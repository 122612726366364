import EVENT from "../Events.js";

export default function clickEventHandle(ctx, container, prototype, triggerEvent) {
    container.addEventListener("click", event => {
        prototype.focus = ctx;

        if (event.isTrusted == false) {
            return;
        }

        let clickEvent = triggerEvent(EVENT.CLICK, event);
        if (clickEvent.defaultPrevented || ctx._src == null) {
            event.stopImmediatePropagation();
        }
    });

    if (!prototype.focus) {
        prototype.focus = ctx;
    }
}

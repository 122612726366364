export default class ObserverPattern{
    constructor(){
        this.events = {};
    }
    
    on(type, fn, isPriority, once){
		if (typeof this.events[type] === 'undefined') {
			this.events[type] = [];
		}
        if(isPriority == true) this.events[type].unshift(fn);
        else this.events[type].push({fn:fn, once: once || false});
    }
    
    one(type, fn, isPriority){
        this.on(type, fn, isPriority, true);
    }

    off(type, fn) {
        if(!this.events.hasOwnProperty(type)) return;
		let subscribers = this.events[type];

		let i = subscribers.length;
		while(i--){
			if (subscribers[i].fn === fn) {
				subscribers.splice(i,1);
			}
		}
		if(this.events[type].length === 0) {
			this.events[type] = null;
			delete this.events[type];
		}
	}
    
    trigger(type, data, ...publication){
        let subscribers = this.events[type];
        let eventCarrier = {};
        if(subscribers instanceof Array && subscribers.length > 0){
            eventCarrier = this.createEvent(type, data);
            subscribers.concat().reduce((proceed, subscriber) => {
                if(proceed === false) return false;
                try{
                    let nProceed = subscriber.fn.call(null, eventCarrier, ...publication);
                    if(subscriber.once == true) this.off(type, subscriber.fn);
                    return nProceed;
                }catch(e){
                    console.error(e);
                    return true;
                }
            }, true);
        }
        return eventCarrier;
    }
    
    createEvent(type, data = {}){
        if(data instanceof Event){
            this.setUpGatter(data, "type", type);
            if(typeof data.getCarryInfo !== "function"){
                data.getCarryInfo = () => null;
            }
            return data;
        }
        
        let eventCarrier = document.createEvent('Event');
        eventCarrier.initEvent(type, false, true);
        eventCarrier.preventDefault = () => {
            this.setUpGatter(eventCarrier, 'defaultPrevented', true);
        };
        eventCarrier.getCarryInfo = () => data;
        
        
        for(let k in data){
            if(!data.hasOwnProperty(k)) continue;
            if(k === "type") throw 'Cannot be converted to "Event" because the "type" field cannot be replaced.';
            eventCarrier[k] = data[k];
        }
        
        return eventCarrier;
    }
    
    setUpGatter(target, key, value){
        if(Object.defineProperty){
            Object.defineProperty(target, key, { 
                get: function() { return value; },
                configurable: true
            });
        }else{
            target.__defineGetter__(key, function(){ return value; });
        }
    }
    
}
import "../css/main.less";
import Config from "./Model/Config.js";
import { MainViewWrapper } from "./Util/MainviewHelper.js";
import { loadIMA, loadVideoJs, loadPPNCustomScript } from "/lib/util/loadScript.js";
import { ENV, noop } from "./Util/Util.js";

window.lepInfo = {
    version: VERSION,
    sw_update_date: UPDATE_DATE,
};
window.ppnAutoIdCount = window.ppnAutoIdCount || 0;

async function init() {
    await Promise.all([loadIMA().catch(noop), loadVideoJs()]);

    if (Object.prototype.toString.call(window.ppnio) === "[object Array]") {
        window.ppnio.forEach(viewInit);
    }

    window.ppnio = {
        push: function () {
            [...arguments].forEach(viewInit);
        },
    };
}

function viewInit(item) {
    if (typeof item == "string") {
        initCore(document.getElementById(item), item);
    } else if (item instanceof HTMLElement) {
        initCore(item, appendAutoId(item));
    }
    function appendAutoId(container) {
        let idParts = container.id.split("-");
        idParts[2] = "auto:" + ++window.ppnAutoIdCount;
        return (container.id = idParts.join("-"));
    }
    async function initCore(container, id) {
        let [sponsor, contentId] = id.split("-")[1].split(":");
        let overrideSponsor = ENV.isMobile ? container.dataset.mobileSpid : container.dataset.desktopSpid;
        sponsor = overrideSponsor || sponsor;

        let [config] = await Promise.all([
            new Config().init(sponsor, contentId, lepInfo),
            loadPPNCustomScript(sponsor).catch(noop),
        ]);
        MainViewWrapper(id, container, config);
    }
}

init().catch((e) => {
    console.error("ad block");
});

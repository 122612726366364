import { getAssets, ENV } from "../Util/Util.js";
import paraDecrypt from "../Util/ParaDecrypt.js";
import urls from "../Util/Urls.js";
import { getDeviceId } from "../Util/DeviceId.js";

const PAGE_TYPE = { DIVERSION: "diversion", CONTENT: "content", HOME: "home", NONE: "none", INTENT: "intent" };
const VPAID_MOD = { 1: "ENABLE", 2: "INSECURE" };
const LiTVUrl = "https://www.litv.tv";

class Config {
    async init(sponsor, contentId, lepInfo) {
        //let querys = getUrlParameters(window.location.search);
        let sponsorInfo = sponsor || "";
        let [sponsorName, refSponsorName] = sponsorInfo.split("|");
        refSponsorName = refSponsorName || sponsorName;
        let partnerName = window.atob(sponsorName);
        let refPartnerName = window.atob(refSponsorName);

        let config = {};
        config.sponsor_info = sponsorInfo;
        config.sponsor_name = sponsorName;
        config.partner_name = partnerName;
        config.ref_sponsor_name = refSponsorName;
        config.ref_partner_name = refPartnerName;
        config.device_id = getDeviceId();
        config.link_extra = "";
        let idparts = (contentId || "").split(".");
        if (idparts.length == 1) {
            config.play_list_id = null;
            config.preset_content_id = idparts[0];
        } else {
            config.play_list_id = idparts[0];
            config.preset_content_id = idparts[1];
        }
        config.count_limit = 25;

        let diversionLink =
            LiTVUrl +
            urls.diversion_page
                .replace("${ref_partner_name}", config.ref_partner_name)
                .replace("${ref_sponsor_name}", config.ref_sponsor_name);
        let contentPageLink = LiTVUrl + urls.content_page.replace("${ref_sponsor_name}", config.ref_sponsor_name);
        config.diversion_link = diversionLink + ((config.link_extra || "") && "&" + config.link_extra);
        config.content_page_link = contentPageLink + ((config.link_extra || "") && "&" + config.link_extra);

        config.env = ENV.current;
        config.showcase_meta_urls = urls.showcase_meta.map((url) =>
            url.replace("${ref_sponsor_name}", config.ref_sponsor_name)
        );
        config.dynamic_build = true;
        config.dynamic_version = 3;
        config.ppn_id = "";

        try {
            let customConfig = await getAssets(`/meta/config/${partnerName}-config.json`);
            config = Object.assign(config, paraDecrypt(customConfig.parameter));
            config.auto_play = getAutoPlay(customConfig.auto_play_rate, config.start_play);
            config.visible_threshold = getVisibleThreshold(customConfig.visible_threshold);

            if (typeof customConfig.floating != "undefined") {
                let blockList = customConfig.floating.block_list || [];
                let floatingOpt = customConfig.floating[ENV.isMobile ? "mobile" : "desktop"];
                config.enable_floating =
                    floatingOpt.enable && !blockList.find((pattern) => window.location.href.includes(pattern));
                config.float_pos = floatingOpt.pos;
                config.sticky_time_gap = floatingOpt.time_gap;
                config.sticky_timecode = floatingOpt.timecode;
                config.sticky_mode = floatingOpt.mode >= 0 ? floatingOpt.mode : customConfig.sticky_mode;
                config.sticky_avoid_atf = floatingOpt.avoid_atf;
                config.sticky_drag = floatingOpt.drag;
            } else {
                config.enable_floating = false;
                config.float_pos = "br";
                config.sticky_time_gap = 0;
                config.sticky_timecode = [];
                config.sticky_mode = customConfig.sticky_mode;
                config.sticky_avoid_atf = false;
                config.sticky_drag = false;
            }

            if (typeof customConfig.side_btn != "undefined" && customConfig.side_btn.url) {
                config.enable_side_btn = true;
                config.side_btn_title = customConfig.side_btn.title;
                config.side_btn_url = customConfig.side_btn.url;
            } else {
                config.enable_side_btn = false;
                config.side_btn_title = "";
                config.side_btn_url = "";
            }

            if (typeof customConfig.rebirth != "undefined") {
                config.rebirth = customConfig.rebirth;
            } else {
                config.rebirth = false;
            }

            if (customConfig.watch_log != null) {
                config.enable_watch_log = customConfig.watch_log.enable;
            } else {
                config.enable_watch_log = false;
            }

            config.liad_id = customConfig.liad_id || "";
            config.external_player = customConfig.external_player || "";
            config.project_num = customConfig.project_num || "";
            config.check_region = customConfig.check_region;
            config.gpt_tag = customConfig.gpt_tag;
            config.get_urls = customConfig.get_urls;
            config.enable_showcase = customConfig.enable_showcase;
            config.ga_ids = getGaIds(customConfig.ga_ids || [], customConfig.ga_account);
            config.device_type = customConfig.device_type;
            config.clip_count = customConfig.clip_count;
            config.error_button = customConfig.error_button;
            config.heavy_ad_fill_banner_tags = customConfig.heavy_ad_fill_banner_tags;
            config.bottom_link_type = config.bottom_link_type || PAGE_TYPE.DIVERSION;
            config.inside_title_link_type = config.inside_title_link_type || PAGE_TYPE.DIVERSION;
            config.link = customConfig.link || {};
            config.playlist_ui = customConfig.playlist_ui;
            config.early_midroll = customConfig.early_midroll || {};
            // immed_and_play preload if sticky is set ON_AD_START.
            config.preload_ad =
                config.enable_floating && config.sticky_mode == 2 ? "immed_and_play" : customConfig.preload_ad || "";
            config.enable_tracking = !!customConfig.tracking;

            config.brandLogo = customConfig.brand_logo || {};
            config.logo_link = getLitvLogoLink(config);
            // set vpaidmode to insecure to get mouse events in iframes to drag sticky
            config.vpaid_mode = VPAID_MOD[config.sticky_drag ? 2 : customConfig.vpaid_mode];
            config.vast_click_area = customConfig.vast_click_area || "default";
            config.allow_ad_pause = customConfig.allow_ad_pause || false;
        } catch (error) {
            config.hasFail = true;
            config.error = error;
            throw error;
        } finally {
            this.config = config;
            let { content_page_link, showcase_meta_urls, ...keep } = this.config;
            lepInfo = Object.assign(lepInfo, keep);
        }
        return this;
    }

    get liadId() {
        return this.config.liad_id
    }

    get externalPlayer() {
        return this.config.external_player || this.config.device_type; // drop device_type in future
    }

    get projectNum() {
        return this.config.project_num;
    }

    get sponsorInfo() {
        return this.config.sponsor_info;
    }

    get sponsorName() {
        return this.config.sponsor_name;
    }

    get partnerName() {
        return this.config.partner_name;
    }

    get refSponsorName() {
        return this.config.ref_sponsor_name;
    }

    get refPartnerName() {
        return this.config.ref_partner_name;
    }

    get deviceId() {
        return this.config.device_id;
    }

    get type() {
        return this.config.type;
    }

    get autoPlay() {
        return this.config.auto_play;
    }

    get bottom() {
        return this.config.bottom;
    }

    get bottomLinkType() {
        return this.config.bottom_link_type;
    }

    get insideTitle() {
        return this.config.inside_title;
    }

    get insideTitleLinkType() {
        return this.config.inside_title_link_type;
    }

    get recommendDisplay() {
        return this.config.recommend_display;
    }

    get recommendStyle() {
        return this.config.recommend_style;
    }

    get env() {
        return this.config.env;
    }

    get presetContentId() {
        return this.config.preset_content_id;
    }

    set presetContentId(id) {
        this.config.preset_content_id = id;
    }

    get playListId() {
        return this.config.play_list_id;
    }

    set playListId(id) {
        this.config.play_list_id = id;
    }

    get showcaseMetaUrls() {
        return this.config.showcase_meta_urls;
    }

    get logoLink() {
        return this.config.logo_link;
    }

    get fontSize() {
        return this.config.font_size;
    }

    get countLimit() {
        return this.config.count_limit;
    }

    get clipCount() {
        return this.config.clip_count;
    }

    get vpaidMode() {
        return this.config.vpaid_mode;
    }

    get vastClickArea() {
        return this.config.vast_click_area;
    }

    get getUrls() {
        return this.config.get_urls;
    }

    get checkRegion() {
        return this.config.check_region;
    }

    get gptTag() {
        return this.config.gpt_tag;
    }

    get enableWatchLog() {
        return this.config.enable_watch_log;
    }

    get enableShowcase() {
        let cfg = this.config;
        return cfg.playlist_ui == "out" || (cfg.enable_showcase && cfg.dynamic_build && cfg.dynamic_version >= 2);
    }

    get enableFloating() {
        return this.config.enable_floating;
    }

    get floatPos() {
        return this.config.float_pos;
    }

    get enableSideBtn() {
        return this.config.enable_side_btn;
    }

    get sideBtnTitle() {
        return this.config.side_btn_title;
    }

    get sideBtnUrl() {
        return this.config.side_btn_url;
    }

    get dynamicVersion() {
        return this.config.dynamic_version;
    }

    get ppnId() {
        return this.config.ppn_id;
    }

    set puid(_puid) {
        return (this.config.puid = _puid);
    }

    get puid() {
        return this.config.puid;
    }

    get gaIds() {
        return this.config.ga_ids;
    }

    get logoDisplay() {
        return this.config.logo_display;
    }

    get errorButton() {
        return this.config.error_button;
    }

    get visibleThreshold() {
        return this.config.visible_threshold;
    }

    get dynamicBuild() {
        return this.config.dynamic_build;
    }

    get rebirth() {
        return this.config.rebirth;
    }

    get heavyAdFillBannerTags() {
        return this.config.heavy_ad_fill_banner_tags;
    }

    get stickyMode() {
        return this.config.sticky_mode;
    }

    get stickyTimeGap() {
        return +this.config.sticky_time_gap;
    }

    get stickyTimecode() {
        return this.config.sticky_timecode;
    }

    get stickyAvoidAtf() {
        return this.config.sticky_avoid_atf;
    }

    get stickyDrag() {
        return this.config.sticky_drag;
    }

    get playlistUi() {
        return this.config.playlist_ui;
    }

    get earlyMidroll() {
        return this.config.early_midroll;
    }

    get preloadAd() {
        return this.config.preload_ad;
    }

    get allowAdPause() {
        return this.config.allow_ad_pause;
    }

    get enableTracking() {
        return this.config.enable_tracking;
    }

    get brandLogo() {
        const { bottom_bar, inside_player, click_through } = this.config.brandLogo;
        return {
            get bottomBar() {
                return bottom_bar;
            },
            get insidePlayer() {
                return inside_player;
            },
            get clickThrough() {
                return click_through;
            },
        };
    }

    getDiversionLink(contentType, contentId, extra) {
        let link = this.config.diversion_link;
        link += (contentType || "") && "&content_type=" + contentType;
        link += (contentId || "") && "&content_id=" + contentId;
        link += (extra || "") && "&" + extra;
        return link;
    }

    getContentPageLink(contentType, contentId, extra) {
        let link = this.config.content_page_link;
        link = link.replace("${content_type}", contentType);
        link += (contentId || "") && "&content_id=" + contentId;
        link += (extra || "") && "&" + extra;
        return link;
    }

    getInsideTitleLink(contentType, contentId, extra) {
        let link;
        if (this.config.inside_title_link_type == PAGE_TYPE.DIVERSION) {
            link = this.getDiversionLink(contentType, contentId, extra);
        } else if (this.config.inside_title_link_type == PAGE_TYPE.CONTENT) {
            link = this.getContentPageLink(contentType, contentId, extra);
        } else if (this.config.inside_title_link_type == PAGE_TYPE.INTENT) {
            link = extra;
        }
        return link;
    }

    getBottomLink(contentType, contentId, extra) {
        let link;
        if (this.config.bottom_link_type == PAGE_TYPE.DIVERSION) {
            link = this.getDiversionLink(contentType, contentId, extra);
        } else if (this.config.bottom_link_type == PAGE_TYPE.CONTENT) {
            link = this.getContentPageLink(contentType, contentId, extra);
        } else if (this.config.bottom_link_type == PAGE_TYPE.INTENT) {
            link = extra;
        }
        return link;
    }
}

function getAutoPlay(autoPlayRate, startType) {
    return autoPlayRate != null ? autoPlayRate > Math.random() * 100 : startType == "auto";
}

function getVisibleThreshold(thresholds) {
    if (!thresholds) {
        return 0.2;
    }
    let threshold = thresholds[ENV.isMobile ? "mobile" : "desktop"];
    return threshold == 0.5 ? 0.45 : threshold;
}

function getGaIds(gaIds, gaAccount) {
    if (gaAccount && gaIds.indexOf(gaAccount) < 0) {
        gaIds.push(gaAccount);
    }
    return gaIds;
}

function getLitvLogoLink(config) {
    switch (config.logo_link_type) {
        case PAGE_TYPE.DIVERSION:
            return config.diversion_link;
        case PAGE_TYPE.NONE:
            return "";
    }
    return LiTVUrl;
}

export default Config;

import litvLogoWatermark from "@images/litv_logo_watermark.svg";
import { cdnUrl } from "../Util/Urls.js";
import { ENV, getAssets, logoAdPart, logoAdElement } from "../Util/Util.js";

const MEDIA_TYPE_MAPPING = { vod: "vod", vc: "vod-channel", vcvm: "vod-channel" };
const MEDIA_MODE_MAPPING = { vod: "vod", vc: "simulation_live", vcvm: "vod" };

const UrlTemplate = __RELEASE_MODE__ || !ENV.useLocal
    ? {
          vod: ({ partnerName, presetContentId }) =>
              `/meta/vod/${partnerName}/${presetContentId && presetContentId + "-"}content.json`,
          schedule: ({ partnerName }) => `/meta/vc/${partnerName}-schedule.json`,
          playList: ({ partnerName, playListId }) =>
              `/meta/pl/${partnerName}/${playListId ? playListId + "-" : ""}playlist.json`,
          liad: (liadId, { partnerName }) => `/meta/acs/${partnerName}/${liadId}.json`,
      }
    : {
          vod: ({ partnerName, presetContentId }) =>
              `/meta/common/${presetContentId && presetContentId + "-"}content.json`,
          schedule: ({ partnerName }) => `/meta/common/schedule.json`,
          playList: ({ partnerName, playListId }) => `/meta/common/${playListId ? playListId + "-" : ""}playlist.json`,
          liad: (liadId, { partnerName }) => `/meta/common/liad/${liadId}.json`,
      };

function refreshLinks(config, meta) {
    let insideTitleLink = config.getInsideTitleLink(meta.contentType, meta.contentId, meta.titleUrl);
    let bottomLink = config.getBottomLink(meta.contentType, meta.contentId, meta.titleUrl);
    return { insideTitleLink, bottomLink };
}

let metaPretreatment = (() => {
    let items = [addLogoMeta];
    return async function (meta, config) {
        return await items.reduce(async (promise, fn) => {
            let { meta, config } = await promise;
            return fn(meta, config);
        }, Promise.resolve({ meta, config }));
    };
})();

async function addLogoMeta(meta, config) {
    const { liad } = meta;
    if (liad.logo_tr && (liad.logo_tr.element_id || []).length > 0) {
    } else if (config.brandLogo.insidePlayer) {
        appendBrandLogo(liad, config.brandLogo);
    } else if (config.logoDisplay) {
        appendLitvLogo(liad);
    }
    return meta;

    function appendBrandLogo(liad, { bottomBar, insidePlayer, clickThrough }) {
        const elementId = bottomBar ? "*litv_logo" : "*litv_logo_fixed";
        appendLogoTr(liad, elementId, insidePlayer, clickThrough);
    }
    function appendLitvLogo(liad) {
        const elementId = config.brandLogo.bottomBar ? "*litv_logo_fixed" : "*litv_logo";
        appendLogoTr(liad, elementId, litvLogoWatermark, config.logoLink);
    }
    function appendLogoTr(liad, elementId, image, clickThough) {
        liad.logo_tr = logoAdPart(elementId);
        liad.elements = liad.elements || {};
        liad.elements[elementId] = logoAdElement(elementId, image, clickThough, config.sponsorName, "tr");
    }
}

function getMetaConstructor(config) {
    switch (config.type) {
        case "vod":
            return config.playListId ? new PlayList(config) : new Vod(config);
        case "vc":
        case "vcvm":
            return new VodChannel(config);
    }
    return null;
}

export default class Meta {
    constructor(config) {
        this.config = config;
        this._meta = {};
        this._metaConstructor = getMetaConstructor(config);
        this._media_type = MEDIA_TYPE_MAPPING[config.type];
        this._media_mode = MEDIA_MODE_MAPPING[config.type];
        this.getRecommend = this.getRecommend.bind(this);
    }

    get mediaType() {
        return this._media_type;
    }

    get mediaMode() {
        return this._media_mode;
    }

    get assetId() {
        return this._meta.assetId;
    }

    get contentId() {
        return this._meta.contentId;
    }

    get contentType() {
        return this._meta.contentType;
    }

    get title() {
        return this._meta.title;
    }

    get secondaryTitle() {
        return this._meta.secondaryTitle;
    }

    get image() {
        if (/\:\/\/|^\//.test(this._meta.image)) {
            //TODO 不需每次檢查
            return this._meta.image;
        }
        return cdnUrl() + "/" + this._meta.image;
    }

    get videoType() {
        return this._meta.videoType;
    }

    get season() {
        return this._meta.season;
    }

    get episode() {
        return this._meta.episode;
    }

    get midrollTimeCodes() {
        return this._meta.midrollTimeCodes;
    }

    get liad() {
        return this._meta.liad || {};
    }

    get startTime() {
        return this._meta.startTime;
    }

    get bottomLink() {
        return this._meta.bottomLink;
    }

    get insideTitleLink() {
        return this._meta.insideTitleLink;
    }

    get filmLength() {
        return this._meta.filmLength;
    }

    get streamUrl() {
        return this._meta.streamUrl;
    }

    getRecommend() {
        return this._metaConstructor.getRecommend();
    }

    async next(contentInfo = {}) {
        let contentId = contentInfo.id;
        if (
            this._metaConstructor instanceof Vod &&
            typeof contentId == "string" &&
            this.config.presetContentId !== contentId
        ) {
            this.config.presetContentId = contentId;
            this._meta = {};
            this._metaConstructor = new Vod(this.config);
            this.getRecommend = this.getRecommend.bind(this);
        }
        let _meta;
        try {
            let startTime = (contentInfo.startTime || 0) * 1e3;
            _meta = await this._metaConstructor.next(contentId, startTime);
        } catch (e) {
            throw e;
        }
        if (_meta.done == true) return { done: true };
        this._meta = await metaPretreatment(_meta.value || {}, this.config);
        return { value: this, done: _meta.done };
    }
}

class Vod {
    constructor(config) {
        this.getVodPromise = getAssets(UrlTemplate.vod(config));
        this.config = config;
        this.loaded = false;
        this.startTime = 0;
        this.resolve = () => {};
        // this.getRecommend = this.getRecommend.bind(this);
    }

    async next(contentId, startTime) {
        if (this.loaded == true) {
            this.startTime = startTime || 0;
            return { done: true };
        }
        let oriVod;
        try {
            oriVod = await this.getVodPromise;
        } catch (e) {
            throw e;
        }

        this.loaded = true;
        let meta = await this.trans(oriVod, startTime);

        Object.assign(this, meta, refreshLinks(this.config, meta));
        return { value: this, done: false };
    }

    async trans(ori, startTime) {
        let meta = {};
        let data = ori;
        meta.assetId = data.assets_id;
        meta.contentId = data.content_id;
        meta.title = data.title;
        meta.secondaryTitle = data.title + " " + (data.subtitle || "");
        meta.image = data.video_image;
        meta.contentType = data.content_type;
        meta.videoType = data.video_type;
        meta.season = data.season;
        meta.episode = data.episode;
        //meta.liad = data.liad || {};
        meta.time_codes = data.midroll_time_codes || [];
        meta.filmLength = data.film_length + "000";
        meta.streamUrl = data.stream_url;
        meta.titleUrl = data.title_url;
        meta.startTime = startTime || 0;
        // this.resolve(new Recommend(this.config, data.title, data.content_id, data.recommend));

        let liad;
        try {
            if (data.liad_id) liad = await getAssets(UrlTemplate.liad(data.liad_id, this.config));
        } catch (e) {
            // console.log(e);
        }

        meta.liad = liad || data.liad || {};
        return meta;
    }

    get midrollTimeCodes() {
        return (this.time_codes || []).map((t) => t * 1e3);
    }

    getRecommend() {
        return new Promise((resolve, reject) => {
            resolve(null);
            // this.resolve = resolve;
        });
    }
}

class VodChannel {
    constructor(config) {
        this.getSchedulePromise = getAssets(UrlTemplate.schedule(config));
        this.config = config;
        this.loaded = false;

        this.videoType = "F";
        this.season = "0";
        this.episode = "0";
    }

    async next() {
        if (this.loaded == true) {
            let programs = this.programs;
            let nextId = (this.currentIndex + 1) % programs.length;

            this.currentProgram = programs[nextId];
            this.currentIndex = nextId;
            this.startTime = 0;
            Object.assign(this, refreshLinks(this.config, this));
            return { value: this, done: false };
        }

        let schedule;
        try {
            schedule = await this.getSchedulePromise;
        } catch (e) {
            throw e;
        }
        let meta = await this.trans(schedule);
        Object.assign(this, meta);
        Object.assign(this, refreshLinks(this.config, this));

        this.loaded = true;
        return { value: this, done: false };
    }

    async trans(schedule) {
        let programs = schedule.programs;
        let programsLength = programs.length;
        let diffTime = Math.round(+new Date() / 1000) - schedule.start_time;
        let remainder = diffTime % schedule.total_length;
        let currentIndex = 0;
        for (; currentIndex < programsLength; currentIndex++) {
            var pre = remainder - programs[currentIndex].length;
            if (pre < 0) break;
            else remainder = pre;
        }

        let meta = {};
        meta.title = schedule.title;
        meta.cdnCode = schedule.cdn_code;
        meta.currentProgram = schedule.programs[currentIndex];
        meta.titleUrl = meta.currentProgram.title_url;
        meta.programs = programs;
        meta.programsLength = programsLength;
        meta.startTime = remainder * 1000;
        meta.currentIndex = currentIndex;
        //meta.liad = schedule.liad || {};
        meta.streamUrl = schedule.stream_url;

        let liad;
        try {
            const liadId = this.config.liadId || schedule.liad_id;
            if (liadId) liad = await getAssets(UrlTemplate.liad(liadId, this.config));
        } catch (e) {
            // console.log(e);
        }

        meta.liad = liad || schedule.liad || {};
        return meta;
    }

    get assetId() {
        return this.cdnCode + "#" + this.currentProgram.asset_id;
    }

    get contentId() {
        return this.currentProgram.content_id;
    }

    get contentType() {
        return this.currentProgram.content_type;
    }

    get secondaryTitle() {
        return this.currentProgram.title + " " + (this.currentProgram.subtitle || "");
    }

    get image() {
        return this.currentProgram.thumbnail;
    }

    get midrollTimeCodes() {
        return (this.currentProgram.time_codes || []).map((t) => t * 1e3);
    }

    get filmLength() {
        return this.currentProgram.length + "000";
    }

    getRecommend() {
        return new Promise((resolve, reject) => {
            resolve(null);
        });
    }
}

class PlayList {
    constructor(config) {
        this.getMetaPromise = getAssets(UrlTemplate.playList(config));
        this.config = config;
        this.loaded = false;
        // this.videoType = "F";
        this.next = this._initNext;
        this._getRecommend = new Promise((resolve) => (this.resolve = resolve));
    }

    async _initNext(contentId, millisecond) {
        let playList;
        try {
            playList = await this.getMetaPromise;
        } catch (e) {
            throw e;
        }
        let meta = await this.trans(playList, contentId || this.config.presetContentId, millisecond);
        Object.assign(this, meta);
        Object.assign(this, refreshLinks(this.config, this));
        this.next = this._next;
        return { value: this, done: false };
    }

    async _next(contentId, millisecond) {
        let programs = this.programs;
        let nextId;
        if (typeof contentId == "string") {
            nextId = programs.findIndex((e) => e.content_id == contentId);
        } else {
            nextId = (this.currentIndex + 1) % programs.length;
        }
        this.currentIndex = nextId;
        this.currentProgram = programs[nextId];
        this.startTime = millisecond || 0;
        Object.assign(this, refreshLinks(this.config, this));
        return { value: this, done: false };
    }

    async trans(playList, contentId, startTime) {
        let programs = playList.programs;
        let nextId;
        if (contentId) {
            startTime = startTime || 0;
            nextId = programs.findIndex((item) => item.content_id == contentId);
        } else {
            startTime = (((Date.now() / 1000) | 0) - playList.start_time) % playList.total_length;
            nextId = programs.findIndex(
                (program) => startTime < program.length || ((startTime -= program.length), false)
            );
            startTime *= 1000;
        }
        nextId = Math.max(nextId, 0);

        this.resolve(new PlayListRecommend(this.config, programs));

        let liad;
        try {
            if (playList.liad_id) liad = await getAssets(UrlTemplate.liad(playList.liad_id, this.config));
        } catch (e) {
            console.log(e);
        }

        return {
            title: playList.title,
            programs: programs,
            totalLength: playList.total_length,
            currentIndex: nextId,
            currentProgram: programs[nextId],
            startTime: startTime,
            liad: liad || playList.liad || {},
        };
    }

    get contentId() {
        return this.currentProgram.content_id;
    }

    get streamUrl() {
        return this.currentProgram.stream_url;
    }

    get titleUrl() {
        return this.currentProgram.title_url;
    }

    get contentType() {
        return this.currentProgram.content_type;
    }

    get secondaryTitle() {
        return this.currentProgram.title + " " + (this.currentProgram.subtitle || "");
    }

    get image() {
        return this.currentProgram.thumbnail;
    }

    get midrollTimeCodes() {
        return (this.currentProgram.time_codes || []).map((t) => t * 1e3);
    }

    get filmLength() {
        return this.currentProgram.length + "000";
    }

    getRecommend() {
        return this._getRecommend;
    }
}
class PlayListRecommend {
    constructor(config, programs) {
        this._meta = {
            list: programs.map((item) => new PlayListRecommendItem(config, item)),
        };
    }

    get list() {
        return this._meta.list;
    }
}
class PlayListRecommendItem {
    constructor(config, item) {
        this.config = config;

        let imageUrl = item.thumbnail;
        this._meta = {
            contentId: item.content_id,
            title: item.title,
            subtitle: item.subtitle,
            getImage: /\:\/\/|^\//.test(imageUrl) ? imageUrlDirect : imageUrlFromCdn,
        };
        function imageUrlDirect() {
            return imageUrl;
        }
        function imageUrlFromCdn() {
            return cdnUrl() + "/" + imageUrl;
        }
    }

    get contentId() {
        return this._meta.contentId;
    }

    get image() {
        return this._meta.getImage();
    }

    get title() {
        return this._meta.title;
    }

    get subtitle() {
        return this._meta.subtitle;
    }
}

export default function metaViewportHandle() {
    const targeViewport = "viewport-fit=cover";
    let oriViewportMetaContent = null;
    let isChange = false;

    function addCover() {
        if (isChange) return;
        oriViewportMetaContent = null;
        let viewportMeta = document.querySelector("meta[name=viewport]");
        if (viewportMeta) {
            // 已存在 viewportMeta
            let viewportMetaContent = viewportMeta.getAttribute("content");
            if (viewportMetaContent) {
                // 已存在 viewportMeta content
                oriViewportMetaContent = viewportMetaContent;
                let viewportFit = viewportMetaContent
                    .split(",")
                    .map(i => i.trim())
                    .filter(i => i.indexOf("viewport-fit") == 0)[0];
                if (viewportFit) {
                    // 已存在 viewport fit => 替換
                    viewportMetaContent.replace(/viewport-fit=.+?\b/, targeViewport);
                } else {
                    // 不存在 viewport fit => 直接指定
                    if (viewportMetaContent.length > 0) viewportMetaContent += ", ";
                    viewportMetaContent += targeViewport;
                }
            } else {
                // 不存在 viewportMeta content => 建立 content
                oriViewportMetaContent = "";
                viewportMetaContent = targeViewport;
            }
            viewportMeta.setAttribute("content", viewportMetaContent);
        } else {
            // 不存在 viewportMeta => 建立 viewportMeta
            viewportMeta = document.createElement("META");
            viewportMeta.name = "viewport";
            viewportMeta.content = targeViewport;
            document.querySelector("head").appendChild(viewportMeta);
        }
        isChange = true;
        forceUpdateViewport();
    }

    function reset() {
        if (!isChange) return;
        let viewportMeta = document.querySelector("meta[name=viewport]");
        if (!viewportMeta) return;
        if (oriViewportMetaContent == null) {
            document.querySelector("head").removeChild(viewportMeta);
        } else {
            viewportMeta.setAttribute("content", oriViewportMetaContent);
        }
        isChange = false;
        forceUpdateViewport();
    }

    function forceUpdateViewport() {
        let ori = document.body.scrollTop;
        document.body.scrollTop += 1;
        setTimeout(() => {
            document.body.scrollTop = ori;
        }, 1);
    }

    return {
        addCover: addCover,
        reset: reset,
    };
}

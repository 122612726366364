import Event from "./Event.js";

export default function ViewportSensor(container, eventCenter, threshold) {
    let inWindow = false,
        documentVisible = true,
        inViewport = false;

    const viewPortObserver = new IntersectionObserver(
        (entries) => {
            inWindow = entries[0].isIntersecting;
            triggerViewportEvent();
        },
        { threshold: threshold || 0.2 }
    );

    eventCenter.on(Event.Dismiss, stop);

    function onVisibilityChange() {
        documentVisible = !document.hidden;
        triggerViewportEvent();
    }

    function triggerViewportEvent() {
        let incoming = inWindow && documentVisible;
        if (inViewport != incoming) {
            inViewport = incoming;
            eventCenter.trigger(inViewport ? Event.VIEW_IN : Event.VIEW_OUT);
        }
    }

    return {
        get inViewport() {
            return inViewport;
        },
        start,
        stop,
    };

    function start() {
        document.addEventListener("visibilitychange", onVisibilityChange);
        viewPortObserver.observe(container);
    }
    function stop() {
        document.removeEventListener("visibilitychange", onVisibilityChange);
        viewPortObserver.disconnect();
    }
}

import ObserverPattern from './ObserverPattern.js';

export default class CountdownComponent extends ObserverPattern{
    constructor(container){
        super();
        
        this.time = -1;
        this.clickFn = () => {};
        
        let view = document.createElement("DIV");
        view.classList.add("countdown");
        view.classList.add("hide");
        view.innerHTML = "<div class='ltp_countdown_text'><span class='ltp_countdown_count'></span><span class='ltp_purchase_tip'></span></div><div class='ltp_thumbnail'></div>";
        view.addEventListener("click", e => {
            e.preventDefault();
            this.clickFn();
            this.trigger("click", { href: view.href });
        });
        view.addEventListener("dblclick", e => e.stopPropagation(), true);
        
        this.view = view;
        this.$thumbnail = view.querySelector(".ltp_thumbnail");
        this.$countView = view.querySelector(".ltp_countdown_count");
        this.$purchaseTip = view.querySelector(".ltp_purchase_tip");
        container.appendChild(view);
    }

    setThumbnail(url){
        this.thumbnail = url || "";
    }

    showThumbnail() {
        if(this.thumbnail){
            this.view.classList.add("has_thumbnail");
            this.$thumbnail.style.cssText = `background-image:url(${this.thumbnail})`;
        }else{
            this.view.classList.remove("has_thumbnail");
            this.$thumbnail.style.cssText = 'display:none;';
        }
    }

    start(url, clickFn = () => {}, time = -1){
        this.showThumbnail();

        if(url){
            this.view.classList.remove("not_href");
            this.view.href = url;
            this.$purchaseTip.innerText = "免看廣告";
        }else{
            this.view.classList.add("not_href");
            this.view.href = "javascript:void(0);";
            this.$purchaseTip.innerText = "";
        }
        this.view.classList.remove("hide");
        this.clickFn = clickFn;

        if(time != -1) this.progress(time);
    }
    
    progress(time){
        if(time == this.time) return;
        if(this.time == -1) this.view.classList.add("countdown_start");
        this.time = time;
        if(this.time == 0) return;
        this.$countView.textContent = time;
    }
    
    end(){
        this.view.classList.add("hide");
        this.view.classList.remove("countdown_start");
        this.view.removeAttribute("href");
        this.time = -1;
        this.$countView.textContent = "";
        this.clickFn = () => {};
    }
    
}
import { genUuid } from "./Util";

const storageId = "ppn_device_id";

export function getDeviceId() {
    let deviceId;
    try {
        deviceId = localStorage.getItem(storageId);
        if (deviceId && deviceId.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i)) {
            return deviceId;
        }
    } catch (e) {}
    deviceId = genUuid();
    localStorage.setItem(storageId, deviceId);
    return deviceId;
}

export default function (videojs) {
    const Component = videojs.getComponent("Component");
    const Button = videojs.getComponent("Button");

    class AdPlayToggle extends Button {
        constructor(player, options = {}) {
            super(player, options);
        }
        buildCSSClass() {
            return "vjs-ad-play-control " + super.buildCSSClass();
        }
        handleClick(event) {
            const liAd = this.options_.ctx.liAd;
            if (liAd) {
                liAd.paused ? liAd.resume() : liAd.pause();
            }
        }
    }

    Component.registerComponent("AdPlayToggle", AdPlayToggle);
}

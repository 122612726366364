export default (function () {
    function send(player, event) {
        player.sendCurrentExternalAdInfo(event)
            .then(() => {
                let btn = event.target;
                btn.style.color = "#ff00a0";
                setTimeout(() => {
                    btn.style.removeProperty('color');
                }, 1000);
                try { navigator.vibrate([100, 50, 50, 50, 50]); } catch (e) { }
            })
            .catch((e) => {
                console.warn(e);
            });
    }

    function start(player) {
        const expiredTime = 400, goal = 7;
        let expiredId = null, count = 0;

        return function (e) {
            clearTimeout(expiredId);
            if (++count >= goal) {
                count = 0;
                send(player, e);
            } else {
                expiredId = setTimeout(() => count = 0, expiredTime);
            }
        }
    }

    return {
        addPlayer(container, player) {
            let adControlsVolumeBtn = container.querySelector(".vjs-ad-mute-control");
            let listener = start(player);
            adControlsVolumeBtn.addEventListener("click", listener);
            player.factorListener = listener;
        },
        removePlayer(container, player) {
            if (typeof player.factorListener == "function") {
                let adControlsVolumeBtn = container.querySelector(".vjs-ad-control-bar .vjs-mute-control");
                adControlsVolumeBtn && adControlsVolumeBtn.removeEventListener("click", player.factorListener);
            }
        }
    };
})();
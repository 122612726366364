import ObserverPattern from "../Util/ObserverPattern.js";
import Showcase from "./Showcase";
import CompanionAD from "./CompanionAD.js";
import { ResizeSensor } from "css-element-queries";
import { getElement, toggleClass } from "../Util/Util.js";

const EVENT = {
    SHOWCASE_CLICK: "SHOWCASE_CLICK",
    SHOWCASE_IMPRESSION: "SHOWCASE_IMPRESSION",
    SHOWCASE_PLAY_REQUEST: "SHOWCASE_PLAY_REQUEST",
};
const [IDLE, COMPANION_AD, SHOWCASE] = ["idle", "CompanionAD", "Showcase"];

export default function Ancillary($container, config, meta, player, contentManager, errorComponent, tracing) {
    $container.classList.add("ppn-has_ancillary");

    const $element = getElement($container, ".ppn-ancillary");
    const state = {
        _listeners: [],
        _s: IDLE,
        set(s) {
            if (this._s == s) return;
            let preState = this._s;
            this._s = s;
            this._listeners.forEach((fn) => fn(s, preState));
        },
        get() {
            return this._s;
        },
        onChange(listener) {
            this._listeners.push(listener);
        },
    };

    let eventCenter = new ObserverPattern();
    let showcase = Showcase(config, meta, $element);
    let companionAD = CompanionAD(player, $element);

    //showcase.init(config, $element);
    showcase.onFirstImpression(() => {
        eventCenter.trigger(EVENT.SHOWCASE_IMPRESSION, {});
    });
    showcase.onClick((e) => {
        e.isPlaylist
            ? eventCenter.trigger(EVENT.SHOWCASE_PLAY_REQUEST, e)
            : eventCenter.trigger(EVENT.SHOWCASE_CLICK, e);
    });
    showcaseEnable(); //NOTE: 2020/08/21 會議決議，先顯示 showcase。

    //CompanionAD.init(player, $element);

    companionAD.onPublish(() => {
        state.set(COMPANION_AD);
        showcase.disable();
    });

    companionAD.onCollapse(() => {
        state.set(SHOWCASE);
        showcase.enable();
    });

    /*
    //NOTE: 2020/08/21 會議決議，先顯示 showcase。
    player.on(LiTVPlayer.EVENT.LINEAR_AD_MEDIA_START, e => {
        setTimeout(showcaseEnable, 500);
    });

    player.on(LiTVPlayer.EVENT.IMPRESSION, e => {
        showcaseEnable();
    });
    */

    state.onChange((_state, _preState) => {
        if (_preState == IDLE) {
            $element.classList.add("ppn-loaded");
        }
        if (_state !== IDLE) {
            $element.dataset.state = _state;
        }
    });

    new ResizeSensor($element, onResize);

    eventCenter.on(EVENT.SHOWCASE_CLICK, (e) => {
        tracing.ga.send("click", `${config.refPartnerName}_showcase`, `${e.page}|${e.item}|${e.title}`);
    });
    eventCenter.on(EVENT.SHOWCASE_PLAY_REQUEST, function (e) {
        tracing.report.end();
        contentManager.play({ id: e.contentId });
    });
    errorComponent.on(errorComponent.EVENT.OCCURRED_ERROR, showcaseEnable);

    function showcaseEnable() {
        if (state.get() != COMPANION_AD) {
            state.set(SHOWCASE);
            showcase.enable();
        }
    }

    function onResize() {
        const offsetWidth = $element.offsetWidth;
        toggleClass($element, "less-300", offsetWidth < 300);
        toggleClass($element, "less-360", offsetWidth < 360);
        toggleClass($element, "over-360", offsetWidth >= 360);
        toggleClass($element, "measured", true);
    }

    return {
        showcaseEnable,
        on(type, fn) {
            eventCenter.on(type, fn);
            return this;
        },
        EVENT,
    };
}

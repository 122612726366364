import { noop } from "./Util";

export default function keyboardHandle(player) {
    const stopState = StopState(player);
    const resumeState = ResumeState(player);
    let currentState = stopState;

    return {
        setMode(newMode) {
            resumeState.setMode(newMode);
        },
        stop() {
            currentState = stopState;
        },
        resume() {
            currentState = resumeState;
        },
        onKeyDown(event) {
            currentState.onKeyDown(event);
        },
        onKeyUp(event) {
            currentState.onKeyUp(event);
        },
    };
    function StopState() {
        return { onKeyDown: noop, onKeyUp: noop };
    }
    function ResumeState(player) {
        const { playToggle, forwardButton, backwardButton, VolumePanel } = player.vjsPlayer.controlBar;
        const VodKeyHandler = {
            keydown: {
                38: VolumeUp, // ArrowUp
                40: VolumeDown, // ArrowDown
                39: seekForwardStart, // ArrowRight
                37: seekBackwardStart, // ArrowLeft
                32: togglePlay, // Space
            },
            keyup: {
                39: seekForwardEnd, // ArrowRight
                37: seekBackwardEnd, // ArrowLeft
            },
        };
        const LiveKeyHandler = {
            keydown: {
                38: VolumeUp, // ArrowUp
                40: VolumeDown, // ArrowDown
            },
            keyup: {},
        };
        let currentKeyHandler = VodKeyHandler;

        return { setMode, onKeyDown, onKeyUp };

        function setMode(newMode) {
            currentKeyHandler = isLive(newMode) ? LiveKeyHandler : VodKeyHandler;
        }
        function onKeyDown(event) {
            const metaKeyPressed = event.ctrlKey || event.metaKey || event.altKey;
            if (!metaKeyPressed && event.shiftKey) {
                return;
            }
            const container = player.container;
            if (document.activeElement == container) {
                event.preventDefault();
            } else if (container.contains(document.activeElement)) {
                container.focus();
                event.preventDefault();
            }
            const keyDownHandler = currentKeyHandler.keydown[event.which];
            if (!keyDownHandler) {
                return;
            }
            keyDownHandler();
            player.vjsPlayer.userActive(true);
            event.stopImmediatePropagation();
        }
        function onKeyUp(event) {
            const keyUpHandler = currentKeyHandler.keyup[event.which];
            if (!keyUpHandler) {
                return;
            }
            keyUpHandler();
            event.stopImmediatePropagation();
        }
        function isLive(mode) {
            return mode == "live" || mode == "simulation_live";
        }
        function seekForwardStart() {
            forwardButton.trigger("mousedown");
        }
        function seekForwardEnd() {
            forwardButton.trigger("mouseup");
        }
        function seekBackwardStart() {
            backwardButton.trigger("mousedown");
        }
        function seekBackwardEnd() {
            backwardButton.trigger("mouseup");
        }
        function VolumeUp() {
            if (player.muted) {
                player.mute(false);
                player.volume(0.1);
            } else {
                player.volume(player.volumeLevel + 0.1);
            }
            VolumePanel.userActive(true);
        }
        function VolumeDown() {
            if (!player.currentMuted) {
                player.volume(player.volumeLevel - 0.1);
            }
            VolumePanel.userActive(true);
        }
        function togglePlay() {
            playToggle.trigger("click");
        }
    }
}

import PLAY_ICON from "../images/play_icon.svg";
import { SVGCope } from "./Util.js";

export default function BigPlayIcon(ctx) {
    const playIconSvgCope = new SVGCope(PLAY_ICON);
    const playIcon = playIconSvgCope.getSVGElement();
    playIcon.setAttribute("class", "vjs-play-rounded-fill");
    playIcon.onmouseup = e => ctx.play();
    playIcon.ontouchend = e => ctx.play();
    ctx.appendChild(playIcon);
}

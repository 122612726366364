import ObserverPattern from './ObserverPattern.js';
import AdsSessionChannel from './AdsSessionChannel.js';
import {LiAdError, LiAdErrorCodes} from './CustomError.js';
import { establishUid, testImage } from './Util.js';
import GptImageAd from './GptImageAd.js';

//TODO GPT's click report

const TRIGGER_NAME = "ImageAd";
const MINIMUM_WIDTH_FOR_GPT_REQUEST = 630;

export default class ImageAd extends ObserverPattern{
    constructor(adBlockEnable, logoContainer, nonlinearImageContainer, linearImageContainer, playerContainer, urlConfig, viewportSensor){
        super();
        let pool = [];
        let nonlinearContainerRatio = nonlinearImageContainer.offsetWidth / nonlinearImageContainer.offsetHeight;
        let adsRequestAbort = _adsRequestAbort.bind(this);
        let removeImage = _removeImage();
        let remove = _remove(removeImage).bind(this);
        let createImage = _createImage(pool, nonlinearContainerRatio, logoContainer, linearImageContainer, nonlinearImageContainer, remove, viewportSensor);

        this.pool = pool;
        this.adsRequestCancelOperator = {};
        this.isPaused = false;

        this.closeNonLinearBanner = _closeNonLinearBanner(pool, adsRequestAbort, remove);
        this.stopLinearAd = _closeLinearBanner(pool, adsRequestAbort, remove);
        this.stop = _removeAll(adsRequestAbort, removeImage).bind(this);
        this.remove = remove;

        adBlockEnable.then(r => {
            this.adsRequest = _adsRequest(r, playerContainer, urlConfig, createImage).bind(this);
        });
    }
    
    pause(){
        this.isPaused = true;
        this.pool.forEach(imageHost => imageHost.pause());
    }
        
    resume(){
        this.isPaused = false;
        this.pool.forEach(imageHost => imageHost.resume());
    }

    removeLogo(position){
        this.pool.filter(v => v.isLogo && v.position === position).forEach(v => this.remove(v.uid));
    }
}

function _adsRequest(adBlockEnable, playerContainer, urlConfig, createImage){
    return function(data){
        let adsSessionChannel = new AdsSessionChannel();
        let destroyAdsSessionChannel = () => {
            adsSessionChannel.destroy();
            adsSessionChannel = null;
        };

        if(data.isGpt && adBlockEnable){
            Promise.resolve().then(() => {
                adsSessionChannel.trigger("Error", {error: new LiAdError(LiAdErrorCodes.codes.GPT_BLOCK)}, TRIGGER_NAME);
                destroyAdsSessionChannel();
            });
            return adsSessionChannel.getChannel();
        }

        adsSessionChannel.on("Impression", e => { this.trigger("impression", e); });
        adsSessionChannel.on("Click", e => { this.trigger("click", e); });
        adsSessionChannel.on("Completed", destroyAdsSessionChannel);
        adsSessionChannel.on("Error", destroyAdsSessionChannel);

        let uid = data.uid || establishUid();
        if(data.is_linear == false && data.is_logo == false){
            uid = "NB." + uid;
        }else if(data.is_linear == true){
            uid = "LA." + uid;
        }
        data.uid = data.uid || uid;

        let promise, url, cancelled = false;
        this.adsRequestCancelOperator[uid] = () => {
            cancelled = true;
            destroyAdsSessionChannel();
        };

        if (!data.isGpt) {
            url = (/\:\/\/|^\//.test(data.data) || /^data:image.*base64/.test(data.data)) ? data.data : urlConfig.cdnStatic + "/" + data.data;
            promise = testImage(url).catch(err => {
                throw { error: new LiAdError(Object.assign({ innerError: err }, LiAdErrorCodes.codes.HOUSE_AD_IMAGE_ASSET_NOT_FOUND)) };
            })
        } else if (data.is_linear == false && playerContainer.offsetWidth < MINIMUM_WIDTH_FOR_GPT_REQUEST) {
            promise = Promise.reject({ error: new LiAdError(LiAdErrorCodes.codes.GPT_UNABLE_TO_ACCOMMODATE) });
        } else {
            url = data.data;
            promise = Promise.resolve({ url: url });
        }
        

        if(url) adsSessionChannel.trigger("Request", {requestInfo : {url: url}}, TRIGGER_NAME);
        
        promise
        .then(imageMeta => {
            if(cancelled) return;
            delete this.adsRequestCancelOperator[uid];
            return createImage(data, imageMeta, adsSessionChannel, this.isPaused);
        })
        .catch(err => {
            if(adsSessionChannel) adsSessionChannel.trigger("Error", err, TRIGGER_NAME);
            delete this.adsRequestCancelOperator[uid];
        });
        
        return adsSessionChannel.getChannel();
    };
}

function _adsRequestAbort(designation){
    if(!designation){
        for(let k in this.adsRequestCancelOperator){
            if(!this.adsRequestCancelOperator.hasOwnProperty(k)) return;
            this.adsRequestCancelOperator[k]();
        }
        this.adsRequestCancelOperator = {};
    }else{
        let prefix = "^" + designation + "\\.";
        let reg = new RegExp(prefix);
        Object.keys(this.adsRequestCancelOperator).filter(k => reg.test(k)).forEach(k => {
            this.adsRequestCancelOperator[k]();
            delete this.adsRequestCancelOperator[k];
        });
    }
}

function _createImage(pool, nonlinearContainerRatio, logoContainer, linearImageContainer, nonlinearImageContainer, remove, viewportSensor){
    return function(data, imageMeta, adsChannel, isPaused){
        let imageHost = {
            paused : isPaused
        };
    
        pool.push(imageHost);
        
        let uid = data.uid;
        let _onClickImage = onClickImage(adsChannel, data, imageHost);
        let removeByUid = () => remove(uid);
    
        let elCreator = imageElementCreator({
            uid : uid, 
            elementId : data.id, 
            url : imageMeta.url, 
            position : data.position, 
            width : imageMeta.width, 
            height : imageMeta.height, 
            nonlinearContainerRatio : nonlinearContainerRatio, 
            clickThrough : data.click_through, 
            clickListener : _onClickImage, 
            remove : removeByUid
        });
    
        let el = elCreator({
            isGpt : data.isGpt, 
            isLogo : data.is_logo, 
            isLinear : data.is_linear, 
        });
    
        let container = data.is_logo ? logoContainer : data.is_linear ? linearImageContainer : nonlinearImageContainer;
        container.appendChild(el);
    
        imageHost.uid = uid;
        imageHost.el = el;
        imageHost.container = container;
        imageHost.position = data.position;
        imageHost.adsChannel = adsChannel;
        imageHost.onClickImage = _onClickImage;
        imageHost.isLinear = data.is_linear;
        imageHost.isLogo = data.is_logo;
        imageHost.isGpt = data.isGpt;
    
        let promise;
        if(data.isGpt){
            promise = GptImageAd(imageMeta.url, imageHost, viewportSensor)
                .then(() => {
                    if (!pool.find(v => v.uid === uid)) {
                        imageHost.cleanGpt();
                        throw "abort";
                    }
                })
                .catch(err => {
                    let index = pool.findIndex(host => host.uid == uid);
                    if (index > -1) {
                        pool.splice(index, 1);
                    }
                    let closeBtn = el.querySelector(".banner_close_btn");
                    if (closeBtn) {
                        closeBtn.onclick = null;
                    }
                    el.querySelector(".image_content").removeEventListener("click", _onClickImage);
                    if (el.parentNode == container) {
                        container.removeChild(el);
                    }
                    throw err;
                });
        }else{
            promise = Promise.resolve();
        }
    
        return promise.then(() => {
            adsChannel.trigger("Loaded", { meta: imageMeta }, TRIGGER_NAME);
            container.classList.add("ad_impression");
            let duration = data.duration || Math.ceil(Math.max((data.end_time || 0) - (+new Date), 0) / 1E3);
            adsChannel.trigger("AdMediaStart", { duration: duration }, TRIGGER_NAME);
            if(data.is_linear == true) adsChannel.trigger("PauseRequested", {}, TRIGGER_NAME);
            adsChannel.trigger("Impression", Object.assign(data, {file_name: data.data.replace(/^.*[\\\/]/, '')}), TRIGGER_NAME);
    
            let pause = () => {};
            let resume = () => {};
            let timerId = null;
            if(duration > 0){
                let count = duration;
                if(data.enable_countdown == true){
                    adsChannel.trigger("CountdownStart", { time: count, clickFunction: () => adsChannel.trigger("ClickSkip", {}, TRIGGER_NAME) });
                    resume = () => {
                        if(timerId == null){
                            timerId = setInterval(() => {
                                imageHost.paused = false;
                                if(--count == 0){
                                    clearInterval(timerId);
                                    adsChannel.trigger("CountdownEnd");
                                    removeByUid();
                                }else{
                                    adsChannel.trigger("CountdownProgress", {time: count});
                                }
                            }, 1E3);
                        }
                        adsChannel.trigger("Resume", {}, TRIGGER_NAME);
                    };
                }else{
                    resume = () => {
                        if(timerId == null){
                            timerId = setInterval(() => {
                                imageHost.paused = false;
                                if(--count == 0){
                                    clearInterval(timerId);
                                    removeByUid();
                                }
                            }, 1E3);
                        }
                    };
                }
                
                if(imageHost.paused == false) resume();
                pause = () => {
                    imageHost.paused = true;
                    clearInterval(timerId);
                    timerId = null;
                    adsChannel.trigger("Pause", {}, TRIGGER_NAME);
                };
            }
            
            let samePos = pool.filter(v => v.uid !== uid && v.position === data.position && v.isLinear === data.is_linear && v.isLogo === data.is_logo);
            if(samePos.length > 0){
                samePos[samePos.length - 1].el.classList.add('hide');
            }
    
            imageHost.resume = resume;
            imageHost.pause = pause;
            imageHost.timerId = timerId;
        })
        .catch(err => {
            adsChannel = null;
            if(err !== "abort") throw err;
        });
    };
}

function _remove(removeImage){
    return function(uid){
        let imageHost = this.pool.find(v => v.uid === uid);
        if(!imageHost) return;
        let index = this.pool.indexOf(imageHost);
        this.pool.splice(index, 1);
        //this.pool = this.pool.filter(v => v !== imageHost);
        let samePos = this.pool.filter(v => (
                                        v.position === imageHost.position && 
                                        v.isLinear === imageHost.isLinear && 
                                        v.isLogo === imageHost.isLogo && 
                                        v.isGpt === imageHost.isGpt));
        if(samePos.length > 0){
            samePos[samePos.length - 1].el.classList.remove('hide');
        }else{
            imageHost.container.classList.remove("ad_impression");
        }
        removeImage(imageHost);
    };
}

function _removeImage(){
    return function(imageHost, isAbort = false){
        if(imageHost.timerId != null){
            clearInterval(imageHost.timerId);
            imageHost.timerId = null;
        }

        let closeBtn = imageHost.el.querySelector(".banner_close_btn");
        if(closeBtn){
            closeBtn.onclick = null;
        }
    
        if(imageHost.isGpt){
            imageHost.cleanGpt();
        }
    
        imageHost.el.querySelector(".image_content").removeEventListener("click", imageHost.onClickImage);
        if(imageHost.el.parentNode == imageHost.container) imageHost.container.removeChild(imageHost.el);
        imageHost.el = null;
        imageHost.container = null;
        imageHost.onClickImage = null;
        
        isAbort && imageHost.adsChannel.trigger("Abort");
        imageHost.adsChannel.trigger("AdMediaComplete", {}, TRIGGER_NAME);
        imageHost.adsChannel.trigger("Completed", {}, TRIGGER_NAME);
        imageHost.adsChannel = null;
        imageHost = null;
    };
}

function onClickImage(adsChannel, data, imageHost){
    return function(e){
        if(imageHost.paused == true){
            //imageHost.resume();
            adsChannel.trigger("RequestResume", {}, TRIGGER_NAME);
            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();
            return;
        }
        adsChannel.trigger("Click", Object.assign(data, {file_name: data.data.replace(/^.*[\\\/]/, '')}), TRIGGER_NAME);
    }
}

function _closeNonLinearBanner(pool, adsRequestAbort, remove){
    return function(){
        adsRequestAbort("NB");
        let nonLinearBannerList = pool.filter(imageHost => {
            return (imageHost.isLinear == false && imageHost.isLogo == false);
        });
        if(nonLinearBannerList.length < 1) return;
        let imageHost = nonLinearBannerList[nonLinearBannerList.length - 1];
        remove(imageHost.uid); //TODO 優化成更高效率的做法，目前方式會多跑幾次迴圈。
    };
}

function _closeLinearBanner(pool, adsRequestAbort, remove){
    return function(){
        adsRequestAbort("LA");
        pool.forEach(imageHost => {
            if(imageHost.isLinear == true){
                remove(imageHost.uid);
            }
        });
    };
}

function _removeAll(adsRequestAbort, removeImage){
    return function(){
        adsRequestAbort();
        this.pool.forEach(imageHost => {
            imageHost.container.classList.remove("ad_impression");
            removeImage(imageHost, true);
        });
        this.pool.splice(0, this.pool.length);
    };
}

// create image element
function pipe(...fns){
    return function(){
        return fns.reduce((pre, fn) => fn(pre), arguments[0]);
    }
}

function positionToClassName(position){
    if(!position) return ["m","c"];
    else return position.toLowerCase().split("");
}

function createElement(elementId){
    return function(){
        let el = document.createElement("DIV");
        el.classList.add("c_image");
        el.dataset.elementId = elementId;
        //positionToClassName(position).forEach(c => el.classList.add(c));
        return el;
    };
}

function addClassName(classList){
    return function(el){
        classList.forEach(className => el.classList.add(className));
        return el;
    };
}

function maxSize(width, height){
    return function(el){
        el.style.maxWidth = width + "px";
        el.style.maxHeight = height + "px";
        return el;
    };
}

function sizeFixedPercentage(percentage){
    percentage = percentage + "%";
    return function(el){
        el.style.width = percentage;
        el.style.height = percentage;
        return el;
    };
}

function sizeResponsive(width, height, nonlinearContainerRatio){
    return function(el){
        const padding = 7; //height padding 7em
        let bannerRatio = width / height;
        el.style.width = "calc(" + (width / (height * nonlinearContainerRatio) * 100) + "% - " + (bannerRatio * padding) + "em)";
        return el;
    }
}

function sizeSticky(width, height){
    return function(el){
        el.style.width = width / 1920 * 100 + "%";
        el.style.height = height / 1080 * 100 + "%";
        return el;
    }
}

function appendElement(createTemple, clickThrough){
    return function(el){
        let temple = createTemple();
        if(clickThrough){
            temple = `<a href="${clickThrough}" target="_blank">${temple}</a>`;
        }
        el.innerHTML = temple;
        return el;
    };
}

function addImageContentClick(clickListener){
    return function(el){
        let imageContent = el.querySelector(".image_content");
        imageContent.addEventListener("click", clickListener);
        return el;
    }
}

function appendCloseBtn(removeFn){
    return function(el){
        let closeBtn = createCloseBtn(removeFn);
        el.appendChild(closeBtn);
        return el;
    };
}

function createCloseBtn(removeFn){
    let closeBtn = document.createElement("A"); //add close btn.
    closeBtn.classList.add("banner_close_btn");
    closeBtn.href = "javascript:void(0);";
    closeBtn.onclick = removeFn;
    return closeBtn;
}

function createNonLinearGptEl(position, elementId, uid, remove){
    return pipe(
        createElement(elementId), 
        addClassName(["internal_banner", "nonlinear_ad", "nonlinear_gpt_banner"].concat(positionToClassName(position))),
        //maxSize(width, height),
        //sizeFixedPercentage(100),
        appendElement(() => `<div id="${uid}" class="image_content gpt_container"></div>`),
        appendCloseBtn(remove)
    );
}

function createLinearGptEl(position, elementId, uid){
    return pipe(
        createElement(elementId), 
        addClassName(["gpt_banner", "gpt-base-layer"].concat(positionToClassName(position))),
        // appendElement(() => `<div id="${uid}" class="image_content gpt_container"></div>`)
    );
}

function createNonLinearBannerEl(position, elementId, url, width, height, remove, uid, clickThrough, clickListener, nonlinearContainerRatio){
    return pipe(
        createElement(elementId), 
        addClassName(["internal_banner", "nonlinear_ad"].concat(positionToClassName(position))),
        maxSize(width, height),
        sizeResponsive(width, height, nonlinearContainerRatio),
        appendElement(() => `<img src="${url}" class="image_content" />`, clickThrough),
        addImageContentClick(clickListener),
        appendCloseBtn(remove)
    );
}

function createLinearBannerEl(position, elementId, url, clickThrough, clickListener){
    return pipe(
        createElement(elementId), 
        addClassName(["internal_banner"].concat(positionToClassName(position))),
        appendElement(() => `<div class="image_div image_content" style="background-image:url('${url}')"></div>`, clickThrough),
        addImageContentClick(clickListener)
    );
}

function createLogoEl(position, elementId, url, width, height, clickThrough, clickListener){
    return pipe(
        createElement(elementId), 
        addClassName(["trademark"].concat(positionToClassName(position))),
        sizeSticky(width, height), //因為 logo 沒有依造規範給予正確的尺寸，故沒辦法計算。
        //sizeFixedPercentage(11), //暫時的，待素材正規化時就可以移除。
        appendElement(() => `<img src="${url}" class="image_content" />`, clickThrough),
        addImageContentClick(clickListener)
    );
}

function imageElementCreator({uid, elementId, url, position, width, height, nonlinearContainerRatio, clickThrough, clickListener, remove}){
    return function({isGpt, isLogo, isLinear}){
        if(isLogo){
            return createLogoEl(position, elementId, url, width, height, clickThrough, clickListener)();
        }

        if(isGpt && isLinear) {
            return createLinearGptEl(position, elementId, uid)();
        }

        if(isGpt){
            return createNonLinearGptEl(position, elementId, uid, remove)();
        }

        if(isLinear){
            return createLinearBannerEl(position, elementId, url, clickThrough, clickListener)();
        }

        return createNonLinearBannerEl(position, elementId, url, width, height, remove, uid, clickThrough, clickListener, nonlinearContainerRatio)();
    }
}

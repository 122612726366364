import { getLocalStoreSafe, setLocalStoreSafe } from "../Util";

const EMPTY_THEME = { startTime: -1, endTime: -1 };

export default function (videojs) {
    const SKIP_THEME_KEY = "li_skp_thm";

    const Component = videojs.getComponent("Component");
    const Button = videojs.getComponent("Button");

    class SkipThemeButton extends Button {
        constructor(player, options = {}) {
            super(player, options);
            const isOnLocal = getLocalStoreSafe(SKIP_THEME_KEY);
            this.isOn = isOnLocal != null ? isOnLocal : true;
            if (this.isOn) {
                this.el_.classList.add("is-on");
            }
            player.on("timeupdate", e => this.compareTime(e));
            player.on("manualSeeking", e => this.onSeeking(e));
            player.on("manualSeeked", e => this.onSeeked(e));
        }
        buildCSSClass() {
            return "vjs-skip-theme " + super.buildCSSClass();
        }
        handleClick() {
            const isOn = !this.isOn;
            setLocalStoreSafe(SKIP_THEME_KEY, isOn);
            this.isOn = isOn;
            isOn ? this.checkTimeStart() : this.checkTimeStop();
        }
        checkTimeStart() {
            this.player_.toast.text("開啟略過片頭片尾");
            this.el_.classList.add("is-on");
        }
        checkTimeStop() {
            this.player_.toast.text("關閉略過片頭片尾");
            this.el_.classList.remove("is-on");
        }
        onSeeking(e) {
            this.isSeeking = true;
        }
        onSeeked(e) {
            let time = e.time;
            if (isInThemeTime(time, this.openingTheme)) {
                this.prevTime = this.openingTheme.startTime + 0.1;
            } else if (isInThemeTime(time, this.endingTheme)) {
                this.prevTime = this.endingTheme.startTime + 0.1;
            } else {
                this.prevTime = time;
            }
            this.isSeeking = false;
        }
        reset(startTime, openingTheme, endingTheme) {
            this.prevTime = startTime;
            this.openingTheme = themeNormalization(openingTheme);
            this.endingTheme = themeNormalization(endingTheme);
        }
        compareTime() {
            const currentTime = this.player_.currentTime();
            const { prevTime, isOn, isSeeking } = this;
            this.prevTime = currentTime;
            if (!isOn || isSeeking) {
                return;
            }

            const { openingTheme, endingTheme } = this;
            if (shouldThemeSkip(prevTime, currentTime, openingTheme)) {
                return toThemeEndTime(this.player_, openingTheme, "已略過片頭");
            }
            if (shouldThemeSkip(prevTime, currentTime, endingTheme)) {
                return toThemeEndTime(this.player_, endingTheme, "已略過片尾");
            }

            function shouldThemeSkip(prevTime, currentTime, theme) {
                return prevTime <= theme.startTime && isInThemeTime(currentTime, theme);
            }
            function toThemeEndTime(player, theme, hint) {
                const time = Math.min(theme.endTime, player.duration() - 1);
                player.currentTime(time);
                player.one("seeked", () => player.toast.text(hint));
            }
        }
    }
    Component.registerComponent("skipThemeButton", SkipThemeButton);
}

function themeNormalization(rawData) {
    if (rawData == null) {
        return EMPTY_THEME;
    }
    const { startTime, start_time, endTime, end_time } = rawData;
    return {
        startTime: +(start_time == null ? startTime : start_time),
        endTime: +(end_time == null ? endTime : end_time),
    };
}

function isInThemeTime(time, theme) {
    return theme.startTime <= time && time < theme.endTime - 1;
}

import { getLocalStoreSafe, setLocalStoreSafe } from "../Util";

export default function (videojs) {
    const STRING_AUTO = "自動";
    const storeKey = "li_pl_res_label";
    const DefaultResolutionList = ["1080p", "720p", "480p", "360p", "240p"];

    function setDefaultResolution(resolutionLabel) {
        setLocalStoreSafe(storeKey, resolutionLabel);
    }
    function getDefaultResolutionLabel() {
        return getLocalStoreSafe(storeKey) || STRING_AUTO;
    }
    function getDefaultResolutionMenuIndex(menuDataList) {
        const defaultLabel = getDefaultResolutionLabel();
        const index = menuDataList.findIndex(item => item.label == defaultLabel);
        return index < 0 ? menuDataList.length - 1 : index;
    }
    function getResolutionIndex(level) {
        const bitrate = level.bitrate;
        if (bitrate >= 4000000) {
            return 0;
        }
        if (bitrate >= 991000) {
            return 1;
        }
        if (bitrate >= 501000) {
            return 2;
        }
        if (bitrate >= 201000) {
            return 3;
        }
        return 4;
    }

    const MenuItem = videojs.getComponent("MenuItem");
    class QualityMenuItem extends MenuItem {
        constructor(player, options) {
            super(player, {
                label: options.label,
                selected: options.selected,
                selectable: true,
                multiSelectable: false,
            });
            this.id = options.id;
            this.label = options.label;
            this.on(player, "quality", (e, target) => this.update(e, target));
            if (options.selected) {
                this.changeResolution();
            }
        }
        changeResolution() {
            const id = this.id;
            const qualityList = this.player_.qualityLevels();
            for (let i = 0; i < qualityList.length; i++) {
                qualityList[i].enabled = id === i || id === "auto";
            }
        }
        handleClick(event) {
            super.handleClick();
            setDefaultResolution(this.label);
            this.changeResolution();
            this.player_.toast.text("畫質已切換為 " + this.label);
            this.player_.trigger("quality", this);
        }
        update(event, target) {
            this.selected(target.id === this.id);
        }
    }

    const MenuButton = videojs.getComponent("MenuButton");
    class QualityButton extends MenuButton {
        constructor(player, options) {
            super(player, options);

            this.updateVisibility();
            this.on(player, "quality", (e, target) => this.updateLabel(e, target));
            this.on(player, "loadedmetadata", e => this.handleQualityLevelsChange(e));
        }
        createEl() {
            const el = super.createEl();
            this.labelElId_ = "vjs-quality-level-value-label-" + this.id_;
            this.labelEl_ = videojs.dom.createEl("div", {
                className: "vjs-quality-level-value",
                id: this.labelElId_,
            });
            el.appendChild(this.labelEl_);
            return el;
        }
        dispose() {
            this.labelEl_ = null;
            super.dispose();
        }
        buildCSSClass() {
            return `vjs-quality-level ${super.buildCSSClass()}`;
        }
        buildWrapperCSSClass() {
            return `vjs-quality-level ${super.buildWrapperCSSClass()}`;
        }
        createItems() {
            const player = this.player();
            const levelList = player
                .qualityLevels()
                .levels_.map((level, index) => ({ id: index, level }))
                .sort((a, b) => b.level.bitrate - a.level.bitrate);
            const menuDataList = convertMenuData(levelList);
            this.labels = menuDataList.map(item => item.label);
            this.labelMap = menuDataList.reduce((result, { id, label }) => ((result[id] = label), result), []);
            menuDataList.push({ id: "auto", label: "自動", selected: false });

            const defaultItem = menuDataList[getDefaultResolutionMenuIndex(menuDataList)];
            defaultItem.selected = true;
            this.updateLabel(null, defaultItem);

            return menuDataList.map(level => new QualityMenuItem(player, level));

            function convertMenuData(levelList) {
                if (levelList.length == 0) {
                    return [];
                }
                const resolutionMaxIndex = getResolutionIndex(levelList[0].level);
                const resolutionList = DefaultResolutionList.slice(resolutionMaxIndex, resolutionMaxIndex + 4);
                return levelList
                    .slice(0, resolutionList.length)
                    .map(({ id }, index) => ({ id, label: resolutionList[index], selected: false }));
            }
        }
        handleQualityLevelsChange() {
            this.update();
            this.updateVisibility();
        }
        qualityLevelSupported() {
            return this.player().qualityLevels && this.player().tech({ IWillNotUseThisInPlugins: true }).vhs;
        }
        updateVisibility(event) {
            if (this.qualityLevelSupported()) {
                this.removeClass("vjs-hidden");
            } else {
                this.addClass("vjs-hidden");
            }
        }
        updateLabel(event, item) {
            if (!this.qualityLevelSupported()) {
                return;
            }
            this.labelEl_.textContent = item.label;
        }
    }

    const Component = videojs.getComponent("Component");
    Component.registerComponent("QualityMenuItem", QualityMenuItem);
    Component.registerComponent("QualityButton", QualityButton);
}

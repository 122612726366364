import ObserverPattern from './ObserverPattern.js';
import AdsSessionChannel from './AdsSessionChannel.js';
import {LiAdError} from './CustomError.js';
import {testImage} from './Util.js';

const TRIGGER_NAME = "PinAdController";

export default class PinAdController extends ObserverPattern{
    constructor(urlConfig){
        super();
        this.urlConfig = urlConfig;
        this.unit = null;
    }

    adsRequest(data){
        //need return error if media_type not image
        if(this.unit != null) {
            this.close();
        }
        let adsSessionChannel = new AdsSessionChannel();
        this.unit = createUnit(data, adsSessionChannel, this.urlConfig);
        Promise.resolve().then(() => {
            this.trigger("publish", this.unit);
        });
        return adsSessionChannel.getChannel();
    }

    close(){
        if(this.unit == null) return;
        if(this.unit.isResolved == true) return;
        this.unit.complete();
        this.trigger("requestHide", this.unit);
    }
}

function createUnit(data, adsSessionChannel, urlConfig){
    let isResolved = false;

    function getContent(){
        adsSessionChannel.trigger("Request", {requestInfo : {url: data.data}}, TRIGGER_NAME);
        let url = /\:\/\/|^\//.test(data.data) ? data.data : urlConfig.cdnStatic + "/" + data.data;

        return testImage(url)
        .then(imageMeta => {
            let img = document.createElement("IMG");
            img.src = imageMeta.url;
            img.alt = data.title;
            let content = document.createElement("A");
            content.href = data.click_through;
            content.target = "_blank";
            content.onclick = () => adsSessionChannel.trigger("Click", Object.assign({}, data), TRIGGER_NAME);
            content.appendChild(img);

            adsSessionChannel.trigger("Loaded", { meta: data.data }, TRIGGER_NAME);
            adsSessionChannel.trigger("AdMediaStart", { duration: data.duration }, TRIGGER_NAME);
            adsSessionChannel.trigger("Impression", {}, TRIGGER_NAME);

            return content;
        })
        .catch(err => {
            adsSessionChannel.trigger("Error", {error: new LiAdError(Object.assign({ reportType: "error" }, err))}, TRIGGER_NAME);
            throw err;
        });
    }

    function complete(){
        if(isResolved == true) return;
        isResolved = true;
        adsSessionChannel.trigger("AdMediaComplete", {}, TRIGGER_NAME);
        adsSessionChannel.trigger("Completed", {}, TRIGGER_NAME);
    }

    return Object.assign(data, {
        adType: "pinAd",
        getContent: getContent,
        complete: complete,
        isResolved: isResolved
    });

}
export default function(videojs) {
    const Component = videojs.getComponent("Component");
    const ClickableComponent = videojs.getComponent("ClickableComponent");

    class Brand extends ClickableComponent {
        constructor(player, options = {}) {
            super(player, options);
        }
        createEl() {
            const brand = this.options_.brand;
            return videojs.dom.createEl('a', {
                tabIndex: -1,
                target: "_blank",
                className: "vjs-control litv-brand",
                href: brand == "string" ? brand : "https://www.litv.tv"
            });
        }
    }

    Component.registerComponent("Brand", Brand);
}
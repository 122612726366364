export default function (videojs) {
    const Component = videojs.getComponent("Component");
    const Button = videojs.getComponent("Button");

    class AdMuteToggle extends Button {
        constructor(player, options = {}) {
            super(player, options);
        }
        buildCSSClass() {
            return "vjs-ad-mute-control " + super.buildCSSClass();
        }
        handleClick(event) {
            const muted = !this.player_.muted();
            this.options_.ctx.mute(muted);
        }
    }

    Component.registerComponent("AdMuteToggle", AdMuteToggle);
}

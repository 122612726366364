import { noop, hidden, visibilityChange } from "../Util";

export default function recoverStreamHandle(ctx, retryMax) {
    let countDown = 0;

    const vjsPlayer = ctx.vjsPlayer;
    const startState = {
        start: noop,
        stop: stop,
        resume: resume,
        pause: pause,
        recoverTrail: () => {
            if (--countDown < 0) {
                stop();
                return;
            }
            vjsPlayer.playWrapper();
        },
        onRecovered: () => {
            countDown = retryMax;
        },
    };
    const stopState = {
        start: start,
        stop: noop,
        resume: noop,
        pause: noop,
        recoverTrail: noop,
        onRecovered: noop,
    };
    let currentState = stopState;

    return {
        start() {
            currentState.start();
        },
        stop() {
            currentState.stop();
        },
        get countDown() {
            return countDown;
        },
    };

    function start() {
        if (!ctx.clip.live) {
            return;
        }
        currentState = startState;
        countDown = retryMax;
        document.addEventListener(visibilityChange, onVisibilityChange);
        resume();
    }
    function stop() {
        currentState = stopState;
        document.removeEventListener(visibilityChange, onVisibilityChange);
        pause();
    }
    function onRecoverTrial() {
        currentState.recoverTrail();
    }
    function onRecovered() {
        currentState.onRecovered();
    }
    function resume() {
        vjsPlayer.on("timeupdate", onRecovered);
        vjsPlayer.on("ended", onRecoverTrial);
        vjsPlayer.on("stalled", onRecoverTrial);
    }
    function pause() {
        vjsPlayer.off("timeupdate", onRecovered);
        vjsPlayer.off("ended", onRecoverTrial);
        vjsPlayer.off("stalled", onRecoverTrial);
    }
    function onVisibilityChange() {
        document[hidden] ? pause() : resume();
    }
}
import Player from "./Player.js";
import EVENT from "./Events.js";
import PRELOAD_TYPE from "./PreloadType.js";
import SecretiveDebug from "./extension/SecretiveDebug.js";
import LogLeaveTime from "./extension/LogLeaveTime.js";

const LiTVPlayerVersion = "__version";

export default class LiTVPlayer {
    constructor() {
        throw "This is a factory, you must create a new LiTVPlayer instance using the 'create' method.";
    }
    static instanceMap = new Map();
    static count = 0;
    static get $0() {
        return this.count > 0 ? this["$" + this.count] : undefined;
    }
    static create(container, options = {}) {
        if (this.instanceMap.has(container)) {
            return this.instanceMap.get(container);
        }

        let player = new Player(container, options, LiTVPlayerVersion);
        this.instanceMap.set(container, player);

        let instanceId = "$" + ++this.count;
        this[instanceId] = player;

        SecretiveDebug.addPlayer(container, player);
        LogLeaveTime(player);

        player.on(EVENT.WILL_SHUTDOWN, () => {
            SecretiveDebug.removePlayer(container, player);
            this[instanceId] = null;
            delete this[instanceId];
            this.instanceMap.delete(container);
        });
        return player;
    }
}

LiTVPlayer.prototype.EVENT = LiTVPlayer.EVENT = EVENT;
LiTVPlayer.prototype.version = LiTVPlayer.version = LiTVPlayerVersion;
LiTVPlayer.prototype.PRELOAD_TYPE = LiTVPlayer.PRELOAD_TYPE = PRELOAD_TYPE;

LiTVPlayer.conf = {};
let confProperty = ["traceAdRequestTime", "traceLeaveTime", "traceAdDuration"];
if (Object.defineProperty) {
    confProperty.forEach(key => {
        Object.defineProperty(LiTVPlayer, key, {
            set: val => (LiTVPlayer.conf[key] = val),
            get: () => LiTVPlayer.conf[key],
        });
    });
} else {
    confProperty.forEach(key => {
        LiTVPlayer.__defineGetter__(key, function () {
            return LiTVPlayer.conf[key];
        });
        LiTVPlayer.__defineSetter__(key, function (val) {
            LiTVPlayer.conf[key] = val;
        });
    });
}

if (window.LiTVPlayer) {
    if (typeof window.LiTVPlayer === "function" && typeof window.LiTVPlayer.create === "function") {
        window.LiTVPlayer = window.LiTVPlayer;
    } else {
        let temp = window.LiTVPlayer;
        window.LiTVPlayer = LiTVPlayer;
        if (typeof temp.prototype === "undefined") {
            for (let k in temp) {
                if (confProperty.includes(k)) LiTVPlayer.conf[k] = temp[k];
            }
        }
    }
} else {
    window.LiTVPlayer = LiTVPlayer;
}

document.addEventListener("keydown", onKeyDown, true);
document.addEventListener("keyup", onKeyUp, true);

function onKeyDown(event) {
    const player = Player.prototype.focus;
    if (!player) {
        return;
    }
    player.keyboardHandle.onKeyDown(event);
}
function onKeyUp(event) {
    const player = Player.prototype.focus;
    if (!player) {
        return;
    }
    player.keyboardHandle.onKeyUp(event);
}

import { ElementQueries } from "css-element-queries";
import { ENV, convertBoolString, getElement } from "./Util.js";
import urls from "./Urls";
import { getPuid } from "./PUID.js";
import ExApi from "../Api/ExApi.js";
import { inAllowedRegion } from "../Api/CdiApi.js";
import { callbackPpn } from "../Api/Pusti.js";
import LiTVPlayer from "../Component/LiTVPlayer.js";
import GptHosting from "../Component/GptHosting.js";
import { default as CMain, fabric as CFab } from "../ChannelListMod/MainView.js";
import { default as NMain, fabric as NFab } from "../Component/MainView.js";

export async function MainViewWrapper(ppnId, container, config) {
    let MainView = config.type == "ch" ? CMain : NMain;
    let fabric = config.type == "ch" ? CFab : NFab;
    // let { default: MainView, fabric } = await (config.type == "ch"
    //     ? import("../ChannelListMod/MainView.js")
    //     : import("../Component/MainView.js"));

    container.classList.add("ppn-container");
    container.classList.add("ppn-display-none");
    container.innerHTML = fabric;

    let exApi = ExApi(ppnId, container);
    await Promise.all([
        getPuid(exApi).then((puid) => (config.puid = puid)),
        checkRegion(container, config),
        waitStyleLoaded(container),
    ]);

    container.classList.remove("ppn-display-none");
    MainView(ppnId, container, config, exApi);
}

async function waitStyleLoaded(container) {
    if (!isStyleLoaded()) {
        await new Promise((resolve) => {
            let checkId = setInterval(() => {
                if (isStyleLoaded()) {
                    clearInterval(checkId);
                    resolve();
                }
            }, 100);
        });
    }
    ElementQueries.init();

    function isStyleLoaded() {
        return window.getComputedStyle(container).display == "none";
    }
}

async function checkRegion(container, config) {
    if (config.checkRegion) {
        return;
    }
    let allowed = await inAllowedRegion();
    if (allowed) {
        return;
    }
    GptHosting(config, container);
    throw "region_not_allowed";
}

export function createPlayer(container, config) {
    let $playerMain = getElement(container, ".ppn-player_main");

    return LiTVPlayer.create($playerMain, {
        decideVisible: true,
        allowAdPause: config.allowAdPause,
        preloadType: config.preloadAd || LiTVPlayer.PRELOAD_TYPE.THRESHOLD,
        preloadThreshold: config.visibleThreshold,
        visibleThreshold: 0.5,
        vpaidMode: config.vpaidMode,
        vastClickArea: config.vastClickArea,
        displayClickToUnMute: true,
        disableFullscreen: isDisableFullscreen(container, config),
        customApiUrlConfig: { cdiApi: { [ENV.current]: urls.cdiUrls } },
        env: ENV.current,
        isLite: true,
        useNativeFullscreen: true,
    });
}

function isDisableFullscreen(container, config) {
    let allowFullscreen = convertBoolString(container.dataset.allowFullscreen);
    if (allowFullscreen != null) {
        return !allowFullscreen;
    }
    if (window.ppnFullScreen && window.ppnFullScreen[config.partnerName]) {
        return false;
    }
    let videojs = window.videojs;
    return videojs && videojs.browser && (videojs.browser.IS_IOS || videojs.browser.IS_ANDROID);
}

export function sendInitTracking(config, tracing) {
    tracing.ga.sendOnce("request", "player", false);
    tracing.litv.pageInit();
    callbackPpn(config.puid);
}

export function sendImpressionTracking(tracing) {
    tracing.ga.sendOnce("impression", "player", false);
    tracing.litv.pageImpression();
}
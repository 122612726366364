import { getClosest, toggleClass } from "../Util/Util";
import { DAY_IN_SECONDS, GTM8_OFFSET } from "./constant";

export function isScheduleButton(element) {
    return getClosest(element, "svg.schedule_icon") != null;
}

export function traceChannelListItem(element) {
    return getClosest(element, ".channel-list .item");
}

export function traceScheduleProgram(element) {
    return getClosest(element, ".channel-schedule .program");
}

export function getProgramTitle(program) {
    if (!program) {
        return "";
    }
    return `${program.title || ""} ${program.subtitle || ""}`.trim();
}

export function updateCurrentItem(parent, selector) {
    let element = parent.querySelector(".current");
    if (element != null) {
        toggleClass(element, "current", false);
    }
    element = parent.querySelector(selector);
    toggleClass(element, "current", true);
    return element;
}

export function toDayStartTimestamp(timestamp) {
    return new Date((timestamp - ((timestamp + GTM8_OFFSET) % DAY_IN_SECONDS)) * 1e3) / 1e3;
}

export function toMilliseconds(second) {
    return second * 1e3;
}

window.ppnApi = window.ppnApi || {};

export function setupPpnAPI(ppnId, config, player, contentManager, errorComponent) {
    const { listener, playId, play: contentInfo } = window.ppnApi[ppnId] || {};

    window.ppnApi[ppnId] = PpnAPI(config.type == "vod", listener);

    return contentInfo != null ? contentInfo : playId != null ? { id: playId } : undefined;

    function PpnAPI(isPlayIdSettable, listener) {
        let onerror = (e, source) => {
            if (typeof listener == "function") {
                listener.call(null, { id: ppnId, type: "error", source: source });
            }
        };
        player.on(player.EVENT.IMPRESSION, eventListenerWrapper);
        player.on(player.EVENT.RESUME, eventListenerWrapper);
        player.on(player.EVENT.PAUSE, eventListenerWrapper);
        player.on(player.EVENT.ENDED, eventListenerWrapper);
        errorComponent.on(errorComponent.EVENT.OCCURRED_ERROR, onerror);

        if (isPlayIdSettable) {
            return {
                set listener(cb) {
                    listener = cb;
                },
                set playId(id) {
                    contentManager.play({ id });
                },
                set play(contentInfo) {
                    contentManager.play(contentInfo);
                },
            };
        }
        return {
            set listener(cb) {
                listener = cb;
            },
            set playId(id) {},
            set play(contentInfo) {},
        };

        function eventListenerWrapper(e) {
            if (typeof listener !== "function") {
                return;
            }
            if (!contentManager.isErrorMode) {
                return listener.call(null, { id: ppnId, type: e.type });
            }
            if (e.type == "impression") {
                // trigger error event while errorVideo is playing.
                return listener.call(null, { id: ppnId, type: "error", source: "meta" });
            }
        }
    }
}

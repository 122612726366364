import fetch from 'unfetch';

const ITEMS_PER_PAGE = 4;

function fixStyleKey() {
    return [
        {
            "sample": "transition",
            "transform": "transform",
            "transition": "transition",
            "transitionend": "transitionend",
            "transitionDuration": "transition-duration"
        },
        {
            "sample": "webkitTransition",
            "transform": "-webkit-transform",
            "transition": "-webkit-transition",
            "transitionend": "-webkit-transitionend",
            "transitionDuration": "-webkit-transition-duration"
        },
        {
            "sample": "MozTransition",
            "transform": "-moz-transform",
            "transition": "-moz-transition",
            "transitionend": "-moz-transitionend",
            "transitionDuration": "-moz-transition-duration"
        },
        {
            "sample": "msTransform",
            "transform": "-ms-transform",
            "transition": "-ms-transition",
            "transitionend": "-ms-transitionend",
            "transitionDuration": "-ms-transition-duration"
        },
        {
            "sample": "OTransition",
            "transform": "-o-transform",
            "transition": "-o-transition",
            "transitionend": "-o-transitionend",
            "transitionDuration": "-o-transition-duration"
        }
    ].filter(i => i.sample in document.body.style)[0];
}

function genInnerHTML(itemsHtmlList, pageCount) {
    let itemsHtml = genItemsHtml(itemsHtmlList);
    let pageIcons = "";
    for (let i = 1; i <= pageCount; i++) {
        pageIcons += `<a data-no=${i}></a>`;
    }
    return `
<div class="ppn-showcase_content_wrapper">
    <div class="ppn-showcase_content">${itemsHtml}</div>
</div>
<div class="ppn-showcase_page_icon">${pageIcons}</div>
`;
}

function genItemsHtml(itemsHtmlList) {
    let lastPageItemCount = itemsHtmlList.length % ITEMS_PER_PAGE || ITEMS_PER_PAGE;
    let complementCount = ITEMS_PER_PAGE - lastPageItemCount;
    return itemsHtmlList.concat(new Array(complementCount).fill(emptyItemHtml())).join("");
}

function emptyItemHtml() {
    return `<div class='ppn-showcase_item empty_item'></div>`;
}

export default function (config, meta, ancillary) {
    let transform, transition, transitionend, transitionDuration;

    let $ancillary;
    let $showcaseEl;
    let $showcaseContent;
    let $pageIcon;
    // let $pagePrev;
    // let $pageNext;
    let $contentList;
    let $imgList;
    let $lastItemList;
    let _pageCount;
    let _page;
    let _changePageTime;
    let _changePageTimeoutId;
    let _pos;
    let _o, _p;
    let progressRate = "idle";
    let isEnable = false;
    let firstImpressionListeners = [], clickListeners = [];
    let showcaseParser = null;

    (function init() {
        ({ transform, transition, transitionend, transitionDuration } = fixStyleKey());
        $ancillary = ancillary;
        showcaseParser = config.playlistUi == "out" ? PlaylistShowcaseParser(meta) : CommonShowcaseParser(config);
    })();

    function startUp() {
        progressRate = "processing";
        showcaseParser.getRenderData()
            .then(r => {
                renderView(r);
            })
            .then(() => behavior())
            .then(() => {
                progressRate = "complete";
                dispatchFirstImpression();
            })
    }

    function renderView({ itemsHtmlList, changePageTime }) {
        _pageCount = Math.ceil(itemsHtmlList.length / ITEMS_PER_PAGE);
        _changePageTime = changePageTime || 3;

        let showcaseEl = $ancillary.appendChild(document.createElement("div"));
        showcaseEl.className = "ppn-showcase";
        showcaseEl.innerHTML = genInnerHTML(itemsHtmlList, _pageCount);
        $showcaseContent = showcaseEl.querySelector(".ppn-showcase_content");
        $pageIcon = showcaseEl.querySelector(".ppn-showcase_page_icon");
        // $pagePrev = showcaseEl.querySelector(".ppn-prev");
        // $pageNext = showcaseEl.querySelector(".ppn-next");
        $contentList = Array.from(showcaseEl.querySelectorAll(".ppn-showcase_item"));
        $imgList = $contentList.map($e => $e.querySelector("img")).filter(item => !!item);
        $lastItemList = $contentList.slice(-ITEMS_PER_PAGE);
        $showcaseEl = showcaseEl;
        setPage(1);

        if (isEnable == false) {
            stopAutoChange();
            showcaseEl.style.display = "none";
        }

        return showcaseEl;
    }

    function behavior() {
        document.addEventListener("touchstart", e => { });
        $showcaseContent.addEventListener("touchstart", onTouchStart);
        $showcaseContent.addEventListener("touchmove", onTouchMove);
        $showcaseContent.addEventListener("touchend", onTouchEnd);
        $showcaseContent.addEventListener("touchcancel", onTouchEnd);
        $showcaseContent.addEventListener("mouseenter", onMouseEnter);
        $showcaseContent.addEventListener("mouseleave", onMouseLeave);

        $pageIcon.addEventListener("click", e => {
            if (e.target.tagName != "A") return;
            setPage(parseInt(e.target.dataset.no), null, "driving");
        });
        // $pagePrev.addEventListener("click", toLeftPage);
        // $pageNext.addEventListener("click", toRightPage);

        Array.from($showcaseContent.querySelectorAll(".ppn-showcase_item a")).forEach(item => {
            item.addEventListener("click", onClickItem);
        });
    }

    // function toLeftPage() {
    //     setPage(_page == 1 ? _pageCount : (_page - 1), "toLeft")
    // }

    // function toRightPage() {
    //     setPage(_page == _pageCount ? 1 : (_page + 1), "toRight")
    // }

    function onClickItem(e) {
        let eventData = showcaseParser.getClickEventData(e.currentTarget);
        dispatchClickItem(eventData);
    }

    function onTouchStart(e) {
        stopAutoChange();
        $showcaseContent.style.setProperty(transition, "none");
        _o = _p = e.targetTouches[0].clientX;
    }

    function onTouchMove(e) {
        const limit = $showcaseContent.offsetWidth * (_pageCount - 1);
        let p = _p;
        _p = e.targetTouches[0].clientX;
        _pos = _pos + p - _p;
        _pos = _pos > limit ? limit : _pos < 0 ? 0 : _pos;
        changePosition();
    }

    function onTouchEnd(e) {
        $showcaseContent.style.removeProperty(transition);
        if (_o < _p) {
            setPage(_page == 1 ? 1 : _page - 1, "driving");
        } else {
            setPage(_page == _pageCount ? _pageCount : _page + 1, "driving");
        }
    }

    function onMouseEnter(e) {
        stopAutoChange();
    }

    function onMouseLeave(e) {
        startAutoChange();
    }

    function setPage(page, type) {
        startAutoChange();
        if (page > _pageCount) return;
        if (page < 1) return;

        let chageType = computeChangePositionType(type, page);
        _page = page;
        _pos = (page - 1) * $showcaseContent.offsetWidth;
        changePosition(chageType);

        for (let i = (page - 1) * ITEMS_PER_PAGE, ln = i + ITEMS_PER_PAGE, lsLen = $imgList.length; i < ln && i < lsLen; i++) {
            let dataSrc = $imgList[i].dataset.src;
            if (dataSrc) {
                $imgList[i].src = dataSrc;
                $imgList[i].dataset.src = "";
            }

        }

        let pageFocus = $pageIcon.querySelector(".ppn-page_focus");
        if (pageFocus != null) pageFocus.classList.remove("ppn-page_focus");
        Array.from($pageIcon.querySelectorAll("a"))[page - 1].classList.add("ppn-page_focus");
    }

    function computeChangePositionType(setPageType, page) {
        let result = "default"
        switch (setPageType) {
            case "autoChange":
            case "toRight":
                if (page == 1) result = "overRight";
                break;
            case "toLeft":
                if (page == _pageCount) result = "overLeft";
                break;
        }
        return result;
    }

    function changePosition(type) {
        switch (type) {
            case "overRight":
                return positionOverRight();
            case "overLeft":
                return positionOverLeft();
        }
        transformX(_pos);
    }

    function positionOverRight() {
        // $pageNext.removeEventListener("click", toRightPage);
        $showcaseContent.addEventListener(transitionend, onOverRightTransitionend);
        $lastItemList.forEach(item => item.classList.add("ppn-order-1"));
        transformX(0);
        skipPositionChangeAnimation();
        transformX($showcaseContent.offsetWidth);
    }

    function onOverRightTransitionend(e) {
        // $pageNext.addEventListener("click", toRightPage);
        $showcaseContent.removeEventListener(transitionend, onOverRightTransitionend);
        $lastItemList.forEach(item => item.classList.remove("ppn-order-1"));
        transformX(0);
        skipPositionChangeAnimation();
    }

    function positionOverLeft() {
        // $pagePrev.removeEventListener("click", toLeftPage);
        $showcaseContent.addEventListener(transitionend, onOverLeftTransitionend);
        $lastItemList.forEach(item => item.classList.add("ppn-order-1"));
        transformX($showcaseContent.offsetWidth);
        skipPositionChangeAnimation();
        transformX(0);
    }

    function onOverLeftTransitionend(e) {
        // $pagePrev.addEventListener("click", toLeftPage);
        $showcaseContent.removeEventListener(transitionend, onOverLeftTransitionend);
        $lastItemList.forEach(item => item.classList.remove("ppn-order-1"));
        transformX($showcaseContent.offsetWidth * (_pageCount - 1));
        skipPositionChangeAnimation();
    }

    function skipPositionChangeAnimation() {
        $showcaseContent.style.setProperty(transitionDuration, "0s");
        $showcaseContent.offsetWidth // flush
        $showcaseContent.style.removeProperty(transitionDuration);
    }

    function transformX(x) {
        let tX = `translateX(${0 - x}px)`;
        $showcaseContent.style.setProperty(transform, tX);
    }

    function startAutoChange() {
        if (!_changePageTime || _pageCount <= 1) return;
        clearTimeout(_changePageTimeoutId);
        _changePageTimeoutId = setTimeout(() => {
            setPage((_page % _pageCount) + 1, "autoChange");
        }, _changePageTime * 1E3);
    }

    function stopAutoChange() {
        if (!_changePageTimeoutId) return;
        clearTimeout(_changePageTimeoutId);
    }

    function enable() {
        isEnable = true;
        if (progressRate == "idle") {
            startUp();
        } else if (progressRate == "complete") {
            $showcaseEl.style.display = "block";
            startAutoChange();
        }
    }

    function disable() {
        isEnable = false;
        if (progressRate !== "complete") return;
        stopAutoChange();
        $showcaseEl.style.display = "none";
    }

    function dispatchFirstImpression() {
        firstImpressionListeners.forEach(fn => fn());
    }

    function onFirstImpression(fn) {
        firstImpressionListeners.push(fn);
    }

    function dispatchClickItem(data) {
        clickListeners.forEach(fn => fn(data));
    }

    function onClick(fn) {
        clickListeners.push(fn);
    }

    return {
        enable,
        disable,
        onFirstImpression,
        onClick
    };
}

function PlaylistShowcaseParser(meta) {
    return {
        getRenderData: getRenderData,
        getClickEventData: getClickEventData,
    };

    function getRenderData() {
        return meta.getRecommend().then(function (meta) {
            const itemHtmlMap = ItemHtmlMap(false);
            return {
                itemsHtmlList: meta.list.map((item, index) => itemHtmlMap(index, `data-content-id="${item.contentId}"`, item.image, item)),
            };
        });
    }

    function getClickEventData(target) {
        return { isPlaylist: true, contentId: target.dataset.contentId };
    }
}

function CommonShowcaseParser({ showcaseMetaUrls }) {
    let urls = showcaseMetaUrls
    let getUrls = () => urls[(Math.random() * urls.length) | 0];

    return {
        getRenderData: getRenderData,
        getClickEventData: getClickEventData,
    };

    function getRenderData() {
        return fetch(getUrls())
            .then(function (response) {
                if (response.ok) {
                    return response.json();
                } else {
                    throw response;
                }
            })
            .then(function (meta) {
                const { content_list, page_interval, no_pic } = meta;
                const itemHtmlMap = ItemHtmlMap(no_pic);
                return {
                    itemsHtmlList: content_list.map((item, index) => itemHtmlMap(index, `href='${item.url}'`, item.pics_url, item)),
                    changePageTime: page_interval,
                };
            });
    }

    function getClickEventData(target) {
        return {
            page: target.dataset.page,
            item: target.dataset.item,
            title: target.title
        };
    }
}

function ItemHtmlMap(noPic) {
    const genThumb = noPic ? genNullThumb : genCommonThumb;

    return function (index, link, imageUrl, { target, title, subtitle }) {
        const page = Math.floor(index / ITEMS_PER_PAGE) + 1;
        const item = (index % ITEMS_PER_PAGE) + 1;
        return `<div class='ppn-showcase_item' data-index='${index}'><a ${link} title='${title}' target='${target || "_blank"}' data-page='${page}' data-item='${item}'>${genThumb(imageUrl)}${genTitle(title, subtitle)}</a></div>`;
    }

    function genNullThumb() {
        return "";
    }
    function genCommonThumb(imageUrl) {
        return `<div class='ppn-show-photo'><img data-src='${imageUrl}'><div class="ppn-playlist-playicon"></div></div>`;
    }
    function genTitle(title, subtitle) {
        return `<div class='ppn-show-article'><h2>${title}</h2><p>${subtitle}</p></div>`;
    }
}
const GA_LABEL = {
    VOD_IMPRESSION: "vod-impression",
    FIRST_IMPRESSION: "first-impression",
    FULLSCREEN: "em-fullscreen",
    LOGO_CLICK: "em-logo-click",
    UNMUTE: "em-unmute",
    FOOTER: "em-footer",
    ERROR: "em-error",
};

function sendGaFromPlayer(meta, exApi) {
    return function (label, affixation) {
        let { contentType, secondaryTitle, season, episode, videoType } = meta;
        let eventCategory = `${contentType}|${secondaryTitle}|${season}|${episode}|${videoType}`;
        exApi.gtag("event", "em", {
            event_label: label,
            event_category: eventCategory + (affixation ? "" : "|" + affixation),
        });
    };
}

export default function gaTracing(config, meta, player, exApi) {
    config.gaIds.forEach((id) => {
        exApi.gtag("config", id, { send_page_view: false, cookie_flags: "SameSite=None;Secure", user_id: config.puid });
    });
    let _sendGaFromPlayer = sendGaFromPlayer(meta, exApi);

    Array.from(document.querySelectorAll("[data-ga-tracing]")).forEach((item) => {
        delete item.dataset.gaTracing;
        item.addEventListener(item.dataset.gaAction, (e) => _sendGaFromPlayer(item.dataset.gaLabel));
    });

    player
        .one(player.EVENT.IMPRESSION, (e) => _sendGaFromPlayer(GA_LABEL.FIRST_IMPRESSION))
        .one(player.EVENT.FULLSCREEN, (e) => _sendGaFromPlayer(GA_LABEL.FULLSCREEN))
        .on(player.EVENT.IMPRESSION, (e) => _sendGaFromPlayer(GA_LABEL.VOD_IMPRESSION))
        .on(player.EVENT.CLICK_LOGO, (e) => e.id == "*litv_logo" && _sendGaFromPlayer(GA_LABEL.LOGO_CLICK))
        .on(player.EVENT.ERROR, (e) => _sendGaFromPlayer(GA_LABEL.ERROR, e.error.code))
        .on(player.EVENT.MUTE, onMutedChange);

    function onMutedChange(e) {
        if (e.muted == true) return;
        _sendGaFromPlayer(GA_LABEL.UNMUTE);
        player.off(player.EVENT.MUTE, onMutedChange);
    }

    function sendOnce() {
        let sended = [];
        return function (action, category, label) {
            let para = action + category;
            if (sended.indexOf(para) > -1) return;
            sended.push(para);
            send(action, category, label);
        };
    }

    function send(action, category, label) {
        let para = { event_category: category };
        if (label !== false) {
            para.event_label = label || meta.secondaryTitle;
        }
        exApi.gtag("event", action, para);
    }

    return {
        send,
        sendOnce: sendOnce(),
    };
}

import { clamp } from "../Util";

export default function (videojs) {
    const Component = videojs.getComponent("Component");
    const Button = videojs.getComponent("Button");
    class SeekButton extends Button {
        constructor(player, options) {
            super(player, options);
            this.on(["mousedown", "touchstart"], this.handleButtonDown);
            this.stepTime = SeekAccelerator(this.options_.step);
        }
        buildCSSClass() {
            return "vjs-seek-button " + super.buildCSSClass();
        }
        handleClick() {}
        handleButtonDown() {
            const player = this.player_;
            const seekBar = player.controlBar.progressControl.seekBar;
            seekBar.startSeeking();

            const { icon } = this.options_;
            const duration = player.duration();
            let seekTime = player.currentTime();
            const seeking = () => {
                const time = this.stepTime.next();
                player.toast.iconHint(icon, seekHint(time));
                seekTime = clamp(seekTime + time, duration, 0);
                seekBar.updateByTime(seekTime);
            };
            seeking();
            this.seekingTimeout = setTimeout(() => (this.seekingInterval = setInterval(seeking, 100)), 100);
            this.off(["mousedown", "touchstart"], this.handleButtonDown);
            this.on(["mouseup", "mouseleave", "touchend"], this.handleButtonUpOrLeave);
            this.on("touchmove", this.handleTouchMove);
        }
        handleTouchMove(event) {
            const touch = event.touches[0];
            if (this.el() !== document.elementFromPoint(touch.pageX, touch.pageY)) {
                this.handleButtonUpOrLeave();
            }
        }
        handleButtonUpOrLeave() {
            this.stepTime.reset();
            if (this.seekingTimeout) {
                clearTimeout(this.seekingTimeout);
                this.seekingTimeout = null;
            }
            if (this.seekingInterval) {
                clearInterval(this.seekingInterval);
                this.seekingInterval = null;
            }
            this.player_.controlBar.progressControl.seekBar.stopSeeking();
            this.off(["mouseup", "mouseleave", "touchend"], this.handleButtonUpOrLeave);
            this.off("touchmove", this.handleTouchMove);
            this.on(["mousedown", "touchstart"], this.handleButtonDown);
        }
    }
    class ForwardButton extends SeekButton {
        constructor(player) {
            super(player, { icon: "forward", step: 1 });
        }
        buildCSSClass() {
            return "vjs-seek-forward " + super.buildCSSClass();
        }
    }
    class BackwardButton extends SeekButton {
        constructor(player) {
            super(player, { icon: "backward", step: -1 });
        }
        buildCSSClass() {
            return "vjs-seek-backward " + super.buildCSSClass();
        }
    }
    Component.registerComponent("ForwardButton", ForwardButton);
    Component.registerComponent("BackwardButton", BackwardButton);
}

function seekHint(time) {
    const prefix = time > 0 ? "+" : "-";
    const hint = Math.floor(Math.abs(time) / 10) * 10 + "s";
    return prefix + hint;
}

function SeekAccelerator(step) {
    const SEEK_ACCELERATION = 5;
    const SEEK_BASE_SPEED = 10;
    const SEEK_MAX_SPEED = 30;
    let lastCallTime = 0;
    let speed = 0;
    return {
        next() {
            const timestamp = Date.now() * 0.001;
            const timeDiff = timestamp - lastCallTime;
            if (timeDiff < 0.5) {
                speed = Math.min(speed + SEEK_ACCELERATION * timeDiff, SEEK_MAX_SPEED);
            } else {
                speed = SEEK_BASE_SPEED;
            }
            lastCallTime = timestamp;
            return step * speed;
        },
        reset() {
            lastCallTime = 0;
            speed = SEEK_BASE_SPEED;
        },
    };
}

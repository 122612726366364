import backIcon from "@images/epg/back.svg";
import epgCloseIcon from "@images/epg/close.svg";
import channelScroll from "@images/epg/channel_scroll.svg";
import { ResizeSensor } from "css-element-queries";
import ChannelList from "./ChannelList.js";
import Schedule from "./Schedule.js";
import Event from "./Event.js";
import { createDivElement, getElement } from "../Util/Util.js";
import { EPG_STYLE_IN, EPG_STYLE_NONE, EPG_STYLE_OUT } from "./constant.js";

const fabric = `
<div class="epg-close-area">${epgCloseIcon}</div>
<div class="epg-menu-area">
    <div class="channel-list-wrapper">
        ${channelScroll}
        <div class="menu-header"></div>
        <div class="channel-list"></div>
        ${channelScroll}
    </div>
    <div class="channel-schedule-wrapper">
        <div class="menu-header">${backIcon}</div>
        <div class="channel-schedule"></div>
    </div>
</div>
`;

export default function Epg($content, meta, player, eventCenter) {
    const isSingleChannel = meta.channelList.length <= 1;
    if ((isSingleChannel && !meta.channelList[0]?.schedule) || meta.epgStyle == EPG_STYLE_NONE) {
        $content.dataset.epgStyle = EPG_STYLE_NONE;
        return;
    }

    const openEpg = isSingleChannel
        ? () => eventCenter.trigger(Event.EpgSchedule, { channelIndex: 0 })
        : () => eventCenter.trigger(Event.EpgChannel);

    player.on(player.EVENT.REQUEST_SCHEDULE, openEpg);
    eventCenter.on(Event.EpgOpen, openEpg);
    eventCenter.on(Event.EpgClose, closeEpg);
    eventCenter.on(Event.EpgChannel, openChannel);
    eventCenter.on(Event.EpgSchedule, openSchedule);

    const epgContainer = document.createElement("div");
    epgContainer.classList.add("epg-container");
    epgContainer.innerHTML = fabric;

    const epgMenuLayer = getElement(epgContainer, ".epg-menu-area");
    ChannelList(epgMenuLayer, meta, eventCenter);
    Schedule(epgMenuLayer, meta, eventCenter);

    const closeArea = getElement(epgContainer, ".epg-close-area");
    closeArea.onclick = () => eventCenter.trigger(Event.EpgClose);

    ResizeHandler($content, meta, player, epgContainer, eventCenter);

    function closeEpg() {
        setEpgPage("");
    }
    function openChannel() {
        setEpgPage("channel");
    }
    function openSchedule() {
        setEpgPage("schedule");
    }
    function setEpgPage(page) {
        $content.dataset.epgPage = page;
    }
}

function ResizeHandler($content, meta, player, epgContainer, eventCenter) {
    const epgFixedAnchor = createDivElement($content, "ppn-epg-fixed-anchor");
    const epgFloatAnchor = createDivElement(player.el(), "ppn-epg-float-anchor");

    let isFullscreen = false;
    player.on(player.EVENT.FULLSCREEN, onFullScreen);
    player.on(player.EVENT.FULLWINDOW, onFullScreen);
    player.on(player.EVENT.FULLSCREEN_EXIT, onExitFullScreen);
    player.on(player.EVENT.FULLWINDOW_EXIT, onExitFullScreen);

    new ResizeSensor($content, onResize);

    function onFullScreen() {
        isFullscreen = true;
        setupFloatEpg();
    }

    function onExitFullScreen() {
        isFullscreen = false;
        onResize();
    }

    function setupFloatEpg() {
        $content.dataset.epgStyle = EPG_STYLE_IN;
        if (!epgFloatAnchor.contains(epgContainer)) {
            epgFloatAnchor.appendChild(epgContainer);
        }
    }

    function setupFixedEpg() {
        $content.dataset.epgStyle = EPG_STYLE_OUT;
        if (!epgFixedAnchor.contains(epgContainer)) {
            epgFixedAnchor.appendChild(epgContainer);
        }
    }

    function setupEpgNone() {
        $content.dataset.epgStyle = EPG_STYLE_NONE;
        if (epgContainer.parentElement != null) {
            epgContainer.parentElement.removeChild(epgContainer);
        }
    }

    function onResize() {
        let width = $content.offsetWidth;

        if (width < 250 || (width < 480 && $content.dataset.epgPage == "schedule")) {
            eventCenter.trigger(Event.EpgClose);
            return setupEpgNone();
        }
        if (width < 840 || isFullscreen || meta.epgStyle == EPG_STYLE_IN) {
            return setupFloatEpg();
        }
        eventCenter.trigger(Event.EpgOpen);
        return setupFixedEpg();
    }
}

export default function handleWaitingTimeout(vjsPlayer) {
    const WAIT_TIMEOUT_IN_MILLISECONDS = 10e3;
    let waitingEvent = null;

    function onWaiting() {
        clearWaitingTimeout();
        waitingEvent = setTimeout(refresh, WAIT_TIMEOUT_IN_MILLISECONDS);
        vjsPlayer.on("playing", clearWaitingTimeout);
    }
    function clearWaitingTimeout() {
        if (waitingEvent != null) {
            clearTimeout(waitingEvent);
            waitingEvent = null;
        }
        vjsPlayer.off("playing", clearWaitingTimeout);
    }
    function refresh() {
        vjsPlayer.src(vjsPlayer.src());
        vjsPlayer.play();
    }
    return {
        start() {
            clearWaitingTimeout();
            vjsPlayer.on("waiting", onWaiting);
        },
        stop() {
            clearWaitingTimeout();
            vjsPlayer.off("waiting", onWaiting);
        },
    };
}

import EVENT from '../Events.js';

export default function(player){
    if(LiTVPlayer.conf.traceLeaveTime !== true) return;

    window.addEventListener("beforeunload", sedLog);
    player.on(EVENT.AD_PROGRESS, adProgress);
    function adProgress(e){
        if((e.trunkType == "prerolls" && e.state == "AdMediaStart") || 
           (e.partType == "prerolls" && e.state == "AdStreamComplete")){
            window.removeEventListener("beforeunload", sedLog);
            player.off(EVENT.AD_PROGRESS, adProgress);
        }
    }
    function sedLog(){
        try{
            player.liAd.report.postData({
                cdata: "leaveBeforeStarting",
                ad_id: "1000002",
                ad_clip: `leaveBeforeStarting|${performance.now() >> 0}`,
                asset_id: ""
            });
        }catch(e){}
    }
}
import scheduleLiveIcon from "@images/epg/schedule_live.svg";
import scheduleVodIcon from "@images/epg/schedule_vod.svg";
import { createDivElement, getElement } from "../Util/Util";
import Event from "./Event";
import { isScheduleButton, traceChannelListItem, updateCurrentItem } from "./utils";
import { CHANNEL_LIST_SCROLL_OFFSET } from "./constant";

export default function ChannelList(root, meta, eventCenter) {
    const container = getElement(root, ".channel-list-wrapper");
    const list = getElement(container, ".channel-list");
    const [scrollUpIcon, scrollDownIcon] = container.querySelectorAll(".channel-scroll");

    list.onclick = onClickList;

    meta.channelList.forEach((channel, index) => createChannelListItem(list, channel, index));

    eventCenter.on(Event.ChannelChange, onChannelChange);

    scrollUpIcon.onclick = () => scrollTo(list.scrollTop - CHANNEL_LIST_SCROLL_OFFSET);
    scrollDownIcon.onclick = () => scrollTo(list.scrollTop + CHANNEL_LIST_SCROLL_OFFSET);
    let ob = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                let className = entry.target.dataset.index == 0 ? "reach-top" : "reach-bottom";
                container.classList.toggle(className, entry.isIntersecting);
            });
        },
        { root: list, threshold: 1 }
    );
    ob.observe(list.children[0]);
    ob.observe(list.children[list.children.length - 1]);

    function createChannelListItem(container, channel, index) {
        let { picture, schedule, selectable, title } = channel;
        let icon = schedule == null ? "" : selectable ? scheduleVodIcon : scheduleLiveIcon;
        let item = createDivElement(container, "item");
        item.dataset.index = index;
        item.innerHTML = `<div><img src=${picture}></img><span>${title}</span>${icon}</div>`;
    }

    function onChannelChange() {
        updateCurrentItem(list, `.item[data-index="${meta.channelIndex}"]`);
    }

    function onClickList(event) {
        let itemEl = traceChannelListItem(event.target);
        if (!itemEl) {
            return;
        }
        let channelIndex = +itemEl.dataset.index;
        if (isScheduleButton(event.target)) {
            eventCenter.trigger(Event.EpgSchedule, { channelIndex });
            return;
        }
        if (channelIndex != meta.channelIndex) {
            scrollTo(itemEl.offsetTop - scrollUpIcon.getBoundingClientRect().height - list.offsetTop);
            eventCenter.trigger(Event.PLAY, { channelIndex });
        }
    }

    function scrollTo(scrollTop) {
        list.scrollTo({ top: scrollTop, behavior: "smooth" });
    }
}

import { ENV } from "./Util.js";
import generalConfigJson from "/src/general_config.json";

const urls = generalConfigJson[ENV.current].urls;

export default urls;

function getUrl(urls) {
    return urls[(Math.random() * urls.length) | 0];
}

export function acsUrl() {
    return getUrl(urls.acs);
}

export function yonderCdiUrl() {
    return getUrl(urls.yonder_cdi);
}

export function ppnCdiUrl() {
    return urls.ppn_cdi;
}

export function cdiUrl() {
    return getUrl(urls.cdi);
}

export function cdnUrl() {
    return getUrl(urls.cdn);
}

export function taiwanMediaUrl() {
    return getUrl(urls.taiwanmedia);
}

export function pustiUrl() {
    return getUrl(urls.pusti);
}

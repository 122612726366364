import { ENV } from "../Util/Util.js";
import { pustiUrl } from "../Util/Urls.js";
import { postOption, rpcOption, rpcResponseCheck } from "../Util/requestHelper.js";

const deviceCategory = ENV.isMobile ? "PPN-EM-mobile" : "PPN-EM-desktop";

export async function fetchPuid(puid) {
    let params = {
        user_agent: navigator.userAgent,
        aaid: "",
        device_category: deviceCategory,
        device_id: "",
        idfa: "",
        puid: puid || "",
    };
    let option = rpcOption("PustiService.PUID", params);
    option.credentials = "include";
    let request = fetch(pustiUrl() + "puid", option);
    let result = await rpcResponseCheck(request, "Failed to fetch puid");
    return result.puid;
}

export function callbackPpn(puid) {
    let hostUrl = window.ppnifRef || window.location;
    let params = {
        Host: encodeURIComponent(hostUrl.hostname),
        Path: encodeURIComponent(hostUrl.pathname + hostUrl.search),
        Titles: encodeURIComponent(document.title),
        PUID: puid,
    };
    fetch(pustiUrl() + "callback/ppn", postOption(params));
}

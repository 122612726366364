import gaTracing from "./GaTracing.js";
import comscoreTracing from "./ComscoreTracing.js";
import litvTracing from "./LiTVTracing.js";
import reportTracking from "./ReportTracking.js";
import monkeyTracing from "./MonkeyTracing.js";

export default class Tracing {
    constructor(config, meta, player, exApi) {
        return {
            report: reportTracking(config, meta, player),
            ga: gaTracing(config, meta, player, exApi),
            comscore: comscoreTracing(config, meta, player),
            litv: litvTracing(config, player),
            monkey: monkeyTracing(config, meta),
        };
    }
}

import { GetUrlsNoAuth } from "../Api/CdiApi.js";
import ObeyPlayBehavior from "../Util/ObeyPlayBehavior.js";
import { ENV, errorVideo, getElement } from "../Util/Util.js";
import ErrorComponent from "./Error.js";

export default class ContentManager {
    constructor($content, config, meta, player, errorComponent) {
        this.meta = meta;
        this.config = config;
        this.getUrlsNoAuth = GetUrlsNoAuth(config);
        this.player = player;
        this.obeyPlayBehavior = new ObeyPlayBehavior(player.container, config);
        this.$content = $content;
        this.$title = getElement($content, ".ppn-title");
        this.errorComponent = errorComponent;
        this.refreshP;
        this.refresh = this.refresh.bind(this);
        this.playNext = this.playNext.bind(this);
        this.play = this.play.bind(this);
        this.isErrorMode = false;
        this.firstErrorAssetId = null;
        player.mute(true);
    }

    refresh(contentInfo) {
        this.refreshP = (async () => {
            try {
                this.resetErrorMode();
                let { done } = await this.meta.next(contentInfo);
                if (!done) {
                    this.$title.innerText = this.meta.secondaryTitle;
                    this.$title.href = this.meta.bottomLink || "";
                }
                this.player.countdownComponent.setThumbnail(this.meta.image);
            } catch (error) {
                this.setErrorMode();
            }
        })();

        return this.refreshP;
    }

    play(contentInfo) {
        this.refresh(contentInfo);
        this.playNext();
    }

    async playNext() {
        try {
            await this.refreshP;
            let autoPlay = this.obeyPlayBehavior.calcAutoPlay();
            let contentUrl = await this.getContentUrl();
            this.player.setSrc({
                src: contentUrl,
                startTime: this.isErrorMode || this.meta.startTime,
                mediaMode: this.meta.mediaMode,
                assetId: this.meta.assetId,
                caption:
                    this.isErrorMode ||
                    (this.config.insideTitle !== false && {
                        text: this.meta.secondaryTitle,
                        link: this.meta.insideTitleLink,
                    }),
                cover: this.isErrorMode || this.meta.image,
                enableCountdown: true,
                midrollTimeCodes: this.meta.midrollTimeCodes,
                muted: this.muted,
                playAds: true,
                autoPlay: autoPlay,
                liadMeta: this.meta.liad,
                hiddenCheck: true,
                companionAdSize: [{ width: 300, height: 250 }],
                puid: this.config.puid,
                keepPlayingAd: true,
            });

            if (ENV.isMobile) {
                this.player.countdownComponent.setThumbnail("");
            }
        } catch (error) {
            if (this.firstErrorAssetId == null) {
                this.firstErrorAssetId = this.meta.assetId;
            }
            console.log("@@ find next @@");
            this.play();
        }
    }

    async getContentUrl() {
        if (this.isErrorMode) {
            return errorVideo;
        }
        if (this.config.getUrls == "fixedly") {
            return this.meta.streamUrl;
        }
        let assetId = this.meta.assetId;
        try {
            let contentUrl = await this.getUrlsNoAuth(assetId, this.meta.mediaType);
            this.firstErrorAssetId = null;
            return contentUrl;
        } catch (error) {
            if (error.code == 87 || error.code == 42000087) {
                this.errorComponent.resolve(error, ErrorComponent.ERROR_SOURCE.GET_URL);
            } else if (this.firstErrorAssetId == assetId) {
                this.firstErrorAssetId = null;
            } else {
                throw error;
            }
            this.setErrorMode();
            return errorVideo;
        }
    }

    setErrorMode() {
        this.$content.classList.add("ppn-error_mode");
        this.isErrorMode = true;
    }

    resetErrorMode() {
        this.$content.classList.remove("ppn-error_mode");
        this.isErrorMode = false;
    }
}

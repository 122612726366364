import fetch from "unfetch";

export function getUrlParameters(url) {
    var parameters = {};
    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        parameters[key] = decodeURI(value);
    });
    return parameters;
}

export function domBeIncluded(dom, targets, top) {
    if (!top) top = document.body;
    function core(dom) {
        if (dom == null || dom == top) return false;
        if (targets.includes(dom)) return true;
        return core(dom.offsetParent);
    }
    return core(dom);
}

export async function getAssets(url) {
    if (__RELEASE_MODE__ || !ENV.useLocal) {
        url = `https://${ENV.current == ENV.STAGING ? "staging-" : ""}player.svc.litv.tv${url}`;
    } else {
        url = `${location.protocol}//${ENV.host}${url}`;
    }
    // url = `https://staging-player.svc.litv.tv${url}`;
    // add Cache busting, update every 12 hr.
    url += `?cb=${(+new Date() / 432e5) | 0}`;

    const response = await fetch(url);
    if (!response.ok) {
        let error = new Error(`${response.statusText}: ${url}`);
        error.code = `HTTP ${response.status}`;
        throw error;
    }
    return response.json();
}

export let ENV = (() => {
    const STAGING = "staging";
    const PRODUCTION = "production";
    let host;
    let hostHref = window.location.href;
    if (hostHref.indexOf("/ex.html") == -1) {
        hostHref = document.querySelector("script[src*='ppnio.js']").src;
        host = /\/\/(.+?)\//g.exec(hostHref)[1];
    } else {
        host = window.location.host;
    }

    let current;
    if (__RELEASE_MODE__) {
        current = /staging-/.test(host) ? STAGING : PRODUCTION;
    } else {
        current = STAGING;
    }

    return { host, current, STAGING, PRODUCTION, isMobile: mobileCheck() };
})();

if (!__RELEASE_MODE__) {
    // switch between local and staging data for dev mode 
    ENV.useLocal = true;
}

export let toPromise = (() => {
    function isPromise(obj) {
        return !!obj && (typeof obj === "object" || typeof obj === "function") && typeof obj.then === "function";
    }

    function verify(src, resolve, reject) {
        if (typeof src == "function") {
            verify(src(), resolve, reject);
        } else if (isPromise(src)) {
            src.then(resolve).catch(reject);
        } else {
            resolve(src);
        }
    }

    return function (src) {
        return new Promise((resolve, reject) => {
            verify(src, resolve, reject);
        });
    };
})();

function mobileCheck() {
    if (navigator.platform === "MacIntel") {
        if (navigator.maxTouchPoints > 1) return true;
        return false;
    }
    let a = navigator.userAgent || navigator.vendor || window.opera;
    return (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
        )
    );
}

export let isIOSAndInIFrame = (() => {
    const UA = navigator.userAgent;
    let isIOS = /iPad|iPhone|iPod/.test(UA) && !window.MSStream; //TODO 2018 iPad ?
    let inIframe = (() => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    })();

    return isIOS && inIframe;
})();

export function logoAdPart(elementId) {
    return {
        element_id: [[elementId]],
        partobj_ratio: 10,
        min_interval: 0,
        element_sampling: 1,
        req_timeshift: 0,
        rewind: 0,
        adobj_sampling: [0],
        filling: -1,
    };
}

export function logoAdElement(elementId, image, clickThrough, sponsorName, position) {
    return {
        id: elementId,
        unit_id: -1,
        users: "All",
        media_type: "image",
        schema: "litv",
        space_id: `${sponsorName}_litvlogo`,
        data: image,
        duration: 0,
        position: position,
        click_through: clickThrough,
        purchase_url: "",
        title: "litv_logo",
    };
}

export function createDivElement(parent, className) {
    let element = parent.appendChild(document.createElement("div"));
    element.classList.add(className);
    return element;
}

export function getElement(root, selector) {
    return root.querySelector(selector);
}

export function getClosest(element, selector) {
    return element.closest(selector);
}

export function toggleClass(element, className, force) {
    element.classList.toggle(className, force);
}

export function convertBoolString(bool) {
    return bool === "true" ? true : bool === "false" ? false : null;
}

export const genUuid = (function () {
    if (window.crypto && window.crypto.randomUUID) {
        return () => window.crypto.randomUUID();
    }
    let rand = window.crypto ? () => crypto.getRandomValues(new Uint8Array(1))[0] : () => (Math.random() * 256) | 0;
    return () =>
        ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (rand() & (15 >> (c / 4)))).toString(16));
})();

export function noop() {}

export const errorVideo = "https://lispeedtest.akamaized.net/ppninstead/index.m3u8";

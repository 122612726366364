import { genUuid } from "./Util";

export default function PalHandler(container, playerType, playerVersion) {
    const targetMacro = "[paln]";
    let nonceLoader = null;
    let getNonce = puid => {
        try {
            nonceLoader = new goog.pal.NonceLoader();
            getNonce = generateNonce;
            return generateNonce(puid);
        } catch (e) {}
        return null;
    };

    return {
        async replace(url, puid) {
            if (typeof url != "string" || url.indexOf(targetMacro) < 0) {
                return url;
            }
            const nonce = await getNonce(puid);
            return nonce ? url.replace(targetMacro, nonce) : url;
        },
    };

    async function generateNonce(puid) {
        const request = new goog.pal.NonceRequest();
        const url = getUrl();
        request.sessionId = genUuid();
        request.adWillAutoPlay = true;
        request.adWillPlayMuted = true;
        request.continuousPlayback = false;
        request.descriptionUrl = url;
        request.iconsSupported = false;
        request.playerType = playerType;
        request.playerVersion = playerVersion;
        request.ppid = puid;
        request.url = url;
        request.videoHeight = container.offsetHeight;
        request.videoWidth = container.offsetWidth;

        return nonceLoader
            .loadNonceManager(request)
            .then(manager => manager.getNonce())
            .catch(error => {
                console.log("Error generating nonce: " + error);
                return null;
            });
    }

    function getUrl() {
        try {
            return top.document.URL;
        } catch (e) {}
        return document.referrer;
    }
}

import ObserverPattern from "../Util/ObserverPattern.js";
import { domBeIncluded, toPromise } from "../Util/Util.js";

const EVENT = {
    OPENED: "opened",
    CLOSED: "closed",
    CLICK_ITEM: "clickItem",
};

function createTtemplate() {
    return `
    <div class="ppn-recommend_tab"></div>
    <div class="ppn-list_container">
    </div>`;
}

function updateList(recommend) {
    let recommendList = recommend.list.map((item) => createListItemTemplate(item)).join("");
    this.listEl.innerHTML = `
    <div class="header">
        <div class="ppn-top_btn button"></div>
    </div>
    <ul>
        ${recommendList}
    </ul>`;
}

function createListItemTemplate(item) {
    return `
    <li class="ppn-recommend_item">
        <a data-content-id="${item.contentId}">
            <div class="ppn-recommend_photo">
                <img src="${item.image}">
                <div class="ppn-playlist-playicon"></div>
            </div>
            <div class="ppn-text">
                <h2 class="ppn-pl-item-title">${item.title}</h2>
                <span class="ppn-display_count">${item.subtitle}</span>
            </div>
        </a>
    </i>`;
}

function setUpView(player, displayType) {
    let $recommend = document.createElement("DIV");
    $recommend.classList.add("ppn-recommend");
    $recommend.classList.add("ppn-" + displayType);
    $recommend.innerHTML = createTtemplate();
    player.appendChild($recommend);

    this.view = $recommend;
    this.listEl = $recommend.querySelector(".ppn-list_container");

    return $recommend;
}

function onClick(player, $recommend) {
    let $recommendTab = $recommend.querySelector(".ppn-recommend_tab");
    let $container = player.container;
    let $controls = $container.querySelector(".fp-controls");

    return (e) => {
        if (e.target == $recommendTab) {
            this.toggle(true);
            e.preventDefault();
            return;
        }

        if (this.isOpened == false) return;
        let in$recommend = domBeIncluded(e.target, [$recommend], $container);
        if (in$recommend) {
            let contentId = getClickContentId(e.target, $container);
            if (contentId) {
                this.trigger(EVENT.CLICK_ITEM, { contentId });
            }
            return;
        }

        let in$controls = domBeIncluded(e.target, [$controls], $container);
        if (!player.paused && !in$controls) {
            e.preventDefault();
        }

        setTimeout(() => this.close());
    };

    function getClickContentId(dom, top) {
        if (!top) top = document.body;
        function core(dom) {
            if (dom == null || dom == top) return "";
            return dom.dataset.contentId || core(dom.parentNode);
        }
        return core(dom);
    }
}

function toggle(conscious) {
    if (this.isOpened == true) this.close(conscious);
    else this.open(conscious);
}

function open(conscious) {
    if (this.isOpened == true) return;
    conscious = conscious === true;
    this.view.classList.add("ppn-open");
    this.isOpened = true;
    this.isLocked = conscious;
    this.trigger(EVENT.OPENED, { conscious: conscious || false });
}

function close(conscious) {
    if (this.isOpened == false) return;
    conscious = conscious === true;
    if (this.isLocked && !conscious) return;
    this.view.classList.remove("ppn-open");
    this.isOpened = false;
    this.isLocked = false;
    this.trigger(EVENT.CLOSED, { conscious: conscious || false });
}

export default class RecommendComponent extends ObserverPattern {
    //displayType "hide", "show", "only_fullscreen"
    constructor(config, meta, player, contentManager, tracing) {
        super();
        let operating = {
            toggle: () => {},
            open: () => {},
            close: () => {},
            onClick: () => {},
            refresh: () => {},
        };
        this.toggle = (conscious) => operating.toggle(conscious);
        this.open = (conscious) => operating.open(conscious);
        this.close = (conscious) => operating.close(conscious);
        this.onClick = (e) => operating.onClick(e);
        this.refresh = () => operating.refresh();
        this.view = null;
        this.isOpened = false;
        this.isLocked = false;

        let displayType = config.recommendDisplay;
        let $recommend = setUpView.apply(this, [player, displayType]);
        operating.onClick = onClick.apply(this, [player, $recommend]);
        operating.toggle = toggle.bind(this);
        operating.open = open.bind(this);
        operating.close = close.bind(this);
        operating.refresh = () =>
            toPromise(meta.getRecommend).then((recommend) =>
                isEmpty(recommend) ? pause() : recommendHandler(recommend)
            );

        let isEmpty = (recommend) => !recommend || recommend.list.length == 0;

        let pause = () => {
            recommendHandler = resume;
            player.off(player.EVENT.PAUSE, this.open);
            player.off(player.EVENT.FILM_FINISH, this.close);
            player.off(player.EVENT.RESUME, this.close);
            player.off(player.EVENT.CLICK, this.onClick);
            this.view.classList.add("vjs-hidden");
            this.close();
        };

        let resume = (recommend) => {
            recommendHandler = refresh;
            player.on(player.EVENT.PAUSE, this.open);
            player.on(player.EVENT.FILM_FINISH, this.close);
            player.on(player.EVENT.RESUME, this.close);
            player.on(player.EVENT.CLICK, this.onClick);
            this.view.classList.remove("vjs-hidden");
            refresh(recommend);
        };

        let refresh = (recommend) => {
            updateList.apply(this, [recommend]);
            this.close();
        };

        let recommendHandler = resume;

        this.refresh();

        this.on(EVENT.CLICK_ITEM, function (e) {
            tracing.report.end();
            contentManager.play({ id: e.contentId });
        });
    }
}

RecommendComponent.prototype.EVENT = RecommendComponent.EVENT = EVENT;

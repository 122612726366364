import { WatchLogPost } from "../Api/PpnCdi";
import Event from "./Event";

const POST_INTERVAL = 30e3;

export default function WatchLog(config, meta, eventCenter) {
    let postLog = WatchLogPost(config, meta);
    let eventId = null;

    eventCenter.on(Event.ProgramChange, start);
    window.addEventListener("unload", stop);

    function start({ channelIndex }) {
        if (channelIndex != meta.channelIndex) {
            return;
        }
        stop();
        postLog();
        eventId = setInterval(postLog, POST_INTERVAL);
    }

    function stop() {
        if (eventId != null) {
            clearInterval(eventId);
            eventId = null;
        }
    }
}

import fetch from "unfetch";

export function postOption(payload) {
    return {
        method: "POST",
        headers: {
            "Accept": "application/json, text/plain, */*",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
    };
}

export function rpcOption(method, params) {
    return postOption({
        id: (Math.random() * 1000) | 0,
        jsonrpc: "2.0",
        method,
        params,
    });
}

export async function rpcResponseCheck(rpcRequest) {
    let response;
    try {
        response = await rpcRequest;
    } catch (err) {
        let error = new Error(errorMessage);
        throw error;
    }

    if (!response.ok) {
        let error = new Error(`${response.statusText}: ${response.url}`);
        error.code = `HTTP ${response.status}`;
        throw error;
    }

    let responseJson = await response.json();
    if (responseJson.error) {
        let error = new Error(responseJson.error.message);
        error.code = responseJson.error.code;
        throw error;
    }

    return responseJson.result;
}

export function fetchQuery(baseUrl, params = {}) {
    return fetchRetry(baseUrl + new URLSearchParams(params), null, 1);
}

export function fetchRpc(url, method, params = {}) {
    return fetchRetry(url, rpcOption(method, params), 1);
}

// todo: use url getter if needed
async function fetchRetry(url, options, n = 0, delayTime = 0) {
    if (delayTime) {
        await delay(delayTime);
    }
    try {
        const response = await fetch(url, options);
        if (response.ok || n < 1) {
            return response;
        }
    } catch (err) {
        if (n < 1) {
            throw err;
        }
    }
    return await fetchRetry(url, options, n - 1, 100);
}

function delay(interval) {
    return new Promise((resolve) => {
        setTimeout(resolve, interval);
    });
}

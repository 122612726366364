export default function preventTurnPageBySwipe(container) {
    let startX, startY;
    container.addEventListener(
        "touchstart",
        function (event) {
            let touch = event.touches[0];
            startX = touch.clientX;
            startY = touch.clientY;
        },
        { passive: false }
    );
    container.addEventListener(
        "touchmove",
        function (event) {
            let touch = event.touches[0];
            let deltaX = touch.clientX - startX;
            let deltaY = touch.clientY - startY;
            if (Math.abs(deltaX) > Math.abs(deltaY)) {
                event.preventDefault();
            }
        },
        { passive: false }
    );
}

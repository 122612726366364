import EVENT from "./Events.js";

export default function UnmuteMask(cxt) {
    let _this = cxt;
    let unmuteMask = null;

    function unmuteClick(e) {
        removeEvents();
        _this.mute(false);
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        removeUnmuteMask();
    }

    function handleUnmute(e) {
        if (e.muted == true) {
            return;
        }
        removeEvents();
        removeUnmuteMask();
    }

    function removeUnmuteMask() {
        if (unmuteMask == null) {
            return;
        }
        unmuteMask.parentNode.removeChild(unmuteMask);
        unmuteMask = null;
    }

    function removeEvents() {
        _this.off(EVENT.MUTE, handleUnmute);
        if (unmuteMask == null) {
            return;
        }
        unmuteMask.removeEventListener("click", unmuteClick, true);
    }

    return {
        create() {
            if (!_this.defOpt.displayClickToUnMute || !_this.vjsPlayer.muted()) {
                return;
            }
            unmuteMask = document.createElement("DIV");
            unmuteMask.classList.add("unmute");
            unmuteMask.innerText = "開啟音效";
            _this.appendChild(unmuteMask);
            unmuteMask.addEventListener("click", unmuteClick, true);
            _this.on(EVENT.MUTE, handleUnmute);
        },

        remove() {
            removeEvents();
            removeUnmuteMask();
        },
    };
}

export default function(videojs) {
    const Component = videojs.getComponent("Component");

    class AdControlBar extends Component {
        constructor(player, options = {}) {
            super(player, options);
        }
        createEl() {
            return videojs.dom.createEl("div", { className: "vjs-ad-control-bar" });
        }
    }

    Component.registerComponent("AdControlBar", AdControlBar);
};
import { CONTENT_TYPE_CHANNEL, CONTENT_TYPE_PLAYOUT, CONTENT_TYPE_VOD_CHANNEL } from "./constant";
import { toMilliseconds } from "./utils";

export const onAirSrcParserMap = {
    [CONTENT_TYPE_CHANNEL]: channelProgram,
    [CONTENT_TYPE_VOD_CHANNEL]: vodChannelOnAirProgram,
    [CONTENT_TYPE_PLAYOUT]: playoutChannelOnAirProgram,
};

export function vodSrcBase(channel, program) {
    const { assetId, timeCodes, timecodeDuration, contentType } = program;
    return {
        assetId: channel.cdnCode + "#" + assetId,
        startTime: 0,
        mediaMode: "simulation_live",
        mediaType: "vod-channel",
        contentType,
        midrollTimeCodes: timeCodes?.map(toMilliseconds),
        midrollTimecodeDuration: timecodeDuration?.map(toMilliseconds),
    };
}

function channelProgram(channel) {
    return {
        assetId: channel.cdnCode,
        mediaMode: "live",
        mediaType: "channel",
        contentType: "channel",
    };
}

function vodChannelOnAirProgram(channel, timestamp) {
    const program = channel.schedule[channel.onAir];
    const srcInfo = vodSrcBase(channel, program);
    srcInfo.startTime = toMilliseconds((timestamp - channel.startTime - program.startTime) % channel.totalLength);
    return srcInfo;
}

function playoutChannelOnAirProgram(channel, timestamp) {
    const program = channel.schedule[channel.onAir];
    const srcInfo = vodSrcBase(channel, program);
    const { scene, progressMark, curAdRemain } = computePlayoutStatus(program, timestamp);
    srcInfo.startTime = toMilliseconds(progressMark);
    srcInfo.midrollBeforeStart = scene != "content";
    srcInfo.midrollBeforeStartDuration = toMilliseconds(curAdRemain);
    return srcInfo;
}

function computePlayoutStatus(program, timestamp) {
    const { timeCodes, timecodeDuration, length, startTime, endTime } = program;
    const programDuration = endTime - startTime;
    const contentDuration = length;
    const elapsedTime = (timestamp - startTime) | 0;

    let scene = "content";
    let progressMark, curAdRemain;

    for (let midrollSpendTime = 0, i = 0, ln = timeCodes.length; i < ln; i++) {
        progressMark = elapsedTime - midrollSpendTime;
        let midrollStart = timeCodes[i];
        if (progressMark < midrollStart) {
            break;
        }
        let midrollDuration = timecodeDuration[i] || 0;
        let midrollEnd = midrollStart + midrollDuration;
        if (progressMark < midrollEnd) {
            scene = "midroll";
            curAdRemain = midrollEnd - progressMark;
            progressMark = midrollStart;
            break;
        }
        midrollSpendTime += midrollDuration;
    }

    if (contentDuration <= progressMark) {
        scene = "postroll";
        curAdRemain = programDuration - elapsedTime;
        progressMark = contentDuration;
    }

    return { scene, progressMark, curAdRemain };
}

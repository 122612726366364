import EVENT from "../Events.js";
import { conditionClass } from "../Util";

export default function upperLogoMovementHandle(ctx) {
    const upperToolBarContainer = ctx.vjsPlayer.upperToolBar.el();

    ctx.on(EVENT.IMPRESSION, setUpperLogoMovementFlag);
    ctx.on(EVENT.FULLSCREEN, setUpperLogoMovementFlag);
    ctx.on(EVENT.FULLSCREEN_EXIT, setUpperLogoMovementFlag);
    ctx.on(EVENT.FULLWINDOW, setUpperLogoMovementFlag);
    ctx.on(EVENT.FULLWINDOW_EXIT, setUpperLogoMovementFlag);

    function setUpperLogoMovementFlag() {
        const upperToolBarLastElement = getLastVisibleElement(upperToolBarContainer);
        conditionClass(
            ctx.container,
            "shift-upper-logo",
            upperToolBarLastElement && !upperToolBarLastElement.classList.contains("vjs-space")
        );
    }
    function getLastVisibleElement(element) {
        return Array.from(element.children)
            .reverse()
            .find(child => window.getComputedStyle(child).display != "none");
    }
}

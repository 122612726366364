import { noop } from "../Util/Util.js";
import ErrorType from "../Util/ErrorType.js";
import Event from "./Event.js";

const errorVideo = "https://player.svc.litv.tv/assets/slate06-20s.mp4";
export default function ContentManager($content, config, meta, player, eventCenter, tracing) {
    const defaultSrcObj = {
        src: errorVideo,
        mediaMode: "simulation_live",
        puid: config.puid,
        enableCountdown: true,
        playAds: true,
        autoPlay: config.autoPlay,
        hiddenCheck: true,
        companionAdSize: [{ width: 300, height: 250 }],
        keepPlayingAd: true,
    };
    const normalMode = {
        playNext: () => play(),
        onImpression: onNormalModeImpression,
        onError: onNormalModeError,
    };
    const errorMode = {
        playNext: refreshChannel,
        onImpression: noop,
        onError: noop,
    };
    const midrollCountDownHandler = MidrollCountDownHandler(player);
    const liveChannelRefreshChecker = LiveChannelRefreshChecker(player.vjsPlayer, refreshChannel);

    let currentMode = normalMode;

    player.mute(true);
    player.on(player.EVENT.IMPRESSION, () => currentMode.onImpression());
    player.on(player.EVENT.ENDED, () => currentMode.playNext());
    player.on(player.EVENT.ERROR, (event) => currentMode.onError(event));
    player.vjsPlayer.on("ended", () => {
        // 2024/10/24:
        // live streaming 有出現 EXT-X-ENDLIST 的狀況, 目前先當成錯誤回報
        // ENDED 和 FINISHED EVENT 會被繞掉, 所以只能接 vjsPlayer
        if (currentMode == normalMode && meta.channel.isLive) {
            tracing.monkey.send(ErrorType.PLAYER_ERROR, { message: "live_streaming_ends" });
        }
    });

    eventCenter.on(Event.PLAY, play);
    eventCenter.on(Event.ProgramChange, ({ channelIndex }) => {
        if (channelIndex == meta.channelIndex) {
            player.vjsPlayer.upperToolBar.caption.update(meta.caption);
        }
    });

    function play(contentInfo) {
        resetErrorMode();
        midrollCountDownHandler.stop();
        liveChannelRefreshChecker.stop();
        meta.next(contentInfo)
            .then((programSrc) => {
                if (programSrc.mediaMode == "live") {
                    liveChannelRefreshChecker.start();
                }
                setProgramSrc(programSrc);
            })
            .catch((error) => {
                if (error?.type == ErrorType.CDI_ERROR) {
                    tracing.monkey.send(error.type, error);
                }
                setErrorMode();
            });
    }

    function refreshChannel() {
        play({ channelIndex: meta.channelIndex });
    }

    function setProgramSrc(programSrc) {
        player.setSrc({
            ...defaultSrcObj,
            ...programSrc,
            liadMeta: meta.liad,
            caption: meta.caption,
        });
    }

    function onNormalModeImpression() {
        if (meta.midrollInterval > 0) {
            midrollCountDownHandler.start(meta.midrollInterval);
        }
    }

    function onNormalModeError(event) {
        setErrorMode();
        tracing.monkey.send(ErrorType.PLAYER_ERROR, { code: event.error.code });
    }

    function resetErrorMode() {
        $content.classList.remove("ppn-error_mode");
        currentMode = normalMode;
    }

    function setErrorMode(error) {
        $content.classList.add("ppn-error_mode");
        currentMode = errorMode;
        setProgramSrc();
    }
}

function MidrollCountDownHandler(player) {
    let midrollInterval = null;
    let countDownInterval = null;

    const countDownStop = () => {
        if (countDownInterval) {
            clearInterval(countDownInterval);
            countDownInterval = null;
        }
    };

    const countDownStart = () => {
        countDownStop();
        countDownInterval = setInterval(() => player.requestMidroll(), midrollInterval);
    };

    return {
        start: (newMidrollInterval) => {
            midrollInterval = newMidrollInterval;
            countDownStart();
            player.on(player.EVENT.PAUSE_FOR_AD, countDownStop);
            player.on(player.EVENT.RESUM_FOR_AD, countDownStart);
        },
        stop: () => {
            countDownStop();
            midrollInterval = null;
            player.off(player.EVENT.PAUSE_FOR_AD, countDownStop);
            player.off(player.EVENT.RESUM_FOR_AD, countDownStart);
        },
    };
}

function LiveChannelRefreshChecker(vjsPlayer, refresh) {
    // 2024/10/24: 目前 videojs 7.20.3 (vhs v2.14.3)
    // 1. 有出現的狀況 m3u8 太久的狀況, 持續載 m3u8 但沒有載 ts, 或是 持續載 ts 但是沒有恢復播放
    // 調整成由 player 處理
    // 2. 由於 live channel m3u8 一小時會過期, player 的 m3u8 request 會失敗, 但是沒有丟出錯誤
    // 調整成播放時間超過 56 分鐘就自動 refresh

    const REFRESH_TIME_IN_MILLISECONDS = 3360e3; // 56 minutes

    let streamStart = NaN;

    function onTimeupdate() {
        if (Date.now() - streamStart > REFRESH_TIME_IN_MILLISECONDS) {
            refresh();
        }
    }
    return {
        start() {
            streamStart = Date.now();
            vjsPlayer.on("timeupdate", onTimeupdate);
        },
        stop() {
            streamStart = NaN;
            vjsPlayer.off("timeupdate", onTimeupdate);
        },
    };
}

import ObserverPattern from './ObserverPattern.js';
import {establishUid} from './Util.js';

let template = {
    data: "",
    uid: null,
    position: "MC",
    is_logo: false,
    is_linear: true,
    click_through: "",
    duration: 0,
    enable_countdown: false
};


export default class AssignImage extends ObserverPattern{
    constructor(imageAd){
        super();
        this.imageAd = imageAd;
        this.uids = [];
        this.removeAll = this.removeAll.bind(this);
    }
    
    assign(opt, disableControl = true){
        if(typeof opt === "string"){
            opt = {data: opt};
        }
        
        if(typeof opt.disableControl !== "boolen"){
            opt.disableControl = disableControl;
        }
        
        let uid = opt.uid || establishUid();
        opt.uid = uid;
        this.uids.push(uid);
        
        let data = Object.assign({}, template, opt);
        
        let channel = this.imageAd.adsRequest(data)
        channel
        .onPauseRequested(e => {
            super.trigger("PauseRequested", data);
        })
        .onAdMediaComplete(e => {
            super.trigger("AdMediaComplete", data);
        });
        
        channel.close = function(){
            this.imageAd.remove(uid);
            this.uids = this.uids.filter(i => uid !== i);
            channel.destroy();
            channel = null;
        }.bind(this);
        
        return channel;
    }
    
    removeAll(){
        this.uids.forEach(i => {
            this.imageAd.remove(i);
        });
        this.uids = [];
        //TODO 想辦法 destroy channel
    }
    
    on(){
        super.on.apply(this, arguments);
        return this;
    }
    
    one(){
        super.one.apply(this, arguments);
        return this;
    }
    
    off(){
        super.off.apply(this, arguments);
        return this;
    }
}
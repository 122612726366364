export function loadIMA() {
    const ImaSdkUrl = "https://imasdk.googleapis.com/js/sdkloader/ima3.js";
    return loadScriptOnCondition(ImaSdkUrl, () => !google.ima.VERSION);
}

export function loadVideoJs() {
    const PPN_VJS_VERSION = "7.20.3";
    const VideoJsUrl = `https://jsmodule.svc.litv.tv/videojs/${PPN_VJS_VERSION}/video.novtt.min.js`;
    return loadScriptOnCondition(VideoJsUrl, () => videojs.VERSION != PPN_VJS_VERSION);
}

export function loadPPNCustomScript(sponsor) {
    const customScriptUrl = `https://player.svc.litv.tv/media_custom/${atob(sponsor.split("|")[0])}.js`;
    return loadScriptOnCondition(customScriptUrl, () => !document.querySelector(`script[src=${customScriptUrl}]`));
}

export async function loadScriptOnCondition(src, condition) {
    try {
        if (condition()) {
            throw "";
        }
    } catch (error) {
        await loadScript(src);
    }
}

export function loadScript(src) {
    return new Promise((resolve, reject) => {
        const srcElt = document.head.appendChild(document.createElement("script"));
        srcElt.onload = resolve;
        srcElt.onerror = () => {
            document.head.removeChild(srcElt);
            reject();
        };
        srcElt.src = src;
    });
}

import { acsUrl } from "./Urls";
import { insertYahooAdLog } from "../Api/CdiApi";

export default function litvTracing(config, player) {
    let against = config.sponsorInfo == "cG50" || config.sponsorInfo == "Y3Qy";
    let browserInfo = player.defOpt.appInfo.split("|");
    browserInfo = browserInfo[browserInfo.length - 1];
    let platform =
        (function (a) {
            if (/ANDROID|IPHONE|IPAD|IPOD/i.test(a)) {
                return "m-web";
            } else return "web";
        })(navigator.userAgent) +
        "|" +
        browserInfo;

    function heavyAdIntervention(inAd) {
        let formData = new FormData();
        formData.append("partner", config.partnerName);
        formData.append("puid", config.puid);
        formData.append("in_ad", inAd ? "1" : "0");
        formData.append("time", typeof performance !== "undefined" ? (performance.now() | 0).toString() : "");
        navigator.sendBeacon(acsUrl() + "/rpc.php", formData);
    }

    // function beforeunload(inAd){
    //     if(!against) return;
    //     let formData = new FormData();
    //     formData.append('partner', "beforeunload");
    //     formData.append('puid', config.puid);
    //     formData.append('in_ad', inAd ? "1" : "0");
    //     formData.append('time', typeof(performance) !== "undefined" ? (performance.now() | 0).toString() : "");
    //     navigator.sendBeacon(acsUrl() + "/rpc.php", formData);
    // }

    function pageInit() {
        if (against) insertYahooAdLog("request", "ams00112323", platform);
    }

    function pageImpression() {
        if (against) insertYahooAdLog("impression", "ams00112323", platform);
    }

    return {
        heavyAdIntervention,
        pageInit,
        pageImpression,
    };
}

export default class LiX{
    //TODO 重構成真的 FP
    constructor(list){
        this.list = (list || []).concat();
        this.breaked = null;
        this.aborted = null;
        this.stoped = false;
        this.qu = [];
        this.unsubscribeList = [];
    }
    add(fn){
        this.list.push(fn);
        return this;
    }
    do(fn, every = true){
        let _do = function(fn){
            return function (input){
                return new Promise((resolve) => {
                    fn(input);
                    resolve(input);
                });
            };
        }(fn);
        let pv = this.qu[this.qu.length - 1];
        if(pv && pv.type == "multiple" && every){
            pv.do = pv.do || [];
            pv.do.push(_do);
        }else{
            this.qu.push({fn: _do, type: "general"});
        }
        return this;
    }
    transform(fn){
        this.qu.push({fn: function(fn){
            return function (input){
                return Promise.resolve(fn(input));
            }
        }(fn),
        type: "general"});
        return this;
    }
    map(fn){
        this.qu.push({fn: function(fn){
            return function (input){
                return Promise.resolve(input.map(fn));
            }
        }(fn),
        type: "general"});
        return this;
    }
    filter(fn){
        this.qu.push({fn: function(fn){
            return function (input){
                return Promise.resolve(input.filter(fn));
            }
        }(fn),
        type: "general"});
        return this;
    }
    takeUntilFromFilter(fn, reasonDescription){
        return this.filter(fn).takeWhile(x => x.length > 0, reasonDescription);
    }
    inCase(determination, meets, avoid){
        this.qu.push({fn: function(determination, meets, avoid){
            return function(input){
                return Promise.resolve(determination(input)? meets(input): avoid(input));
            }
        }(determination, meets, avoid),
        type: "general"});
        return this;
    }
    count(fn){
        this.qu.push({fn: function(fn){
            return function (input){
                return Promise.resolve([input.filter(fn).length, input]);
            }
        }(fn), type: "general"});
        return this;
    }
    takeWhile(fn, reasonDescription){
        let pv = this.qu[this.qu.length - 1];
        if(pv && pv.type == "multiple"){
            pv.takeWhile = pv.takeWhile || [];
            pv.takeWhile.push((input, lastValue) => {
                if(fn(input)){
                    return true;
                }else{
                    return false;
                }
            });
        }else{
            this.qu.push({fn: function(fn, cxt){
                return function(input, lastValue){
                    return new Promise((resolve) => {
                        if(fn(input)){
                            resolve(input);
                        }else{
                            cxt._stop(typeof lastValue !== "undefined" ? lastValue : input, reasonDescription);
                        }
                    });
                };
            }(fn, this), type: "general"});
        }
        return this;
    }
    takeUntilFromEventPattern(subscribeFn, unsubscribeFn){
        let h = () => {
            this._stop(null, null, true);
            unsubscribeFn(h);
        };
        this.qu.push({fn:input => {
            subscribeFn(h);
            this.unsubscribeList.push(() => unsubscribeFn(h));
            return Promise.resolve(input);
        }, type: "general"});
        return this;
    }
    concat(){
        this.qu.push({
            fn: function(cxt){
                return function(input){
                    return new Promise((resolve, reject) => {
                        let _this = this, result = [];
                        let list = input.concat();
                        if(list.length == 0){
                            resolve(result);
                            return;
                        }
                        list.push(() => Promise.resolve());
                        let fist = list.shift();
                        let stopedByTakeWhile = false;
                        list.reduce((p, c) => {
                            return p.then(r => {
                                if(!cxt.stoped && !stopedByTakeWhile){
                                    result.push(r);
                                    if(_this.takeWhile.every(item => item(r, result))){
                                        _this.do.forEach(item => item(r));
                                        return c(r);
                                    }else{
                                        stopedByTakeWhile = true;
                                        return Promise.resolve();
                                    }
                                }else{
                                    return Promise.resolve();
                                }
                            });
                        }, fist())
                        .then(_ => {
                            resolve(result);
                        }, _ => reject(_));
                    });
                };
            }(this),
            type: "multiple", 
            do: [], 
            takeWhile: []
        });
        return this;
    }
    merge(){
        this.qu.push({
            fn: function(cxt){
                return function(input){
                    return new Promise((resolve, reject) => {
                        let _this = this, result = [];
                        let list = input, tl = list.length;
                        if(tl == 0){
                            resolve(result);
                            return;
                        }
                        let stopedByTakeWhile = false;
                        list.forEach((fn, i) => {
                            fn().then(r => {
                                if(cxt.stoped || stopedByTakeWhile) return;
                                result[i] = r;
                                if(_this.takeWhile.every(item => item(r, result))){
                                    _this.do.forEach(item => item(r));
                                    if(result.filter(x => true).length === tl){
                                        resolve(result);
                                    }
                                }else{
                                    stopedByTakeWhile = true;
                                    resolve(result);
                                }
                            }, _ => reject(_));
                        });
                    });
                };
            }(this),
            type: "multiple", 
            do: [], 
            takeWhile: []
        });
        return this;
    }
    _stop(lastValue, otherValue, isAbort){
        this.stoped = true;
        if(isAbort == true){
            this.aborted && this.aborted();
        }else{
            this.breaked && this.breaked(lastValue, otherValue);
        }
    }
    subscribe(complete, error){
        this.stoped = false;
        let cxt = this;
        let qu = this.qu;
        (new Promise((resolve, reject) => {
            cxt.breaked = (lastValue, otherValue) => resolve([lastValue,otherValue]);
            cxt.aborted = () => reject("abort"); 
            qu.map(task => task.fn.bind(task))
            .reduce((p, c) => {
                return p.then(r => {
                    if(cxt.stoped){
                        return Promise.resolve();
                    }
                    return c(r);
                });
            }, Promise.resolve(this.list))
            .then((r) => {
                if(!cxt.stoped){
                    resolve([r]);
                }else{
                    reject("abort");
                }
            }, e => {
                if(e !== "abort") reject(e);
            })
        })).then(([r, r2]) => {
            this.qu = [];
            this.unsubscribeList.forEach(fn => fn());
            this.unsubscribeList = [];
            complete && complete(r, r2);
        }).catch((r) => {
            this.qu = [];
            this.unsubscribeList.forEach(fn => fn());
            this.unsubscribeList = [];
            error && error(r);
        });
        
        return () => {
            this._stop(null, null, true);
        }
    }
    
    static of(list){
        return new LiX(list);
    }
}
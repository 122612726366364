const GPT_DEFAULT_SIZE = {width: 300, height:250};

export default function GptHosting(config, $container){
    let tags = config.gptTag || [];
    if(tags.length < 1) return;
    setUpGpt();

    let $gptHosting = document.createElement("DIV");
    $gptHosting.className = "ppn-gpt_hosting";
    $container.appendChild($gptHosting);

    // let checkSize = startCheckSize($gptHosting, config);

    // googletag.cmd.push(() => {
    //     googletag.pubads().addEventListener('slotRenderEnded', function(event){
    //         checkSize();
    //     });
    // });

    tags.forEach(tag => {
        let uid = establishUid();
        let el = document.createElement("DIV");
        el.id = uid;
        $gptHosting.appendChild(el);
        let tagInfo = parseTag(tag);
        defineAndEnable(tagInfo.tag, tagInfo.size, uid);
    });
}

function setUpGpt(){
    let $gptJs = document.querySelector("script[data-gptjs]");
    if(!$gptJs && !(window.googletag && window.googletag.cmd)){
        $gptJs = document.createElement("script");
        $gptJs.dataset.gptjs = 1;
        $gptJs.async = true;
        $gptJs.src = "https://www.googletagservices.com/tag/js/gpt.js";
        document.head.appendChild($gptJs);
    }

    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];

    googletag.cmd.push(() => {
        //googletag.openConsole();
        googletag.pubads().collapseEmptyDivs(true);
        googletag.pubads().setForceSafeFrame(true);
        googletag.enableServices();
    });
}
function defineAndEnable(tag, size, uid){
    googletag.cmd.push(function(){
        googletag.defineSlot(tag, size, uid)
        .setCollapseEmptyDiv(true, true)
        .addService(googletag.pubads());
        googletag.display(uid);
    });
}

function parseTag(tag){
    let tagInfo = tag.trim().split("|");
    return {
        tag: tagInfo[0].trim(),
        size: JSON.parse((tagInfo[1] || "0").trim()) || [GPT_DEFAULT_SIZE.width, GPT_DEFAULT_SIZE.height]
    };
}

function establishUid(){
    return Math.random().toString(32).substring(2);
}

// function startCheckSize($el, config){
//     let preHeight = 0;

//     window.addEventListener("resize", e => {
//         check(getHeight());
//     });

//     function getHeight(){
//         return Math.floor($el.getBoundingClientRect().height);
//     }

//     function postHeight(height){
//         window.top.postMessage(`ppnplayer://change_size?h=${height}&pid=${config.ppnId}`, "*"); //TODO 方法改了，應該不需要 post message
//     }

//     function check(height){
//         if(preHeight == height) return;
//         preHeight = height;
//         postHeight(height);
//     }

//     return function(){
//         check(getHeight());
//     };
// }
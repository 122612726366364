import EVENT from './Events.js';

let logAdProgress = (e) => {
    console.log("%c" + e.state, "color: #4B8A08", e.getCarryInfo());
};

let logAdReport = (e) => {
    console.log("%cAdReport", "color: #FF8000", e.getCarryInfo());
};

let logError = (e) => {
    let carryInfo = e.getCarryInfo();
    let code = findKeyValue(carryInfo, "code");
    let print = ["%c" + e.type.replace(/(?:^|\_)(\w)/g, ($0, $1) => $1.toUpperCase()), "color: #FE2E2E", carryInfo];
    if(code){
        print.splice(2, 0, code);
    }
    console.log.apply(null, print);
};

let findKeyValue = (obj, key) => {
    if(obj === null || typeof obj !== 'object'){
        return null;
    }
    for(let k in obj){
        if(!obj.hasOwnProperty(k)) continue;
        if(k == key) return obj[k];
        let v = findKeyValue(obj[k], key);
        if(v !== null) return v;
    }
    return null;
};

export default function(Player){
    Player.prototype.traced = {
        adProgress: false,
        adReport: false,
        error: false
    };
    
    Object.defineProperty(Player.prototype, 'trace', {
        get () {
            let _this = this;
            return {
                all(){
                    return this.adProgress().adReport().error();
                },
                adProgress(){
                    if(_this.traced.adProgress == true) return this;
                    _this.traced.adProgress = true;
                    _this.on(EVENT.AD_PROGRESS, logAdProgress);
                    return this;
                },
                adReport(){
                    if(_this.traced.adReport == true) return this;
                    _this.traced.adReport = true;
                    _this.on(EVENT.AD_REPORT, logAdReport);
                    return this;
                },
                error(){
                    if(_this.traced.error == true) return this;
                    _this.traced.error = true;
                    _this.on(EVENT.AD_ERROR, logError);
                    _this.on(EVENT.ERROR, logError);
                    return this;
                }
            };
        }
    });
    
    Object.defineProperty(Player.prototype, 'untrace', {
        get () {
            let _this = this;
            return {
                all(){
                    return this.adProgress().adReport().error();
                },
                adProgress(){
                    if(_this.traced.adProgress == false) return this;
                    _this.traced.adProgress = false;
                    _this.off(EVENT.AD_PROGRESS, logAdProgress);
                    return this;
                },
                adReport(){
                    if(_this.traced.adReport == false) return this;
                    _this.traced.adReport = false;
                    _this.off(EVENT.AD_REPORT, logAdReport);
                    return this;
                },
                error(){
                    if(_this.traced.error == false) return this;
                    _this.traced.error = false;
                    _this.off(EVENT.AD_ERROR, logError);
                    _this.off(EVENT.ERROR, logError);
                    return this;
                }
            };
        }
    });
}
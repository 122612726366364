import { LiAdError, LiAdErrorCodes } from "./CustomError";

const FACADE_MAP = new Map();

function defineSlot(tag, size, uid) {
    return new Promise(resolve => {
        googletag.cmd.push(() => {
            resolve(googletag.defineSlot(tag, size, uid).setCollapseEmptyDiv(false).addService(googletag.pubads()));
        });
    });
}

export class GptSlotFacade {
    constructor(el, tag, size) {
        this._el = el;
        this._tag = tag;
        this._size = size;
        this.refresh = this._refreshInit;
    }
    static removeSlots(slots) {
        slots.forEach(slot => {
            if (FACADE_MAP.has(slot)) {
                FACADE_MAP.get(slot).clearTimeout();
                FACADE_MAP.delete(slot);
            }
        });
        googletag.pubads().clear(slots);
        googletag.destroySlots(slots);
    }
    addClass(className) {
        this._el.classList.add(className);
    }
    removeClass(className) {
        this._el.classList.remove(className);
    }
    async _createSlot() {
        const { _el, _tag, _size } = this;
        const slot = await defineSlot(_tag, _size, _el.id);
        this.slot = slot;
        FACADE_MAP.set(slot, this);
        this.onSlotCreated(slot);
        return slot;
    }
    async _refreshInit() {
        const slot = await this._createSlot();
        googletag.display(slot);
        if (googletag.pubads().isInitialLoadDisabled()) {
            this._refreshCommon();
        }
        this.refresh = this._refreshCommon;
    }
    _refreshCommon() {
        googletag.pubads().refresh([this.slot]);
    }
    requested() {
        this.tId = setTimeout(() => this.onError(new LiAdError(LiAdErrorCodes.codes.GPT_RENDER_TIMEOUT)), 2e3);
    }
    clearTimeout() {
        if (this.tId) {
            clearTimeout(this.tId);
            this.tId = null;
        }
    }
    renderEnded(isEmpty) {
        this.clearTimeout();
        if (isEmpty) {
            return this.onError(new LiAdError(LiAdErrorCodes.codes.GPT_IS_EMPTY));
        }
        this._el.removeAttribute("style");
        this.onRendered(this);
    }
    onSlotCreated() { }
    onRendered() { }
    onError() { }
}

export const setUpGpt = (function () {
    let executed = false;
    return function () {
        if (executed) {
            return;
        }
        executed = true;

        //判斷有無 GPT googletag
        if (!(window.googletag && window.googletag._loaded_) && !document.querySelector("script[data-gptjs]")) {
            let $gptJs = document.createElement("script");
            $gptJs.dataset.gptjs = 1;
            $gptJs.async = true;
            $gptJs.src = "https://www.googletagservices.com/tag/js/gpt.js";
            document.head.appendChild($gptJs);
        }

        window.googletag = window.googletag || {};
        window.googletag.cmd = window.googletag.cmd || [];

        googletag.cmd.push(() => {
            //googletag.openConsole();
            googletag.pubads().setForceSafeFrame(true);
            googletag.pubads().addEventListener("slotRequested", function (event) {
                const slot = event.slot;
                FACADE_MAP.has(slot) && FACADE_MAP.get(slot).requested();
            });
            googletag.pubads().addEventListener("slotRenderEnded", function emitRenderEnded(event) {
                const { slot, isEmpty } = event;
                FACADE_MAP.has(slot) && FACADE_MAP.get(slot).renderEnded(isEmpty);
            });
            // googletag.pubads().addEventListener("impressionViewable", function (event) {});
            // googletag.pubads().addEventListener("slotOnload", function (event) {});
            // googletag.pubads().addEventListener("slotVisibilityChanged", function (event) {});
            googletag.enableServices();
        });
    };
})();

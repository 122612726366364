import { cdiUrl, yonderCdiUrl } from "../Util/Urls";
import { fetchRpc, rpcResponseCheck } from "../Util/requestHelper";

const GET_URLS_NO_AUTH = "LoadService.GetURLsNoAuth";
const IN_ALLOWED_REGION = "LoadService.InAllowedRegion";
const INSERT_YAHOO_AD_LOG = "YahooService.InsertYahooAdLog";

export function GetUrlsNoAuth({ externalPlayer, refPartnerName, getUrls, puid }) {
    let baseUrl;
    let site;
    if (getUrls == "cdi") {
        baseUrl = cdiUrl;
        site = "ep";
    } else {
        baseUrl = yonderCdiUrl;
        site = "pc";
    }

    return async function (assetId, mediaType) {
        let params = {
            AssetId: assetId,
            DeviceType: externalPlayer,
            MediaType: mediaType,
            ProjectID: refPartnerName,
            Site: site,
            puid: puid,
        };
        let request = fetchRpc(baseUrl(), GET_URLS_NO_AUTH, params);
        let result = await rpcResponseCheck(request, "Failed to fetch Content url");
        return result.AssetURLs[0];
    };
}

export async function inAllowedRegion() {
    let request = fetchRpc(cdiUrl(), IN_ALLOWED_REGION, {});
    return await rpcResponseCheck(request, "Failed to fetch inAllowedRegion").catch(() => true);
}

export function insertYahooAdLog(cdata, clip, platform) {
    let params = {
        cdata: cdata,
        ad_clip: clip,
        ad_id: "",
        asset_id: "",
        platform: platform,
    };
    fetchRpc(cdiUrl(), INSERT_YAHOO_AD_LOG, params);
}

import EVENT from "./Events.js";
import qualityLevels from "./qualityLevel/plugin";
import registerQualityButton from "./UiComponent/QualityButton";
import registerAdControlBar from "./UiComponent/AdControlBar";
import registerAdMuteToggle from "./UiComponent/AdMuteToggle";
import registerAdPlayToggle from "./UiComponent/AdPlayToggle";
import registerBrand from "./UiComponent/Brand";
import registerFavoriteButton from "./UiComponent/FavoriteButton";
import registerCaption from "./UiComponent/Caption";
import registerSeekButton from "./UiComponent/SeekButton";
import registerSkipThemeButton from "./UiComponent/SkipThemeButton";
import registerUpperToolBar from "./UiComponent/UpperToolBar";
import registerToast from "./UiComponent/Toast";
import { clamp, conditionClass } from "./Util.js";

export function customizeVjs(container, ctx, options, triggerEvent) {
    const isMobile = videojs.browser.IS_IOS || videojs.browser.IS_ANDROID;

    if (!videojs.getPlugin("liCustom")) {
        videojs.registerPlugin("liCustom", () => {});
        videojs.registerPlugin("qualityLevels", qualityLevels);
        registerQualityButton(videojs);
        registerAdControlBar(videojs);
        registerAdMuteToggle(videojs);
        registerAdPlayToggle(videojs);
        registerBrand(videojs);
        registerCaption(videojs);
        registerFavoriteButton(videojs);
        registerUpperToolBar(videojs);
        registerSeekButton(videojs);
        registerSkipThemeButton(videojs);
        registerToast(videojs);
        customizeMouseTimeDisplay(videojs);
        customizePlaybackRateMenuButton(videojs);
        changeFullScreenAction(videojs, options);
        changeSeekBarAction(videojs);
        customizeMenuButton(videojs, isMobile);
        changeVolumePanelAction(videojs);
    }

    container.classList.add("litv-player");
    container.classList.add(isMobile ? "is-mobile" : "is-desktop");
    if (ctx.platformInfo.isIPad) {
        container.classList.add("is-ipad");
    }

    let vjsElt = container.appendChild(document.createElement("video"));
    vjsElt.className = "video-js";
    vjsElt.controls = true;

    let vjsPlayer = videojs(vjsElt, {
        disablePictureInPicture: true,
        autoplay: false,
        playsinline: true,
        noUITitleAttributes: true,
        aspectRatio: "16:9",
        children: genPlayerChildren(),
        playbackRates: [2, 1.5, 1.25, 1, 0.75, 0.5],
        responsive: true,
        breakpoints: {
            tiny: -Infinity,
            xsmall: -Infinity,
            small: 480,
            medium: 600,
            large: 630,
            xlarge: Infinity,
            huge: Infinity,
        },
    });

    vjsPlayer.handleTechTap_ = onTap;
    vjsPlayer.handleTechTouchStart_ = onTouchStart;
    const controlBar = vjsPlayer.controlBar;
    const { playToggle, playbackRateMenuButton, qualityButton, VolumePanel, progressControl, fullscreenToggle } =
        controlBar;
    const seekBar = progressControl.seekBar;
    const volumeControl = VolumePanel.volumeControl;
    const volumeBar = volumeControl.volumeBar;

    seekBar.checkSeekComplete = seekBar.checkSeekComplete.bind(seekBar); // todo: move to seekBar's constructor
    if (!isMobile) {
        seekBar.playProgressBar.timeTooltip.hide();
        volumeBar.mouseVolumeLevelDisplay.hide();
        volumeControl.el_.appendChild(genPopupPad(videojs, () => VolumePanel.removeClass("vjs-hover")));
    }

    const vjsContainer = vjsPlayer.el_;
    releaseSliderOnMouseLeave(vjsContainer, progressControl, seekBar);
    releaseSliderOnMouseLeave(vjsContainer, volumeControl, volumeBar);
    unpressMenuButtonOnOutsideTouch(vjsContainer, playbackRateMenuButton);
    unpressMenuButtonOnOutsideTouch(vjsContainer, qualityButton);
    vjsContainer.oncontextmenu = e => e.preventDefault();
    vjsPlayer.playWrapper = () =>
        document.body.contains(container) ? vjsPlayer.play() : Promise.reject("player is removed from doc body.");
    vjsPlayer.on("durationchange", changeTimeFormat(videojs, vjsPlayer));
    playToggle.on("click", () => vjsPlayer.toast.icon(vjsPlayer.paused() ? "pause" : "play"));

    // video.js execute listenForUserActivity_ after player event, it causes userActivity sensor is not working in Prerolls .
    // execute it manually instead as workaround.
    vjsPlayer.off("play", vjsPlayer.listenForUserActivity_);
    vjsPlayer.listenForUserActivity_();

    // for live channel full screen by db click
    container
        .querySelector(".vjs-player-mask")
        .addEventListener("dblclick", event => fullscreenToggle.handleClick(event));

    return vjsPlayer;

    function genPlayerChildren() {
        return [
            "mediaLoader",
            { name: "component", className: "vjs-player-mask" },
            { name: "component", id: "nonLinearImage", className: "nonlinear_image_container" },
            { name: "component", id: "logo", className: "logo_container" },
            { name: "controlBar", children: genControlBarChildren() },
            { name: "upperToolBar", children: getUpperToolBar() },
            { name: "adControlBar", children: getAdControlChildren() },
            { name: "component", el: genWaitingAnimEl() },
            { name: "component", id: "linearImage", className: "linear_image_container linear_ad" },
            { name: "component", id: "houseVideoAd", className: "house_ad_container" },
            { name: "component", id: "ima", className: "ima_container" },
            "toast",
            "errorDisplay",
            "resizeManager",
        ];
    }

    function getUpperToolBar() {
        return [
            // genEventButton("vjs-back-button", EVENT.REQUEST_BACK),
            { name: "caption", clickHandler: () => triggerEvent(EVENT.CLICK_CAPTION) },
            { name: "component", className: "vjs-space" },
            { name: "favoriteButton", tx: triggerEvent },
            genEventButton("vjs-return-button", EVENT.REQUEST_PREVIOUS_MEDIA),
            genEventButton("vjs-controller-button", EVENT.REQUEST_CONTROLLER),
            genScheduleButton(),
            genEventButton("vjs-info-button", EVENT.REQUEST_INFO),
        ];
    }

    function getAdControlChildren() {
        const result = options.allowAdPause ? [{ name: "adPlayToggle", ctx }] : [];
        result.push({ name: "adMuteToggle", ctx });
        return result;
    }

    function genControlBarChildren() {
        const { disableFullscreen } = options;
        const result = [
            {
                name: "progressControl",
                seekBar: { children: ["playProgressBar", "mouseTimeDisplay"] },
            },
            { name: "component", className: "vjs-live-icon" },
            "playToggle",
            "backwardButton",
            "forwardButton",
            genEventButton("vjs-next-button", EVENT.NEXT_EPISODE),
            "currentTimeDisplay",
            "timeDivider",
            "durationDisplay",
            { name: "component", className: "vjs-space" },
            "skipThemeButton",
            "playbackRateMenuButton",
            "qualityButton",
            { name: "VolumePanel", inline: false },
        ];

        return result.concat(disableFullscreen ? [] : "fullscreenToggle");
    }
    function genScheduleButton() {
        return {
            name: "button",
            className: "vjs-schedule-button",
            clickHandler: () => triggerEvent(EVENT.REQUEST_SCHEDULE, { isLiveMode: ctx.vjsInfo.isLiveMode }),
        };
    }
    function genEventButton(className, eventType) {
        return { name: "button", className, clickHandler: () => triggerEvent(eventType) };
    }
    function genWaitingAnimEl() {
        let waiting = document.createElement("DIV");
        waiting.classList.add("vjs-waiting-anim");
        waiting.innerHTML = "<em></em><em></em><em></em>";
        return waiting;
    }
}

function changeTimeFormat(videojs, vjsPlayer) {
    return function () {
        videojs.setFormatTime(vjsPlayer.duration() < 3600 ? formatTimeMMSS : formatTimeHHMMSS);
    };

    function formatTimeMMSS(time) {
        if (isNaN(time) || time === Infinity) {
            return "--:--";
        }
        if (time < 1) {
            return "00:00";
        }
        let m = (time / 60) | 0;
        let s = (time - m * 60) | 0;
        return padZero(m) + ":" + padZero(s);
    }
    function formatTimeHHMMSS(time) {
        if (isNaN(time) || time === Infinity) {
            return "--:--:--";
        }
        if (time < 1) {
            return "00:00:00";
        }
        let h = (time / 3600) | 0;
        let m = (time / 60) | 0;
        let s = (time - m * 60) | 0;
        m -= h * 60;
        return padZero(h) + ":" + padZero(m) + ":" + padZero(s);
    }
    function padZero(num) {
        return num < 10 ? "0" + num : num;
    }
}
function onTap(event) {
    this.userActive(true);
}
function onTouchStart(event) {
    const { playbackRateMenuButton, qualityButton } = this.controlBar;
    if (playbackRateMenuButton.buttonPressed_ || qualityButton.buttonPressed_) {
        return;
    }
    const userWasActive = this.userActive();
    if (userWasActive || this.paused()) {
        this.handleTechClick_(event);
    }
    this.userWasActive = userWasActive;
}

function customizePlaybackRateMenuButton(videojs) {
    const PlaybackRateMenuButton = videojs.getComponent("PlaybackRateMenuButton");
    const PlaybackRateMenuItem = videojs.getComponent("PlaybackRateMenuItem");
    const MenuButton = videojs.getComponent("MenuButton");

    PlaybackRateMenuButton.prototype.updateLabel = function updateLabel(event) {
        if (this.playbackRateSupported()) {
            this.labelEl_.textContent = rateLabel(this.player().playbackRate());
        }
    };
    PlaybackRateMenuButton.prototype.createItems = function createItems() {
        return this.playbackRates().map(rate => new PlaybackRateMenuItem(this.player(), { rate: rateLabel(rate) }));
    };
    PlaybackRateMenuButton.prototype.handleClick = function handleClick(event) {
        MenuButton.prototype.handleClick.call(this, event);
    };
    function rateLabel(rate) {
        return rate + (Number.isInteger(rate) ? ".0" : "") + "x";
    }
}

function genPopupPad(videojs, onclick) {
    return videojs.dom.createEl("div", { className: "vjs-popup-pad", onclick });
}

function customizeMouseTimeDisplay(videojs) {
    const TimeTooltip = videojs.getComponent("TimeTooltip");
    TimeTooltip.prototype.update = function update(seekBarRect, seekBarPoint, content) {
        const reservedLength = 16;
        const seekBarWidth = seekBarRect.width;
        const spaceLeftOfPoint = seekBarWidth * seekBarPoint;
        const spaceRightOfPoint = seekBarWidth - spaceLeftOfPoint;
        let offset = 0;
        if (spaceLeftOfPoint < reservedLength) {
            offset = reservedLength - spaceLeftOfPoint;
        } else if (spaceRightOfPoint < reservedLength) {
            offset = spaceRightOfPoint - reservedLength;
        }
        this.el_.style.left = offset + "px";
        this.write(content);
    };
}

function customizeMenuButton(videojs, isMobile) {
    const MenuButton = videojs.getComponent("MenuButton");
    const createMenu = MenuButton.prototype.createMenu;
    MenuButton.prototype.createMenu = function () {
        const menu = createMenu.call(this);
        const onPadClick = () => {
            this.unpressButton();
            this.removeClass("vjs-hover");
        };
        menu.el_.appendChild(genPopupPad(videojs, onPadClick));
        return menu;
    };
    if (isMobile) {
        return;
    }
    MenuButton.prototype.handleClick = function () {};
}

function changeFullScreenAction(videojs, options) {
    if (options.useNativeFullscreen) {
        return;
    }
    const Player = videojs.getComponent("Player");
    Player.prototype.requestFullscreenHelper_ = function (fullscreenOptions) {
        this.enterFullWindow();
    };
    Player.prototype.exitFullscreenHelper_ = function () {
        this.exitFullWindow();
    };
}

function changeSeekBarAction(videojs) {
    const Slider = videojs.getComponent("Slider");
    const SeekBar = videojs.getComponent("SeekBar");
    Slider.prototype.calculateDistance = function (event) {
        const SliderBox = this.el_.getBoundingClientRect();
        const locationRoot = event.changedTouches ? event.changedTouches[0] : event;

        let length, lengthMax;

        if (this.vertical()) {
            length = SliderBox.bottom - locationRoot.pageY + window.pageYOffset;
            lengthMax = SliderBox.height;
        } else {
            length = locationRoot.pageX - window.pageXOffset - SliderBox.left;
            lengthMax = SliderBox.width;
        }
        return clamp(length / lengthMax, 1, 0);
    };
    SeekBar.prototype.getPercent = function () {
        if (this.player_.seekBarMoving) {
            return this._percentage;
        }
        const player = this.player();
        return player.currentTime() / player.duration();
    };
    SeekBar.prototype.handleMouseDown = function handleMouseDown(event) {
        if (!videojs.dom.isSingleLeftClick(event)) {
            return;
        }
        event.stopPropagation();
        this.startSeeking();
        this.updateByMousePosition(event);
        Slider.prototype.handleMouseDown.call(this, event);
    };
    SeekBar.prototype.handleMouseMove = function (event) {
        if (!videojs.dom.isSingleLeftClick(event)) {
            return;
        }
        this.updateByMousePosition(event);
    };
    SeekBar.prototype.handleMouseUp = function (event) {
        if (event) {
            event.preventDefault(); // cancel mouseup for touchend event;
            event.stopPropagation();
        }
        Slider.prototype.handleMouseUp.call(this, event);
        this.updateByMousePosition(event);
        this.stopSeeking();
    };
    SeekBar.prototype.startSeeking = function () {
        const player = this.player_;
        player.off("timeupdate", this.checkSeekComplete);
        player.seekBarMoving = true;
        player.trigger("manualSeeking");
    };
    SeekBar.prototype.stopSeeking = function () {
        const player = this.player();
        this._time = Math.min(this._time, player.duration() - 1);
        /* 
            workaround to fix progress bar jump issue.
            seems that IOS might not update currentTime instantly on seeked event.
        */
        player.on("timeupdate", this.checkSeekComplete);
        player.currentTime(this._time);
    };
    SeekBar.prototype.checkSeekComplete = function () {
        if (Math.abs(this.player_.currentTime() - this._time) > 0.5) {
            return;
        }
        this.onSeekComplete();
    };
    SeekBar.prototype.onSeekComplete = function () {
        const player = this.player_;
        player.off("timeupdate", this.checkSeekComplete);
        player.seekBarMoving = false;
        player.trigger({ type: "timeupdate", target: this, manuallyTriggered: true });
        player.trigger({ type: "manualSeeked", time: this._time });
    };
    SeekBar.prototype.updateByMousePosition = function (event) {
        let newPercentage = this.calculateDistance(event);
        let newTime = newPercentage * this.player_.duration();
        this.manualUpdate(newTime, newPercentage);

        const mouseTimeDisplay = this.getChild("mouseTimeDisplay");
        mouseTimeDisplay.update({ width: this.el_.offsetWidth }, newPercentage);
    };
    SeekBar.prototype.updateByTime = function (newTime) {
        this.manualUpdate(newTime, newTime / this.player_.duration());
    };
    SeekBar.prototype.manualUpdate = function (newTime, newPercentage) {
        this._time = newTime;
        this._percentage = newPercentage;
        this.update();
        this.player_.controlBar.currentTimeDisplay.updateTextNode_(newTime);
        this.bar.el_.dataset.time = videojs.formatTime(newTime);
    };

    const CurrentTimeDisplay = videojs.getComponent("CurrentTimeDisplay");
    CurrentTimeDisplay.prototype.updateContent = function (event) {
        if (this.player_.seekBarMoving) {
            return;
        }
        let time;
        if (this.player_.ended()) {
            time = this.player_.duration();
        } else {
            time = this.player_.scrubbing() ? this.player_.getCache().currentTime : this.player_.currentTime();
        }
        this.updateTextNode_(time);
    };
}

function changeVolumePanelAction(videojs) {
    const VolumePanel = videojs.getComponent("VolumePanel");
    VolumePanel.prototype.userActive = function () {
        this.addClass("vjs-user-action");
        if (this.userActionEvent) {
            clearTimeout(this.userActionEvent);
        }
        this.userActionEvent = setTimeout(() => {
            this.removeClass("vjs-user-action");
            this.userActionEvent = null;
        }, 2e3);
    };
}

function releaseSliderOnMouseLeave(container, sliderControl, slider) {
    const releaseControl = event => sliderControl.handleMouseUp(event);
    slider.on("slideractive", () => container.addEventListener("mouseleave", releaseControl));
    slider.on("sliderinactive", () => container.removeEventListener("mouseleave", releaseControl));
}

function unpressMenuButtonOnOutsideTouch(container, menuButton) {
    const onTouchEnd = event => {
        if (event.composedPath().indexOf(menuButton.el_) < 0) {
            menuButton.unpressButton(event);
        }
    };
    container.addEventListener("touchend", onTouchEnd, true);
}

import { Deferred, hidden, visibilityChange } from "../Util";

export default function WaitPageViewable() {
    let deferred = new Deferred();
    let addVisibilityChangeCallback = () => {
        document.addEventListener(visibilityChange, onVisibilityChange);
    }
    let removeVisibilityChangeCallback = () => {
        document.removeEventListener(visibilityChange, onVisibilityChange);
    }
    let onVisibilityChange = () => {
        if (document[hidden]) {
            return
        }
        removeVisibilityChangeCallback();
        deferred.resolve();
    }
    return {
        promise() {
            if (document[hidden]) {
                addVisibilityChangeCallback();
            } else {
                deferred.resolve()
            }
            return deferred.promise;
        },
        cancel() {
            removeVisibilityChangeCallback();
            deferred.reject()
        },
    }
}

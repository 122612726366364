import ErrorType from "../Util/ErrorType.js";
import { ppnCdiUrl } from "../Util/Urls.js";
import { ENV } from "../Util/Util.js";
import { fetchQuery, postOption } from "../Util/requestHelper.js";

const ppnCdi = ppnCdiUrl();

export async function getLineup(partner) {
    const baseUrl =
        __RELEASE_MODE__ || !ENV.useLocal
            ? `${ppnCdi}/content/epg/lineup/${partner}`
            : `${location.protocol}//${ENV.host}/meta/channel/lineup/${partner}.json`;

    return responseCheck(fetchQuery(baseUrl), "Failed to fetch lineup");
}

export async function getSchedule(cdnCode, startTime, endTime) {
    const baseUrl =
        __RELEASE_MODE__ || !ENV.useLocal
            ? `${ppnCdi}/content/epg/schedule/${cdnCode}?`
            : `${location.protocol}//${ENV.host}/meta/channel/schedule/${cdnCode}.json?`;

    let request = fetchQuery(baseUrl, { start_time: startTime, end_time: endTime });
    return responseCheck(request, "Failed to fetch Schedule");
}

export function GetVideoUrl(config) {
    const baseUrl = ppnCdi + "/video/urls?";
    const deviceType = "pc"; // 這裡的 deviceType 在 mobile 上用網頁瀏覽器還是用 pc, mobile 預期是給 mobile app
    const { externalPlayer, puid, partnerName, deviceId } = config;

    return async function (assetId, mediaType) {
        let request = fetchQuery(baseUrl, {
            asset_id: assetId,
            media_type: mediaType,
            device_type: deviceType,
            device_id: deviceId,
            external_player: externalPlayer,
            partner: partnerName,
            puid: puid,
        });
        let responseJson = await responseCheck(request, "Failed to fetch Content url");
        return responseJson.asset_urls[0];
    };
}

export function WatchLogPost(config, meta) {
    const baseUrl = ppnCdi + "/video/watch_log";
    const { deviceId, sponsorName } = config;
    return function () {
        const fetchOption = postOption({
            asset_id: meta.assetId,
            device_id: deviceId,
            encode_partner: sponsorName,
        });
        fetch(baseUrl, fetchOption);
    };
}
/*
Response would be like
200: "proper result",
400: { "code": 40000000, "message": "string" }
500: "string"

Ref: https://s-cdi.ofiii.com/ppn_cdi/swagger/index.html
*/
async function responseCheck(request) {
    try {
        let response = await request;
        let result = await response.json();
        if (response.ok) {
            return result;
        }
        throw new CdiError(result, response.status);
    } catch (err) {
        throw err;
    }
}

class CdiError {
    constructor(result, status) {
        this.type = ErrorType.CDI_ERROR;
        this.code = result.code;
        this.status = status;
        this.message = result.message;
    }
}

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* use .css because there's issue in less loader while handling css max function */
.logo_container .watch_more {
    cursor: pointer;
    pointer-events: auto;
    position: absolute;
    right: 10px;
    bottom: max(20px, calc(20px + var(--control-bar-height) - var(--logo-offset)));
    transition: bottom 0.6s;
    padding: 5px 11px;
    border-radius: 4px;
    border: solid 1px #fff;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
    text-decoration: none;
}

.logo_container .watch_more::before {
    content: attr(data-title);
    color: white;
    font-size: 14px;
}

.vjs-has-started.vjs-user-inactive.vjs-playing .logo_container .watch_more {
    bottom: 20px;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import LiX from './LiX.js';
import Report from './Report.js';
import ObserverPattern from './ObserverPattern.js';
import PauseAndResumController from './PauseAndResumController.js';
import {ExtendableError, LiAdError, LiAdErrorCodes} from './CustomError.js';
import {checkInViewport} from './Util.js';

const PREROLLS = "prerolls",
      MIDROLLS = "midrolls",
      POSTROLLS = "postrolls",
      LOGO = "logo",
      JINGLE = "jingle",
      PAUSE_AD = "pause_ad",
      BLOCK_AD = "block_ad",
      EXIT_AD = "exit_ad",
      END_AD = "end_ad",
      COMM_AD = "comm_ad",
      HOUSE_AD = "house_ad",
      CONTENT_POOL = "content_pool",
      PIN_AD = "pin_ad";

const SUPPORTIVE = [PREROLLS, MIDROLLS, POSTROLLS, LOGO, JINGLE, PAUSE_AD, BLOCK_AD, EXIT_AD, END_AD, PIN_AD];
const LINEAR_AD_LIST = [PREROLLS, MIDROLLS, POSTROLLS, BLOCK_AD, EXIT_AD, JINGLE, COMM_AD, HOUSE_AD, END_AD, CONTENT_POOL];
const ENABLE_COUNTDOWN_LIST = [PREROLLS, MIDROLLS, POSTROLLS, BLOCK_AD, EXIT_AD, COMM_AD, HOUSE_AD, END_AD];
const INTERVAL_INHERIT = [PREROLLS, POSTROLLS, JINGLE];

const EndReason = {
    CanNotFindThisPart : "Can Not Find This Part",
    ExcludedByInterval : "Excluded By Interval",
    ExcludedByRatio : "Excluded By Ratio"
};

export default class LiAd extends ObserverPattern{
    constructor(imaPack, houseVideoAd, imageAd, pinAdController, countdownComponent, playedCount, urlConfig, interObsProxy){
        super();
        this.lock = true;
        this.queue = [];
        this.oriLiadMeta = null;
        this.isPaused = false;
        this.imaPack = imaPack;
        this.houseVideoAd = houseVideoAd;
        this.imageAd = imageAd;
        this.pinAdController = pinAdController;
        this.countdownComponent = countdownComponent;
        this.playedCount = playedCount;
        this.interObsProxy = interObsProxy;
        this.elements = null;
        this.parts = null;
        this.midrollTimeCodes = [];
        this.role = null;
        this.assetId = null;
        this.linearAdMediaProxy = null;
        this.linearAdMediaData = null;
        this.samplingElement = this.samplingElement();
        this.pauseAndResumController = new PauseAndResumController();
        this.report = new Report(urlConfig);
        this.report.on("Report", e => this.trigger("Report", {
            reportData: e.reportData,
            partType: e.partTypeInfo.partType,
            trunkType: e.partTypeInfo.trunkType,
            sourcePartType: e.partTypeInfo.sourcePartType,
        }));
        this.ignoreDocumentHiddenCheck = false;
        this.inLinearAdStream = false;
        this.puid = "";

        this.autoBind(this);

        SUPPORTIVE
        .forEach(partType => {
            let fnName = partType.replace(/\_(\w)/g, ($0, $1) => $1.toUpperCase()); // 轉成駝峰
            this[fnName] = this.partFactory(partType);
        });

        this.pauseAndResumController
        .on("RequestPause", e => {
            let lasEvent = this.trigger("LinearAdStart", { partType: e.partType, mediaType: e.mediaType });
            if(e.partType == MIDROLLS && !lasEvent.defaultPrevented) {
                this.trigger("RequestPause", { partType: e.partType, rewind: e.rewind });
            }
        })
        .on("RequestResum", e => {
            let lacEvent = this.trigger("LinearAdComplete", { partType: e.partType });
            if(e.partType == MIDROLLS && !lacEvent.defaultPrevented){
                this.trigger("RequestResum", { partType: e.partType, rewind: e.rewind });
            }
        })
        .on("Reset", () => {
            this.trigger("ResetPauseAndResum");
        });
    }

    autoBind(self){
        for (const key of Object.getOwnPropertyNames(self.constructor.prototype)) {
            const val = self[key];
            if (key !== 'constructor' && typeof val === 'function') {
                self[key] = val.bind(self);
            }
        }
    }

    partFactory(partType){
        return (condition) => {
            let cancelled = false;
            let abort = () => {
                cancelled = true;
            };
            let removeListenStop = () => {
                this.off("Stop", abort);
            };
            this.one("Stop", abort);
            let info = {
                partType: partType,
                condition: condition
            };

            let entity = {
                start: () => {
                    return new Promise((resolve, reject) => {
                        if(this.lock == true){
                            this.queue = [entity];
                            let error = new Error(`${partType} is interrupted because not unlocked yet.`);
                            error.isWarning = true;
                            reject(error);
                            return;
                        }

                        this.next(info.partType, info.condition)
                        .then(pauseAndResumController => {
                            if(!cancelled) pauseAndResumController.requestResum();
                            removeListenStop();
                            resolve();
                        })
                        .catch(({error, pauseAndResumController}) => {
                            if(!cancelled && pauseAndResumController){
                                pauseAndResumController.requestResum();
                            }
                            removeListenStop();
                            if(error.isWarning){
                                console.warn(error.message);
                            }else{
                                console.error(error);
                            }
                            reject(error);
                        });
                    });
                },
                info: info
            };

            return entity;
        };
    }

    reconstruct(oriData = {}, disableCountdown, midrollTimeCodes, midrollTimecodeDuration){ //TODO 優化設定方式
        let parts = [];
        let elements;
        let loseElementsId = [];
        let newMidrollTimeCodes = midrollTimeCodes.concat();

//        Object.keys(oriData).filter(p => {
//            if(p == "elements") return false;
//            let data = oriData[p];
//            return data.element_id != null && data.element_id.length !== 0;
//        })

        elements = oriData.elements || {};
        elements["*empty"] = {
            "id": "*empty",
            "space_id": "*empty",
            "users": "All",
            "media_type": "video",
            "schema": "empty"
        };

        for(let p in oriData){
            let data = oriData[p];
            if(data == null || p == "elements" || data.element_id == null) continue;
            if(data.element_id.length == 0){ //XXX 做法不好
                if(p == COMM_AD) data.element_id.push(["*empty"]);
                else continue;
            }

            let part = Object.assign({}, data);

            if(p == PREROLLS || p == MIDROLLS || p == POSTROLLS || p == EXIT_AD || p == END_AD){
                //part.no_ad = oriData.hasOwnProperty(COMM_AD) ? COMM_AD : HOUSE_AD;
                part.no_ad = COMM_AD;
            }else if(p == COMM_AD){
                part.no_ad = HOUSE_AD;
            }

            // EnableCountdown
            if(disableCountdown == true){
                part.enable_countdown = false;
            }else{
                part.enable_countdown = ENABLE_COUNTDOWN_LIST.includes(p);
            }

            // is_linear
            part.is_linear = LINEAR_AD_LIST.includes(p);

            // inherit_interval
            part.inherit_interval = INTERVAL_INHERIT.includes(p);

            //req_timeshift
            if(p == MIDROLLS){
                newMidrollTimeCodes = newMidrollTimeCodes.map((item, index) => {
                    return {
                        time: item + part.req_timeshift,
                        duration: midrollTimecodeDuration[index]
                    };
                });
            }

            let isLogo = /^logo_/.test(p);
            part.type = isLogo ? LOGO : p;
            part.position = !isLogo ? "MC" : p.match(/_(.+)/i)[1].toUpperCase();

            //將 sampling 調整為 -1 代表依序
            Object.keys(part)
            .filter(k => /_sampling$/i.test(k))
            .forEach(k => {
                if(Array.isArray(part[k])){
                    part[k] = part[k].map(i => {
                        if(i === 0) return -1;
                        return i;
                    })
                }else{
                    if(part[k] === 0) part[k] = -1;
                }
            });

            //將 content_pool 的 element_sampling 調整為 -2 代表亂序但不取幾筆
            if(p == CONTENT_POOL){
                part.element_sampling = -2;
            }

            parts.push(part);

            part.element_id = data.element_id.map(function(eIds, fId, arr){
                return eIds.map(function(eId){
                    if(!elements[eId]){
                        loseElementsId.push(eId);
                        eId = "*empty";
                    }

                    let element = elements[eId];
                    let mtMatch = element.media_type.match(/(.+)_(.+)/i);
                    if(mtMatch != null){
                        element.media_type = mtMatch[1];
                        element.media_type_extend = mtMatch[2];
                    }

                    if(element.media_type !== "image") return eId;
                    element.is_logo = isLogo;
                    return eId;
                });
            });
        }

        if(loseElementsId.length > 0){
            loseElementsId = loseElementsId.filter((e, i, a) => a.indexOf(e) === i);
            console.error("lose elements",loseElementsId);
        }

        return {
            parts : parts,
            elements : elements,
            midroll_time_codes : newMidrollTimeCodes
        };

    }

    next(partType, condition){
        return new Promise((resolve, reject) => {
            const isLinear = LINEAR_AD_LIST.includes(partType);
            if(isLinear == true){
                if(this.inLinearAdStream == true){
                    let error = new Error(`${partType} is interrupted because the previous linear ad stream has not ended yet.`);
                        error.isWarning = true;
                    reject({error: error});
                    return;
                }
                this.inLinearAdStream = true;
            }

            let ignoreInterval = null;
            let endTime = null;
            if(typeof condition === "boolean"){
                ignoreInterval = condition;
            }else if(typeof condition === "number"){
                if(condition > 31536000000){
                    endTime = condition;
                }else{
                    endTime = +new Date + (condition * 1000);
                }
            }else if(typeof condition !== "undefined"){
                ignoreInterval = condition.ignoreInterval;
                if(condition.endTime){
                    endTime = condition.endTime;
                }else if(condition.duration){
                    endTime = +new Date + (condition.duration * 1000);
                }
            }

            this.trigger("Progress", {
                partType: partType,
                condition: condition,
                endTime: endTime,
                state: "AdStreamStart"
            });

            let pauseAndResumController = this.pauseAndResumController.getSub(partType);
            this.adPart$(this.parts, partType, this.elements, this.role, endTime, null, pauseAndResumController, null, partType, ignoreInterval, isLinear)
            .then(x => {
                if(x == null){
                    return Promise.resolve([x, null]);
                }

                let lastType = partType;
                let lastExclude = null;
                if(x.length > 0){
                    let last = x[x.length - 1];
                    lastType = last.part.type;
                    lastExclude = last.exclude;
                }

                return this.fillRemainingTime$(this.parts, this.elements, this.role, endTime, lastExclude, pauseAndResumController, lastType, partType, [], x, ignoreInterval);
            })
            .then(([x, _x]) => {
                this.trigger("Progress", {
                    partType: partType,
                    result: x,
                    contentPoolResult: _x,
                    state: "AdStreamComplete"
                });
                this.trigger("AdStreamComplete", {
                    partType: partType,
                    result: x,
                    contentPoolResult: _x
                });
                if(isLinear == true) this.inLinearAdStream = false;
                if(ignoreInterval == true) this.playedCount.transit(partType, null, null, true);
                resolve(pauseAndResumController);
            })
            .catch(e => {
                if(isLinear == true) this.inLinearAdStream = false;
                if(ignoreInterval == true) this.playedCount.transit(partType, null, null, true);
                if(e == "abort") {
                    this.trigger("AdStreamCancel", {
                        partType: partType,
                        isLinear: isLinear
                    });
                    resolve(pauseAndResumController);
                    return;
                }
                reject({error: e, pauseAndResumController: pauseAndResumController});
            });
        });
    }

    adPart$(parts, type, elements, role, endTime, exclude, pauseAndResumController, sourceType, trunkType, ignoreInterval, isLinear){
        return new Promise((resolve, reject) => {
            LiX.of(parts)
            .takeUntilFromEventPattern(h => {
                this.on("Stop", h);
            }, h => {
                this.off("Stop", h);
            })
            .do(x => {
                this.trigger("Progress", {
                    partType: type,
                    trunkType: trunkType,
                    sourcePartType: sourceType,
                    partsInfo: x,
                    isLinear: isLinear,
                    state: "AdPartStart"
                });
            })
            .takeUntilFromFilter(x => x.type == type, EndReason.CanNotFindThisPart)
            .takeUntilFromFilter(x => {
                if(ignoreInterval == true || trunkType !== type || x.is_linear !== true) return true;
                return this.playedCount.transit(type, x.min_interval, x.inherit_interval);
            }, EndReason.ExcludedByInterval)
            .takeUntilFromFilter(x => {
                if(typeof x.partobj_ratio === "undefined") return true;
                return x.partobj_ratio > Math.random() * 10;
            }, EndReason.ExcludedByRatio)
            .map(x => {
                let elementIds = JSON.parse(JSON.stringify(x.element_id)).map(fIds => {
                    return fIds.filter(id => {
                        if(role == "Free"){
                            return elements[id].users != "Pay";
                        }else{
                            return elements[id].users != "Free";
                        }
                    });
                });
                elementIds = this.samplingElement(elementIds, x.element_sampling, x.adobj_sampling, x.adobj_ratio);
                x.dc_element_id = elementIds;
                pauseAndResumController.setRewind(x.rewind);
                return x;
            })
            .map(x => {
                return this.adWaterflow$(x, parts, type, elements, role, endTime, exclude, pauseAndResumController, sourceType, trunkType, ignoreInterval);
            })
            .merge()
            .subscribe((x, reason) => {
                let partsInfo = x && x.reduce((p, c) => {
                    p.push(c.part);
                    return p;
                }, []);

                this.trigger("Progress", {
                    endReason: reason,
                    partType: type,
                    trunkType: trunkType,
                    sourcePartType: sourceType,
                    partsInfo: partsInfo,
                    isLinear: isLinear,
                    state: "AdPartComplete"
                });

                //XXX 做法不太好
                if(reason === EndReason.ExcludedByInterval){
                    x = null;
                }
                resolve(x);
            }, e => {
                reject(e);
            });

        });
    }

    adWaterflow$(part, parts, type, elements, role, endTime, exclude, pauseAndResumController, sourceType, trunkType, ignoreInterval){
        function getNextSampling(guarantee = -1, filling = -1, slot, noAd) {
            noAd = typeof noAd == "number" ? noAd : 0;
            if (guarantee > -1) {
                guarantee = Math.max(0, guarantee - (slot - noAd));
            }
            let remain = Math.max(guarantee, filling);
            return remain < 0 || noAd < remain ? noAd : remain;
        }

        let checkTimeout = () => {
            return endTime == null || endTime > +new Date;
        };
        return () => {
            return new Promise((resolve, reject) => {
                LiX.of(part.dc_element_id)
                .takeUntilFromEventPattern(h => {
                    this.on("Stop", h);
                }, h => {
                    this.off("Stop", h);
                })
                .do(x => {
                    this.trigger("Progress", {
                        partType: type,
                        trunkType: trunkType,
                        sourcePartType: sourceType,
                        elementIds: x,
                        state: "AdWaterflowStart"
                    });
                })
                .takeWhile(checkTimeout)
                .map(x => {
                    return this.adSlot$(part, x, elements, type, endTime, exclude, pauseAndResumController, sourceType, trunkType);
                })
                .concat()
                .takeWhile(() => {
                    return checkTimeout() && this.documentHiddenCheck();
                })
                .count(x => x.needPlayNoAd == true)
                .subscribe(([noAdCount, x]) => {
                    let _exclude = null;
                    if(sourceType != type){
                        let lastItem = x[x.length - 1];
                        if(lastItem){
                            _exclude = {
                                elementIds: lastItem.excludeElementIds,
                                adIds: lastItem.excludeAdIds
                            };
                        }else{
                            _exclude = exclude;
                        }
                    }else{
                        _exclude = exclude;
                    }

                    let hasAdImpression = x.some(i => i.needPlayNoAd === false);
                    let needPlayBlockAd = x.some(i => i.needPlayBlockAd === true);
                    let hasAdStart = x.some(i => i.hasAdStart);

                    this.trigger("Progress", {
                        partType: type,
                        trunkType: trunkType,
                        sourcePartType: sourceType,
                        noAdCount: noAdCount,
                        hasAdImpression: hasAdImpression,
                        hasAdStart: hasAdStart,
                        needPlayBlockAd: needPlayBlockAd,
                        exclude: _exclude,
                        state: "AdWaterflowComplete"
                    });

                    // if(hasAdImpression == true){
                    if(hasAdStart == true){
                        if(!ignoreInterval && part.is_linear == true){
                            this.playedCount.resetCertainOne(trunkType);
                        }
                    }

                    let next = "";
                    if(needPlayBlockAd == true){
                        this.trigger("Progress", { trunkType: trunkType, currentType : type, state: "GotoBlockAd" });
                        next = BLOCK_AD;
                    }else if(part.no_ad){
                        let nextSampling = getNextSampling(part.guarantee, part.filling, x.length, noAdCount);

                        if (nextSampling > 0) {
                            this.trigger("Progress", { trunkType: trunkType, currentType : type, noAdType: part.no_ad, count: noAdCount, state: "GotoNoAd" });
                            parts
                            .filter(item => item.type === part.no_ad)
                            .forEach(item => item.element_sampling = nextSampling);

                            next = part.no_ad;
                        }
                    }

                    if(next !== ""){
                        return this.adPart$(parts, next, elements, role, endTime, _exclude, pauseAndResumController, type, trunkType, ignoreInterval, LINEAR_AD_LIST.includes(next))
                        .then(_x => {
                            let xp = _x.reduce((p, c) => {
                                p.parts = p.parts.concat(c.parts);
                                p.exclude.adIds = p.exclude.adIds.concat(c.exclude.adIds);
                                p.exclude.elementIds = p.exclude.elementIds.concat(c.exclude.elementIds);
                                return p;
                            }, {parts:[], exclude:{adIds:[], elementIds:[]}});

                            xp.parts = xp.parts.concat([part]);
                            xp.part = part;
                            return resolve(xp);
                        })
                        .catch((e) => {
                            reject(e);
                        });
                    }

                    return resolve({parts: [part], exclude: _exclude, part:part});
                }, e => {
                    if(e == "abort") return;
                    console.error(e);
                    reject(e);
                });
            });
        };
    }

    adSlot$(part, elementIds, elements, type, endTime, exclude, pauseAndResumController, sourceType, trunkType){
        return (pr) => {
            let excludeElementIds = [];
            let excludeAdIds = [];

            if(sourceType != type){
                if(pr){
                    excludeElementIds = excludeElementIds.concat(pr.excludeElementIds);
                    excludeAdIds = excludeAdIds.concat(pr.excludeAdIds);
                }else if(exclude){
                    exclude.elementIds && (excludeElementIds = excludeElementIds.concat(exclude.elementIds));
                    exclude.adIds && (excludeAdIds = excludeAdIds.concat(exclude.adIds));
                }
            }

            return new Promise((resolve, reject) => {
                LiX.of(elementIds)
                .takeUntilFromEventPattern(h => {
                    this.on("Stop", h);
                }, h => {
                    this.off("Stop", h);
                })
                .filter(x => !excludeElementIds.includes(x))
                .map(x => this.adElement$(elements[x], part, type, endTime, excludeAdIds, pauseAndResumController, sourceType, trunkType))
                .concat()
                .takeWhile(x => {
                    return (x.entity instanceof Error || x.entity instanceof EmptyContent);
                })
                .subscribe(x => {
                    let result = {
                        needPlayNoAd : false,
                        needPlayBlockAd : false,
                        hasAdStart: false,
                        excludeElementIds : excludeElementIds,
                        excludeAdIds: excludeAdIds
                    };

                    let completeItem = x.find(r => r.type === "complete");
                    if(completeItem){
                        if(completeItem.adId !== null){
                            result.excludeAdIds.push(completeItem.adId);
                        }else{
                            result.excludeElementIds.push(completeItem.elementId);
                        }
                    }

                    let isBlockAdIssue = false;
                    let impression = x.some(i => {
                        if(i.entity instanceof VideoAdError){
                            isBlockAdIssue = isBlockAdIssue || i.entity.reportType === "blocked";
                            return false;
                        }else if(i.entity instanceof EmptyContent){
                            return false;
                        }
                        return true;
                    });

                    let mediaStart = x.some(i => i.mediaStart);

                    result.needPlayNoAd = !impression && !isBlockAdIssue;
                    result.needPlayBlockAd = isBlockAdIssue;
                    result.hasAdStart = mediaStart;
                    resolve(result);
                }, e => {
                    if(e == "abort") return;
                    console.error(e);
                    reject(e);
                });
            });
        };
    }

    adElement$(element, part, type, endTime, excludeAdIds, pauseAndResumController, sourceType, trunkType){
        let logger = this.report.createLogger(this.assetId, element.space_id, element.unit_id, element.title, this.puid, { partType: type, trunkType: trunkType, sourcePartType: sourceType });
        return () => {
            return new Promise((resolve, reject) => {
                let result = { type: null, entity: null, elementId: element.space_id, adId: null, mediaStart: false};

                if(part.type == "logo") {
                    this.imageAd.removeLogo(part.position);
                }
                if(element.schema == "empty"){
                    result.type = "emptyContent";
                    result.entity = new EmptyContent(element.media_type);
                    return resolve(result);
                }
                if(element.media_type == "video" || element.media_type == "image"){
                    let cancelled = false;
                    let abort = (event) => {
                        if(event.reason == "requestPauseAd"){
                            logger.break();
                        }
                        cancelled = true;
                    };

                    this.one("Stop", abort);

                    return this.adMedia$(element, part, type, endTime, excludeAdIds, logger, pauseAndResumController, sourceType, trunkType).then((result) => {
                        this.off("Stop", abort);
                        if(!cancelled) resolve(result);
                    }).catch((e) => {
                        this.off("Stop", abort);
                        if(!cancelled) reject(e);
                    });
                }else{
                    console.warn("not support media type: " + element.media_type + " [" + element.space_id + "]");
                    let liAdError = new LiAdError(LiAdErrorCodes.codes.MEDIA_TYPE_MISMATCH);
                    logger.error(liAdError);
                    result.type = "error";
                    result.entity = liAdError;
                    return resolve(result);
                }
            });
        };
    }

    adMedia$(mediaData, part, type, endTime, excludeAdIds, logger, pauseAndResumController, sourceType, trunkType){
        let data = Object.assign({}, mediaData, {position: part.position, is_linear: part.is_linear, enable_countdown: part.enable_countdown, end_time: endTime});
        return new Promise((resolve, reject) => {
            let result = { type: null, entity: null, elementId: data.space_id, adId: null, mediaStart: false };
            let adProxy = null;
            let cancelled = false;
            let abort = () => {
                cancelled = true;
            };

            this.one("Stop", abort);

            let schemaAndSource = data.schema.split("_");
            data.adSource = schemaAndSource[1];
            if(type == PIN_AD){
                adProxy = this.pinAdController;
            }else{
                let pkSchema = schemaAndSource[0];
                switch(pkSchema){
                    case "interactive":
                        data.enable_countdown = false;
                        data.isInteractive = true;
                    case "yahoo": case "vast": case "ima": 
                        adProxy = this.imaPack.setExcludeAdIds(excludeAdIds);
                        break;
                    case "litv":
                        if(data.media_type == "image"){
                            adProxy = this.imageAd;
                        }else{
                            adProxy = this.houseVideoAd;
                        }
                        break;
                    case "gam":
                        if(data.media_type == "image"){
                            data.isGpt = true;
                            adProxy = this.imageAd;
                            break;
                        }
                    default:
                        let liAdError = new LiAdError(LiAdErrorCodes.codes.SCHEMA_MISMATCH);
                        logger.error(liAdError);
                        result.type = "error";
                        result.entity = liAdError;
                        return resolve(result);
                }
            }

            let isLinear = part.is_linear;
            if(isLinear) {
                this.linearAdMediaProxy = adProxy;
                this.linearAdMediaData = mediaData;
                if(this.isPaused) adProxy.pause();
            }

            let eventBase = {
                partType: type,
                trunkType: trunkType,
                sourcePartType: sourceType,
                isLinear: isLinear,
                isInteractive: data.isInteractive,
            };

            let codeTableTime = -1;
            let calcRequestTime = source => {
                if(source == "IMA" && codeTableTime != -1 && LiTVPlayer.conf.traceAdRequestTime == true){
                    let loadedTime = +new Date() - codeTableTime;
                    logger.adLoaded(loadedTime);
                    codeTableTime = -1;
                }
            };

            adProxy.adsRequest(data)
                .onRequest((e, source) => {
                    if(source == "IMA") {
                        codeTableTime = +new Date();
                        logger.request();
                    }
                    let info = Object.assign({}, eventBase, {requestInfo: e.requestInfo, processor: source });
                    this.trigger("AdRequest", info);
                    this.trigger("Progress", Object.assign(info, { state: "AdRequest" }));
                })
                .onLoaded((e, source) => {
                    if(source == "IMA") {
                        logger.serve();
                        LiTVPlayer.conf.traceAdDuration && logger.adDurationFetched(e.meta.duration || -1);
                        result.adId = e.adId || null;
                    }
                    this.trigger("Progress", Object.assign(eventBase, { processor: source, meta: e.meta }, { state: "AdLoaded" }));
                })
                .onImpression((e, source) => {
                    this.trigger("Impression", Object.assign({}, eventBase, { processor: source }));
                    switch(source){
                        case "IMA":
                        case "HouseVideoAd": 
                        case "ImageAd":
                        case "PinAdController": 
                            logger.impression(); 
                            break;
                    }
                })
                .onPauseRequested((e, source) => {
                    pauseAndResumController && pauseAndResumController.requestPause(data.media_type);
                })
                .onCompanionAd((e, source) => {
                    this.trigger("CompanionAd", Object.assign({}, { adType: "companionAd", meta: e.meta }));
                })
                .onCompanionAdEnd((e, source) => {
                    this.trigger("CompanionAdEnd", Object.assign({}, { adType: "companionAd" }));
                })
                .onAdMediaStart((e, source) => {
                    calcRequestTime(source);
                    let info = Object.assign({}, eventBase, { processor: source, duration: e.duration, mediaType: data.media_type });
                    result.mediaStart = true;
                    this.trigger("AdMediaStart", info);
                    this.trigger("Progress", Object.assign(info, {state: "AdMediaStart"}));
                })
                .onAdMediaComplete((e, source) => {
                    let info = Object.assign({}, eventBase, { processor: source });
                    this.trigger("AdMediaComplete", info);
                    this.trigger("Progress", Object.assign(info, {state: "AdMediaComplete"}));
                })
                .onCompleted((e, source) => {
                    this.off("Stop", abort);
                    if(isLinear) {
                        this.linearAdMediaProxy = null;
                        this.linearAdMediaData = null;
                    }
                    if(source == "IMA") {
                        logger.complete();
                    }
                    if(!cancelled) {
                        result.type = "complete";
                        result.entity = data;
                        resolve(result);
                    }
                })
                .onError((e, source) => {
                    let liAdError = e.error;
                    logger.error(liAdError);
                    this.trigger("Error", Object.assign({}, eventBase, { processor: source, error: e }));
                    if(e.fatal === false) return;
                    calcRequestTime(source);
                    this.off("Stop", abort);
                    if(isLinear) {
                        this.linearAdMediaProxy = null;
                        this.linearAdMediaData = null;
                    }

                    let error = data.media_type == "image" ? new ImageAdError(liAdError) : new VideoAdError(liAdError);
                    if(!cancelled) {
                        result.type = "error";
                        result.entity = error;
                        resolve(result);
                    }
                })
                .onCountdownStart(e => {
                    this.countdownComponent.start(data.purchase_url, e.clickFunction, e.time);
                })
                .onCountdownProgress(e => {
                    this.countdownComponent.progress(e.time);
                })
                .onCountdownEnd(() => {
                    this.countdownComponent.end();
                })
                .onClick((e, source) => {
                    switch(source){
                        case "IMA":
                        case "HouseVideoAd":
                        case "ImageAd":
                        case "PinAdController":
                            logger.click();
                            break;
                    }
                    this.trigger("AdClick", e);
                })
                .onClickSkip((e, source) => {
                    logger.vipSkip();
                })
                .onRequestResume((e, source) => {
                    this.resume();
                })
                .onPause((e, source) => {
                    this.trigger("AdPause", e);
                })
                .onResume((e, source) => {
                    this.trigger("AdResume", e);
                });
                // .onMuted((e, source) => {
                //     this.trigger("AdMuted", e);
                // });
        });
    }

    fillRemainingTime$(parts, elements, role, endTime, exclude, pauseAndResumController, sourceType, trunkType, preResult = [], freezeResult, ignoreInterval){
        let now = +new Date;
        this.trigger("Progress", { trunkType: trunkType, nowTime: now, endTime : endTime, state: "CheckRemainingTime" });
        if(endTime == null || now >= endTime){
            return Promise.resolve([freezeResult, preResult]);
        }else{
            this.trigger("Progress", { trunkType: trunkType, currentType : sourceType, state: "GotoContentPool" });
            const isLinear = LINEAR_AD_LIST.includes(CONTENT_POOL);
            return this.adPart$(parts, CONTENT_POOL, elements, role, endTime, exclude, pauseAndResumController, sourceType, trunkType, ignoreInterval, isLinear)
            .then(x => {
                let _exclude = ((x || [])[0] || {}).exclude || null;
                return this.fillRemainingTime$(parts, elements, role, endTime, _exclude, pauseAndResumController, CONTENT_POOL, trunkType, preResult.concat(x), freezeResult, ignoreInterval);
            });
        }
    }

    samplingElement(){
        function shuffle(arr){
            let _arr = arr.slice();
            for(let tmp, cur, top = _arr.length; top--;) {
                cur = (Math.random() * (top + 1)) << 0;
                tmp = _arr[cur];
                _arr[cur] = _arr[top];
                _arr[top] = tmp;
            }
            return _arr;
        }

        function sampling(arr, sampling){
            if(sampling == -1) return arr;
            else if(sampling == -2) return shuffle(arr);
            else return shuffle(arr).slice(0, sampling);
        }

        return function(elementIds, elementSampling, adObjSampling, adObjRatio){
            // 2021.05.26: set ratio to 10 (100%) if adObj_ratio is not set due to current policy.
            adObjRatio = adObjRatio || [];
            let elementInfos = elementIds.map((ids, index) => {
                let ratio = adObjRatio[index];
                ratio = typeof ratio == "number" ? ratio : 10;
                return {
                    ids : ids,
                    ratio: ratio,
                    sampling: adObjSampling[index]
                };
            }).filter(info => info.ids.length > 0);

            elementSampling = Math.min(elementSampling, elementInfos.length);

            return sampling(elementInfos, elementSampling)
                .filter(item => item.ratio > Math.random() * 10)
                .map(item => sampling(item.ids, item.sampling));
        };
    }

    documentHiddenCheck(){
        if(this.ignoreDocumentHiddenCheck == true) return true;
        //else return !document[hidden];
        else return checkInViewport(this.interObsProxy);
    }

    stopLinearAd(reason){
        Promise.resolve().then(() => {
            if(this.inLinearAdStream == false) return;
            this.trigger("Stop", {reason});
            this.imaPack.stop();
            this.houseVideoAd.stop();
            this.imageAd.stopLinearAd();
            this.countdownComponent.end();
            this.pauseAndResumController.forceResum();
        });
    }

    reset(destroy){
        this.trigger("Stop");
        this.queue = [];
        this.imaPack.stop(destroy);
        this.houseVideoAd.stop(destroy);
        this.imageAd.stop();
        this.countdownComponent.end();
        this.pauseAndResumController.reset();
    }

    setMeta(liadMeta, playAds, assetId, disableCountdown = false, midrollTimeCodes = [], midrollTimecodeDuration = [], startTime = 0, urlReplacement = [], ignoreDocumentHiddenCheck = false){
        this.lock = true;
        this.reset();
        this.oriLiadMeta = typeof liadMeta === "undefined" ? undefined : JSON.parse(JSON.stringify(liadMeta));
        if(liadMeta && liadMeta.length > 0 && !liadMeta.elements){
            this.trigger("MetaError", liadMeta);
            return;
        }
        let {elements, parts, midroll_time_codes} = this.reconstruct(liadMeta, disableCountdown, midrollTimeCodes, midrollTimecodeDuration);
        this.elements = elements;
        this.parts = parts;
        this.role = playAds == false ? "Pay" : "Free";
        this.assetId = assetId;
        this.midrollTimeCodes = midroll_time_codes;
        this.imaPack.setReplacement(urlReplacement);
        this.ignoreDocumentHiddenCheck = ignoreDocumentHiddenCheck;
    }

    setPuid(puid){
        this.puid = puid;
    }

    getQueue(){
        return this.queue;
    }

    unlock(){
        this.lock = false;
    }

    getMidrollTimeCodes(){
        return this.midrollTimeCodes;
    }

    pause(){
        this.isPaused = true;
        if(this.linearAdMediaProxy != null) this.linearAdMediaProxy.pause();
    }

    resume(){
        this.isPaused = false;
        if(this.linearAdMediaProxy != null) this.linearAdMediaProxy.resume();
    }

    combined(parts){
        let pauseAndResumControllerList = [];
        let cancelled = false;
        let abort = () => {
            cancelled = true;
        };
        let next = this.next;
        let pushPauseAndResumController = pauseAndResumController => {
            if(pauseAndResumController) pauseAndResumControllerList.push(pauseAndResumController);
        };
        let requestResum = () => {
            pauseAndResumControllerList.forEach(pauseAndResumController => {
                pauseAndResumController.requestResum();
            });
            pauseAndResumControllerList = [];
        };
        let removeListenStop = () => {
            this.off("Stop", abort);
        };
        this.one("Stop", abort);

        return {
            start: () => {
                return new Promise((resolve, reject) => {
                    if(this.lock == true){
                        this.queue = [];
                        parts.forEach(p => {
                            this.queue.push(p);
                        });
                        let error = new Error(`combined ads is interrupted because not unlocked yet.`);
                            error.isWarning = true;
                        reject(error);
                        return;
                    }

                    parts.reduce((pr, part) => {
                        return pr.then(pauseAndResumController => {
                            if(cancelled == true) return;
                            pushPauseAndResumController(pauseAndResumController);
                            let { partType, condition } = part.info;
                            return next(partType, condition);
                        });
                    }, Promise.resolve())
                    .then(pauseAndResumController => {
                        if(cancelled == false){
                            pushPauseAndResumController(pauseAndResumController);
                            requestResum();
                        }
                        removeListenStop();
                        resolve();
                    })
                    .catch(({ error, pauseAndResumController }) => {
                        if(cancelled == false) {
                            if(pauseAndResumController){
                                pushPauseAndResumController(pauseAndResumController);
                            }
                            requestResum();
                        }
                        removeListenStop();
                        if(error.isWarning){
                            console.warn(error.message);
                        }else{
                            console.error(error);
                        }
                        reject(error);
                    })
                });
            }
        };
    }

    getSupportive(){
        return SUPPORTIVE;
    }

    getCurrentExternalAdInfo(){
        return this.imaPack.getCurrentAdInfo();
    }

    getCurrentLinearAdElement(){
        return this.linearAdMediaData;
    }

    sendCurrentExternalAdInfo(appInfo, event){
        return this.report.postDebug(this.imaPack.getCurrentAdInfo(), this.linearAdMediaData, appInfo, event);
    }

    on(){
        super.on.apply(this, arguments);
        return this;
    }

    one(){
        super.one.apply(this, arguments);
        return this;
    }

    off(){
        super.off.apply(this, arguments);
        return this;
    }

    vastTest(vast, {doNotReplace = true, adSource = "debug"}){
        if(this.inLinearAdStream == true){
            this.stopLinearAd();
        }

        setTimeout(() => {
            this.inLinearAdStream = true;
            let pauseAndResumController = this.pauseAndResumController.getSub(MIDROLLS);
            this.linearAdMediaProxy = this.imaPack;
            this.imaPack.adsRequest({
                data: vast,
                isAdsResponse: /^\<[\s\S]+\>$/m.test(vast),
                enable_countdown: false,
                doNotReplace: doNotReplace,
                adSource: adSource
            })
            .onRequest((e, source) => {
                console.log("onRequest", e);
                this.trigger("AdRequest", {isLinear: true});
            })
            .onLoaded((e, source) => {
                console.log("onLoaded", e);
            })
            .onImpression((e, source) => {
                console.log("onImpression", e);
                this.trigger("Impression", {isLinear: true});
            })
            .onPauseRequested((e, source) => {
                console.log("onPauseRequested", e);
                pauseAndResumController.requestPause();
            })
            .onAdMediaStart((e, source) => {
                console.log("onAdMediaStart", e);
            })
            .onAdMediaComplete((e, source) => {
                console.log("onAdMediaComplete", e);
            })
            .onCompleted((e) => {
                console.log("onCompleted", e);
                this.inLinearAdStream = false;
                this.linearAdMediaProxy = null;
                pauseAndResumController.requestResum();
            })
            .onError((e, source) => {
                console.log(`onError|${e.error.code}|${e.error.message}`, e);
                e.isLinear = true;
                this.inLinearAdStream = false;
                pauseAndResumController.requestResum();
                this.trigger("Error", e);
            })
            .onRequestResume((e, source) => {
                console.log("onRequestResume", e);
                this.resume();
            })
            .onPause((e, source) => {
                console.log("onPause", e);
                this.trigger("AdPause", e);
            })
            .onResume((e, source) => {
                console.log("onResume", e);
                this.trigger("AdResume", e);
            })
            .onClick((e, source) => {
                console.log("onClick", e);
                this.trigger("AdClick", e);
            })
            // .onMuted((e, source) => {
            //     console.log("AdMuted", e);
            //     this.trigger("AdMuted", e);
            // })
            .onCompanionAd((e, source) => {
                this.trigger("CompanionAd", Object.assign({}, { adType: "companionAd", meta: e.meta }));
            })
            .onCompanionAdEnd((e, source) => {
                this.trigger("CompanionAdEnd", Object.assign({}, { adType: "companionAd" }));
            });
        });

    }

    get paused(){
        return this.isPaused;
    }
}

class VideoAdError extends ExtendableError {}
class ImageAdError extends ExtendableError {}

class EmptyContent{
    constructor(mediaType){
        this.mediaType = mediaType;
    }
}

export default function(player, ancillary){
    let $companionAd;
    let _isShow = false;
    let publishEventList = [];
    let collapseEventList = [];
    
    function init(player, ancillary){
        $companionAd = document.createElement("DIV");
        $companionAd.classList.add("ppn-companionAd");
        ancillary.appendChild($companionAd);
        player.on(player.EVENT.PUBLISH_COMPANION_AD, onCompanionAdPublish);
        player.on(player.EVENT.COLLAPSE_COMPANION_AD, onCompanionAdCollapse);
    }
    init(player, ancillary);
    
    function onCompanionAdPublish(e){
        if(e.adType !== "companionAd") return;
        let meta = e.meta;
        if(meta.size.width !== 300 || meta.size.height != 250) return;
        $companionAd.innerHTML = "";
        if(meta.resourceType == "HTML"){
            let iframe = document.createElement("iframe");
            iframe.width = "300";
            iframe.height = "250";
            $companionAd.appendChild(iframe);
            let iframeDoc = iframe.contentWindow.document;
            iframeDoc.open();
            iframeDoc.write(meta.resourceValue);
            iframeDoc.close();
        }else{
            $companionAd.innerHTML = meta.element;
        }
        $companionAd.classList.add("ppn-show");
        _isShow = true;
        publishEventList.forEach(fn => fn());
    }
    
    function onCompanionAdCollapse(e){
        if(e.adType !== "companionAd") return;
        if(_isShow === false) return;
        $companionAd.classList.remove("ppn-show");
        $companionAd.innerHTML = "";
        _isShow = false;
        collapseEventList.forEach(fn => fn());
    }
    
    function onPublish(listener){
        publishEventList.push(listener);
    }
    
    function onCollapse(listener){
        collapseEventList.push(listener);
    }

    return {
        onPublish,
        onCollapse,
        get isShow(){
            return _isShow;
        }
    };
}
import { GptSlotFacade, setUpGpt } from "./GptSlotFacade";
import { noop } from "./Util";

const GPT_SIZE = { width: 300, height: 250 };

export default async function GptImageAd(url, imageHost, viewportSensor) {
    const { container, uid, el: baseLayer } = imageHost;
    const infos = url.trim().split("|");
    const tags = infos[0].split(",").map(tag => tag.trim()).filter(Boolean);
    const size = JSON.parse((infos[1] || "0").trim()) || [GPT_SIZE.width, GPT_SIZE.height];
    const refreshTime = +infos[2];
    const slots = [];
    let stopRefresh = noop;

    setUpGpt();
    container.style.setProperty("--li-gpt-height", size[1]);
    imageHost.cleanGpt = cleanGpt(imageHost);

    await createBaseLayer();

    if (refreshTime > 0) {
        stopRefresh = createRefreshLayer();
    }
    container.classList.add("is_gpt");
    // return;

    function createBaseLayer() {
        return new Promise((resolve, reject) => {
            baseLayer.id = "gpt-base-layer";
            createGptWaterFlow(baseLayer, uid, resolve, error => reject({ error }))();
        });
    }

    function createRefreshLayer() {
        const refreshLayer = createDivElement(container, ["gpt_banner"], "gpt-refresh-layer");
        const onRendered = () => container.classList.add("use-refresh-layer");
        const onAllError = () => container.classList.remove("use-refresh-layer");
        const refresh = createGptWaterFlow(refreshLayer, uid + ":r", onRendered, onAllError);
        const refreshEvent = setInterval(() => viewportSensor.inViewPort && refresh(), refreshTime * 1e3);
        return () => {
            clearInterval(refreshEvent);
            refreshLayer.parentNode.removeChild(refreshLayer);
        };
    }

    function createGptWaterFlow(container, uid, onRefreshed, onError) {
        const facades = tags.map((tag, index) => {
            const el = createDivElement(container, ["gpt_container", "image_content"], `${uid}:${index}`);
            return new GptSlotFacade(el, tag, size);
        });
        const tmp = { onError };
        facades.reduce((previous, current) => {
            current.onSlotCreated = onSlotCreated;
            current.onRendered = onRendered;
            current.onError = onError;
            previous.onError = () => current.refresh();
            return current;
        }, tmp);
        return () => tmp.onError(); // would be facade[0].refresh

        function onSlotCreated(slot) {
            slots.push(slot);
        }
        function onRendered(facade) {
            facades.forEach(item => item.removeClass("refreshed"));
            facade.addClass("refreshed");
            onRefreshed();
        }
    }

    function cleanGpt(imageHost) {
        return function () {
            stopRefresh();
            GptSlotFacade.removeSlots(slots);
            container.classList.remove("use-refresh-layer");
            container.classList.remove("is_gpt");
            imageHost.cleanGpt = noop;
        };
    }

    function createDivElement(container, classList, id) {
        const el = container.appendChild(document.createElement("div"));
        el.id = id;
        classList.forEach(className => el.classList.add(className));
        return el;
    }
}

import { fetchPuid } from "../Api/Pusti.js";

export async function getPuid(exApi) {
    try {
        let { puid, expired } = getLocalPuidInfo();
        if (puid == null) {
            puid = getPuidByCookie();
        }
        if (puid == null) {
            puid = await exApi.getPuid();
        }
        if (expired) {
            puid = await fetchPuid(puid);
            setPuidCookie(puid);
            setLocalPuid(puid);
        }
        exApi.setPuid({ puid, isNew: expired });
        return puid;
    } catch (e) {
        return "";
    }
}

export function getLocalPuidInfo() {
    const errorInfo = { expired: true };
    try {
        let { puid, expire } = JSON.parse(localStorage.getItem("L_PUID"));
        return puid == null ? errorInfo : { puid, expired: +new Date() > expire };
    } catch (e) {
        return errorInfo;
    }
}

export function setLocalPuid(puid) {
    try {
        localStorage.setItem(
            "L_PUID",
            JSON.stringify({
                puid,
                expire: +new Date() + 72e5, //兩小時
            })
        );
    } catch (error) {}
}

function getPuidByCookie() {
    return document.cookie.match("(^|;)\\s*L_PUID\\s*=\\s*([^;]+)")?.pop() || null;
}

function setPuidCookie(puid) {
    let expireTime = new Date(Date.now() + 6048e5); // 七天
    document.cookie = "L_PUID=" + puid + ";expires=" + expireTime.toGMTString();
}

export default function (videojs) {
    const Component = videojs.getComponent("Component");
    const DISPLAY_TIME_DEFAULT = 1;
    class Toast extends Component {
        constructor(player, options = {}) {
            super(player, options);
        }
        createEl() {
            const el = videojs.dom.createEl("div", { className: "vjs-toast" });
            this.textEl = el.appendChild(videojs.dom.createEl("div", { className: "vjs-toast-text" }));
            this.iconEl = el.appendChild(videojs.dom.createEl("div", { className: "vjs-toast-icon" }));
            return el;
        }
        text(message, seconds = DISPLAY_TIME_DEFAULT) {
            if (this.dismissText) {
                clearTimeout(this.dismissText);
            }
            this.textEl.textContent = message;
            this.textEl.classList.add("vjs-show");
            this.dismissText = setTimeout(() => this.hideText(), seconds * 1000);
        }
        hideText() {
            if (this.dismissText) {
                clearTimeout(this.dismissText);
                this.dismissText = null;
            }
            this.textEl.classList.remove("vjs-show");
        }
        icon(type, seconds = DISPLAY_TIME_DEFAULT) {
            if (this.dismissIcon) {
                clearTimeout(this.dismissIcon);
            }
            this.iconEl.dataset.type = type;
            this.iconEl.classList.add("vjs-show");
            this.dismissIcon = setTimeout(() => this.hideIcon(), seconds * 1000);
        }
        iconHint(type, hint = "", seconds = DISPLAY_TIME_DEFAULT) {
            this.iconEl.dataset.hint = hint;
            this.icon(type, seconds);
        }
        hideIcon() {
            if (this.dismissIcon) {
                clearTimeout(this.dismissIcon);
                this.dismissIcon = null;
            }
            this.iconEl.classList.remove("vjs-show");
        }
    }

    Component.registerComponent("Toast", Toast);
}

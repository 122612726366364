import ObserverPattern from './ObserverPattern.js';
import AdsSessionChannel from './AdsSessionChannel.js';
import {LiAdError, LiAdErrorCodes} from './CustomError.js';
import {detectingPlatform} from './Util.js';
import getEidsMacroMap from './functional/getEidsMacroMap.js';

const TRIGGER_NAME = "IMA";
const ENABLE = "ENABLE";
const INSECURE = "INSECURE";
const LP_VPAIDMODE = "lp_vpaidMode";
const _detectingPlatform = detectingPlatform(); // to do: unify with platformInfo from player.

export default class ImaPack extends ObserverPattern{
    constructor(adBlockEnable, adContainer, contentPlayerProxy, platformInfo, requestTimeoutMS = 8E3, vpaidModePreset = ENABLE, vastClickArea, duplicateAdCheck){
        super();
        this.isOldWindows = _detectingPlatform.isXP || _detectingPlatform.isVista;
        this.isOnMobile = _detectingPlatform.isMobile;
        this.adsRequestSessions = [];
        this.volumeSetted = false;
        this.muted = false;
        this.volume = -1;
        this.isPaused = false;
        this.extraReplacement = [];
        this.autoPlayState = {
            autoplayAllowed: true,
            autoplayRequiresMuted: false
        };
        this.requestTimeoutMS = requestTimeoutMS;

        this.companionAdSize = null;
        this.puid = null;

        this.vpaidModePreset = vpaidModePreset;
        this.vpaidMode = vpaidModePreset;
        this.duplicateAdCheck = duplicateAdCheck;
        
        if(_detectingPlatform.supportLocalStorage == true && localStorage.getItem(LP_VPAIDMODE)){
            this.vpaidMode = localStorage.getItem(LP_VPAIDMODE);
        }

        adBlockEnable.then(r => {
            if(r == true){
                this.adsRequest = this.adsRequestWhenAdBlock;
            }
        });
        
        this.adContainer = adContainer;
        this.contentPlayerProxy = contentPlayerProxy;
        try{
            if(this.vpaidMode == INSECURE){
                google.ima.settings.setVpaidMode(google.ima.ImaSdkSettings.VpaidMode.INSECURE);
            }else if(this.vpaidMode == ENABLE){
                google.ima.settings.setVpaidMode(google.ima.ImaSdkSettings.VpaidMode.ENABLE);
            }
            google.ima.settings.setLocale('zh-TW');

            if (this.isOnMobile && vastClickArea == "player") {
                this.adDisplayContainer = new google.ima.AdDisplayContainer(adContainer, null, adContainer);
            } else {
                this.adDisplayContainer = new google.ima.AdDisplayContainer(adContainer);
            }
            this.adDisplayContainer.initialize(); //TODO 確認該在什麼時機使用
            this.onAdsManagerLoaded = this.onAdsManagerLoaded(contentPlayerProxy).bind(this);
            this.adsLoader = new google.ima.AdsLoader(this.adDisplayContainer);
            this.adsLoader.addEventListener(google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED, this.onAdsManagerLoaded, false);
            this.adsLoader.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, this.onAdsLoaderError.bind(this), false);
        }catch(e){
            this.adsRequest = this.adsRequestWhenAdBlock;
        }
        this.triggerUserAction = this.triggerUserAction();
    }
    
    onAdsManagerLoaded(videoContent){
        return function(adsManagerLoadedEvent){
            let adsRenderingSettings = new google.ima.AdsRenderingSettings();
            adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;
            adsRenderingSettings.bitrate = -1;
            adsRenderingSettings.enablePreloading = true;
            adsRenderingSettings.loadVideoTimeout = 8000;
            if(this.isOnMobile == true) adsRenderingSettings.mimeTypes = ["video/mp4"];
            
            let adsManager = adsManagerLoadedEvent.getAdsManager(videoContent, adsRenderingSettings);
            
            let w = this.adContainer.offsetWidth, h = this.adContainer.offsetHeight;
            let viewMode = videoContent.isFullscreen ? google.ima.ViewMode.FULLSCREEN : google.ima.ViewMode.NORMAL;
            
            let adsRequestSession = this.adsRequestSessions.find(item => !item.hasAdsManager);
            if(typeof adsRequestSession !== "undefined") {
                adsRequestSession.setAdsManager(adsManager, w, h, viewMode);
            }
        };
    }
    
    onAdsLoaderError(adErrorEvent) {
        //console.log("AdsLoaderError", adErrorEvent.getError(), adErrorEvent.getError().getErrorCode());
        let liAdError = new LiAdError(adErrorEvent.getError());
        this.adsRequestSessions[0] && this.adsRequestSessions[0].triggerChannel("Error", { error: liAdError }, TRIGGER_NAME); //TODO 確認 ima error 能不能 for in
        this.sessionComplete(adErrorEvent);
    }
    
    sessionComplete(adEvent){
        let adsRequestSession = this.adsRequestSessions.shift();
        if (this.adsRequestSessions.length > 0){
            this.adsRequestSessions[0].request();
        } else {
            this.adContainer.classList.remove("ad_impression");
        }
        adsRequestSession && adsRequestSession.destroy();
        //this.notifyContentComplete();
    }
    
    onPauseRequested(adEvent){
        this.adContainer.classList.add("ad_impression");
        if(this.adsRequestSessions.length == 1){ //TODO 這樣的判斷可能不恰當
            this.adsRequestSessions[0].triggerChannel("PauseRequested", adEvent, TRIGGER_NAME);
        }
    }
    
    onImpression(adEvent){
        this.adsRequestSessions[0].triggerChannel("Impression", adEvent, TRIGGER_NAME);
    }
    
    onAllAdsCompleted(adEvent){
        if(this.adsRequestSessions.length == 1){
            this.adsRequestSessions[0].triggerChannel("Completed", adEvent, TRIGGER_NAME);
        }
        this.sessionComplete(adEvent);
    }
    
    onAdsManagerError(adErrorEvent) {
        this.sessionComplete(adErrorEvent);
    }
    
    adsRequestWhenAdBlock(){
        let adsSessionChannel = new AdsSessionChannel();
        
        Promise.resolve().then(() => {
            let liAdError = new LiAdError(LiAdErrorCodes.codes.IMA_SDK_LOST);
            adsSessionChannel.trigger("Error", { error: liAdError }, TRIGGER_NAME);
            adsSessionChannel.destroy();
        })
        return adsSessionChannel.getChannel();
    }
    
    buildUpAdTagUrl(data){
        let timestamp = String(+new Date());
        let locationUrl = encodeURIComponent(window.self !== window.top ? document.referrer: document.URL);
        let useragent = encodeURIComponent(navigator.userAgent);
        
        let macroMap = Object.assign({
            timestamp: timestamp,
            CACHEBUSTING: timestamp,
            CACHE_BREAKER: timestamp,
            pageurl: locationUrl,
            referrer_url: locationUrl,
            description_url: locationUrl,
            urlencodedpageURL: locationUrl,
            EMBEDDING_PAGE_URL: locationUrl,
            urlencodedvideoURL: locationUrl,
            playerwidth: this.adContainer.offsetWidth,
            playerheight: this.adContainer.offsetHeight,
            osversion: encodeURIComponent(navigator.appVersion),
            ua: useragent,
            useragent: useragent,
            USERAGENT: useragent,
            puid: this.puid,
            PUID: this.puid,
            networkconnectiontype: "u",
            cellcarrier: "litv",
        }, getEidsMacroMap());
        
        let adTagUrl = data.replace(/\[(.*?)\]/g, (macro, key) => macroMap[key] || macro);
        this.extraReplacement.forEach(r => {
            adTagUrl = adTagUrl.replace(new RegExp(r.target, 'g'), r.newValue);
        });

        return adTagUrl;
    }

    //public
    
    notifyContentComplete(){
        if(this.adsLoader) this.adsLoader.contentComplete();
        return this;
    }
    
    adsRequest(data, excludeAdIds = []){
        this.notifyContentComplete();
        let adsRequest = new google.ima.AdsRequest();

        if (data.isAdsResponse) {
            adsRequest.adsResponse = data.data;
        } else if (data.doNotReplace) {
            adsRequest.adTagUrl = data.data;
        } else {
            adsRequest.adTagUrl = this.buildUpAdTagUrl(data.data);
        }
        //adsRequest.vastLoadTimeout = 
        
        if(!this.volumeSetted){
            this.muted = this.contentPlayerProxy.muted;
            this.volume = (this.muted ? 1 : this.contentPlayerProxy.volume) * 0.5;
            this.volumeSetted = true;
        }
        
        let volume = this.muted ? 0 : this.volume;
        let adsRequestSession = new AdsRequestSession(this.adsLoader, adsRequest, this.adContainer, data, volume, this.isPaused, excludeAdIds, this.autoPlayState, this.requestTimeoutMS, this.companionAdSize, this.puid, this.duplicateAdCheck);
        adsRequestSession.one("PauseRequested", this.onPauseRequested.bind(this));
        adsRequestSession.one("Impression", this.onImpression.bind(this));
        adsRequestSession.one("AllAdsCompleted", this.onAllAdsCompleted.bind(this));
        adsRequestSession.one("Error", this.onAdsManagerError.bind(this));
        
        this.adsRequestSessions.push(adsRequestSession);
        if(this.adsRequestSessions.length == 1){
            Promise.resolve().then(adsRequestSession.request);
        }
        
        return adsRequestSession.getChannel();
    }
    
    resize(){
        let adsRequestSession = this.adsRequestSessions[0];
        if(typeof adsRequestSession !== "undefined"){
            let w = this.adContainer.offsetWidth, h = this.adContainer.offsetHeight;
            let isFullScreen = this.contentPlayerProxy.isFullscreen && this.contentPlayerProxy.supportFullscreen;
            adsRequestSession.resize(w, h, isFullScreen ? google.ima.ViewMode.FULLSCREEN : google.ima.ViewMode.NORMAL);
        }
        return this;
    }
    
    pause(){
        this.isPaused = true;
        if(this.adsRequestSessions.length == 0) return;
        this.adsRequestSessions[0].pause();
    }
    
    resume(){
        this.isPaused = false;
        if(this.adsRequestSessions.length == 0) return;
        this.adsRequestSessions[0].resume();
    }
    
    stop(destroy){
        if(this.adsRequestSessions.length == 0) return;
        this.adsRequestSessions[0].stop();
        this.adsRequestSessions.forEach(adsRequestSession => {
            adsRequestSession.destroy();
        });
        this.adsRequestSessions = [];
        this.adContainer.classList.remove("ad_impression");

        if(destroy){
            this.adsLoader.destroy();
            this.adsLoader = null;

            this.adDisplayContainer.destroy();
            this.adDisplayContainer = null;
        }
    }
    
    mute(muted){
        if(muted === this.muted) return;
        this.muted = muted;
        if(this.adsRequestSessions.length == 0) return;
        if(muted == true){
            this.adsRequestSessions.forEach(ars => {
                ars.setVolume(0);
            });
        }else{
            if(this.volume == 0) this.volume = 0.001;
            this.adsRequestSessions.forEach(ars => {
                ars.setVolume(this.volume);
            });
        }
    }
    
    setVolume(volume){
        if(volume == 0){
            if(this.muted == true) return;
            volume = 0.001;
        }
        this.volume = volume;
        if(this.adsRequestSessions.length == 0) return;
        this.adsRequestSessions.forEach(ars => {
            ars.setVolume(volume);
        });
    }
    
    getVolume(){
        if(this.adsRequestSessions.length == 0) return this.volume;
        return this.adsRequestSessions[0].getVolume();
    }
    
    triggerUserAction(){
        let exed = false;
        return function(){
            if(exed == true) return;
            exed = true;
            this.autoPlayState.autoplayAllowed = true;
            this.autoPlayState.autoplayRequiresMuted = false;
            let videoList = Array.from(this.adContainer.querySelectorAll("video"));
            let playPromiseList = videoList.map(v => v.play()).filter(x => Boolean(x));
            playPromiseList.forEach(p => p.catch(e => console.warn(e)));
        }
    }
    
    setExcludeAdIds(excludeAdIds){
        return {
            adsRequest: (data) => {
                return this.adsRequest(data, excludeAdIds);
            },
            pause: this.pause.bind(this),
            resume: this.resume.bind(this)
        };
    }
    
    setReplacement(urlReplacement = []){
        if(Array.isArray(urlReplacement)){
            this.extraReplacement = urlReplacement;
        }else{
            this.extraReplacement = [];
        }
    }

    setAutoPlayState(state){
        this.autoPlayState = state;
    }

    getCurrentAdInfo(){
        if(this.adsRequestSessions.length > 0){
            return this.adsRequestSessions[0].getAdInfo();
        }
        return null;
    }

    setCompanionAdSize(sizeObj){
        this.companionAdSize = sizeObj;
    }

    setPuid(puid){
        this.puid = puid;
    }

    setVpaidModeToInsecure(){
        this.vpaidMode = INSECURE;
        _detectingPlatform.supportLocalStorage && localStorage.setItem(LP_VPAIDMODE, INSECURE);
        google.ima.settings.setVpaidMode(google.ima.ImaSdkSettings.VpaidMode.INSECURE);
    }

    setVpaidModeToEnable(){
        this.vpaidMode = ENABLE;
        _detectingPlatform.supportLocalStorage && localStorage.setItem(LP_VPAIDMODE, ENABLE);
        google.ima.settings.setVpaidMode(google.ima.ImaSdkSettings.VpaidMode.ENABLE);
    }

    setVpaidModeToPreset(){
        this.vpaidMode = this.vpaidModePreset;
        _detectingPlatform.supportLocalStorage && localStorage.removeItem(LP_VPAIDMODE);
        if(this.vpaidMode == ENABLE){
            google.ima.settings.setVpaidMode(google.ima.ImaSdkSettings.VpaidMode.ENABLE);
        }else if(this.vpaidMode == INSECURE){
            google.ima.settings.setVpaidMode(google.ima.ImaSdkSettings.VpaidMode.INSECURE);
        }
        
    }

    getVpaidMode(){
        return this.vpaidMode;
    }
}

class AdsRequestSession extends ObserverPattern {
    constructor(adsLoader, adsRequest, adContainer, data, volume, isPaused, excludeAdIds, autoPlayState, requestTimeoutMS, companionAdSize, puid, duplicateAdCheck){
        super();
        this.adsLoader = adsLoader;
        this.adsRequest = adsRequest;
        this.adContainer = adContainer;
        this.enableCountdown = data.enable_countdown;
        this.isInteractive = data.isInteractive;
        this.adsManager = null;
        this.request = this.request.bind(this);
        this.adInfo = null;
        this.onClickSkip = this.onClickSkip.bind(this);
        this.requestResume = this.requestResume.bind(this);
        this.adsSessionChannel = new AdsSessionChannel();
        this.volume = volume;
        this.isPaused = isPaused;
        this.excludeAdIds = excludeAdIds;
        this.isStarted = false;
        this.width = 0;
        this.height = 0;
        this.viewMode = google.ima.ViewMode.NORMAL;
        this.adSource = (data.adSource||"").toUpperCase();
        this.autoPlayState = autoPlayState;
        this.companionAdSize = companionAdSize;
        this.puid = puid;
        this.requestTimeoutMS = requestTimeoutMS;
        this.timeoutId = null;
        this.handlePausedTimeoutId = null;
        this.duplicateAdCheck = duplicateAdCheck;
        //if(this.adSource == "FB") google.ima.settings.setVpaidMode(google.ima.ImaSdkSettings.VpaidMode.INSECURE);
        //else google.ima.settings.setVpaidMode(google.ima.ImaSdkSettings.VpaidMode.ENABLE);
        
        let managerEvents = {};
        managerEvents[google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED] = this.onPauseRequested.bind(this);
        managerEvents[google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED] = this.onResumeRequested.bind(this);
        managerEvents[google.ima.AdEvent.Type.IMPRESSION] = this.onImpression.bind(this);
        managerEvents[google.ima.AdEvent.Type.ALL_ADS_COMPLETED] = this.onAllAdsCompleted.bind(this);
        managerEvents[google.ima.AdErrorEvent.Type.AD_ERROR] = this.onAdsManagerError.bind(this);
        managerEvents[google.ima.AdEvent.Type.STARTED] = this.onStarted.bind(this);
        managerEvents[google.ima.AdEvent.Type.LOADED] = this.onLoaded.bind(this);
        managerEvents[google.ima.AdEvent.Type.CLICK] = this.onClick.bind(this);
        managerEvents[google.ima.AdEvent.Type.AD_BREAK_READY] = null;
        managerEvents[google.ima.AdEvent.Type.AD_METADATA] = null;
        managerEvents[google.ima.AdEvent.Type.COMPLETE] = this.onAdCompleted.bind(this);
        managerEvents[google.ima.AdEvent.Type.DURATION_CHANGE] = null;
        managerEvents[google.ima.AdEvent.Type.FIRST_QUARTILE] = null;
        managerEvents[google.ima.AdEvent.Type.INTERACTION] = null;
        managerEvents[google.ima.AdEvent.Type.LINEAR_CHANGED] = null;
        managerEvents[google.ima.AdEvent.Type.LOG] = null;
        managerEvents[google.ima.AdEvent.Type.MIDPOINT] = null;
        managerEvents[google.ima.AdEvent.Type.PAUSED] = this.onPuase.bind(this);
        managerEvents[google.ima.AdEvent.Type.RESUMED] = this.onResume.bind(this);
        managerEvents[google.ima.AdEvent.Type.SKIPPABLE_STATE_CHANGED] = null;
        managerEvents[google.ima.AdEvent.Type.SKIPPED] = null;
        managerEvents[google.ima.AdEvent.Type.THIRD_QUARTILE] = null;
        managerEvents[google.ima.AdEvent.Type.USER_CLOSE] = null;
        managerEvents[google.ima.AdEvent.Type.VOLUME_CHANGED] = this.onVolumeChanged.bind(this);
        managerEvents[google.ima.AdEvent.Type.VOLUME_MUTED] = null;
        
        this.managerEvents = this.setUpManagerEvents(managerEvents, this.decorationEventListener);
        
        this.checkRemainingTimeId = null;
    }
    
    onPauseRequested(){
        let exed = false; //確保只被執行一次。 //TODO 抽象化
        return function(adEvent){
            if(exed) return;
            this.terminationTimeout();
            let duration = ["getAd", "getDuration"].reduce((p, c) => {
                if(p !== -1 && p && p[c]) return p[c]();
                return -1;
            }, adEvent);
            this.trigger("PauseRequested", {innerEvent: adEvent});
            this.triggerChannel("AdMediaStart", {duration: duration}, TRIGGER_NAME);
            exed = true;
        }.bind(this);
    }
    
    onResumeRequested(){
        return function(adEvent) {
            this.managerEvents[google.ima.AdEvent.Type.COMPLETE](adEvent);

            // workaround: fire ALL_ADS_COMPLETED event due to vpaid bug.
            let onAllAdsCompleted = this.managerEvents[google.ima.AdEvent.Type.ALL_ADS_COMPLETED];
            setTimeout(() => onAllAdsCompleted(adEvent), 1e3);
        }.bind(this);
    }

    onAdCompleted(){
        return function(adEvent){
            if (this.adCompleted) {
                return;
            }
            this.adCompleted = true;
            this.triggerChannel("AdMediaComplete", {}, TRIGGER_NAME);
        }.bind(this);
    }
    
    onImpression(){
        return function(adEvent){
            this.trigger("Impression", {innerEvent: adEvent});
        }.bind(this);
    }
    
    onAllAdsCompleted(){
        return function(adEvent){
            if (this.allAdCompleted) {
                return;
            }
            this.allAdCompleted = true;
            this.terminationTimeout();
            this.stopCheckRemaining();
            this.trigger("AllAdsCompleted", {innerEvent: adEvent});
        }.bind(this);
    }
    
    onAdsManagerError() {
        return function(adErrorEvent){
            this.terminationTimeout();
            this.stopCheckRemaining();
            //console.log("AdsManagerError", adErrorEvent.getError(), adErrorEvent.getError().getErrorCode());
            let liAdError = new LiAdError(adErrorEvent.getError());
            this.triggerError(liAdError);
        }.bind(this);
    }
    
    onStarted(){
        let exed = false; //確保只被執行一次。 //TODO 抽象化
        return function(adEvent){
            if(exed) return;
            //this.terminationTimeout();
            let imaAd = adEvent.getAd();
            let sizes = this.companionAdSize;

            if(sizes && Array.isArray(sizes)){
                let selectionCriteria = new google.ima.CompanionAdSelectionSettings();
                let regExp = /<div id="adaptvCompanion\d+x\d+" style="display:none;"><\/div>/g;
                let hasCompanionAds = false;

                selectionCriteria.resourceType = google.ima.CompanionAdSelectionSettings.ResourceType.ALL;
                selectionCriteria.creativeType = google.ima.CompanionAdSelectionSettings.CreativeType.ALL;
                selectionCriteria.sizeCriteria = google.ima.CompanionAdSelectionSettings.SizeCriteria.SELECT_EXACT_MATCH;

                let adUnit, matchAdUnit = this.adsRequest.adTagUrl.match(/iu\=([\w\/]+)/i);
                if(matchAdUnit){
                    adUnit = matchAdUnit[1];
                }else{
                    adUnit = "";
                }

                for(let i = 0; i < sizes.length; i ++){
                    let size = sizes[i];
                    let companionAds = imaAd.getCompanionAds(size.width, size.height, selectionCriteria);

                    companionAds = companionAds[0];

                    if(!companionAds){
                        continue;
                    }

                    if(regExp.test(companionAds.getContent())){
                        regExp.lastIndex = 0;
                        continue;
                    }

                    let resourceType, resourceValue;
                    for(let k in companionAds){
                        if(!companionAds.hasOwnProperty(k)) continue;
                        if(typeof companionAds[k] === "object"){
                            if("resourceType" in companionAds[k]){
                                resourceType = companionAds[k].resourceType.toUpperCase();
                            }
                            if("resourceValue" in companionAds[k]){
                                resourceValue = companionAds[k].resourceValue;
                            }
                        }
                    }

                    if(_detectingPlatform.isIE && resourceType == "HTML"){
                        continue;
                    }

                    let meta = {
                        element: companionAds.getContent(),
                        size: size,
                        adUnit: adUnit,
                        resourceType: resourceType,
                        resourceValue: resourceValue
                    };

                    this.triggerChannel("CompanionAd", { meta: meta }, TRIGGER_NAME);

                    hasCompanionAds = true;

                    break;
                }

                if(!hasCompanionAds){
                    this.triggerChannel("CompanionAdEnd", TRIGGER_NAME);
                }
            }

            if(!this.enableCountdown) return;

            if(imaAd.isLinear()){
                //this.resize(this.width, this.height, this.viewMode);
                this.triggerChannel("CountdownStart", { clickFunction: this.onClickSkip });
                let adsManager = this.adsManager;
                getCountStart()
                    .then(getNextTick)
                    .then(time => {
                        this.triggerChannel("CountdownProgress", { time })
                        this.checkRemainingTimeId = setInterval(() => countdownProgress.apply(this), 500);
                    })
                    .catch(() => {});
                
                function getCountStart() {
                    let time = adsManager.getRemainingTime() >> 0;
                    return time > 0 ? Promise.resolve(time) : new Promise((resolve, reject) => {
                        let tried = 0;
                        let eventId = setInterval(() => {
                            time = adsManager.getRemainingTime() >> 0;
                            if (time > 0) {
                                clearInterval(eventId);
                                resolve(time);
                            } else if (++tried > 3) {
                                clearInterval(eventId);
                                reject();
                            }
                        }, 500);
                    });
                }
                function getNextTick(countStart) {
                    return new Promise((resolve, reject) => {
                        let tried = 0;
                        let eventId = setInterval(() => {
                            let time = adsManager.getRemainingTime() >> 0;
                            if (countStart - time == 1) {
                                clearInterval(eventId);
                                resolve(time);
                            } else if (++tried > 3) {
                                clearInterval(eventId);
                                reject();
                            }
                        }, 500);
                    });
                }
                function countdownProgress(){
                    let remainingTime = this.adsManager.getRemainingTime() >> 0;
                    if(remainingTime > -1){
                        this.triggerChannel("CountdownProgress", { time: remainingTime });
                    }
                }
            }
            exed = true;
        }.bind(this);
    }
    
    onLoaded(){
        return function(adEvent){
            let adId = adEvent.getAd().getAdId() + "##" + adEvent.getAd().getCreativeId();
            
            // if((this.adSource != "AOL" && this.adSource != "APPNEXUS")&& this.excludeAdIds.includes(adId)){
            if(this.duplicateAdCheck && this.excludeAdIds.length > 0 && this.excludeAdIds[this.excludeAdIds.length - 1] == adId){
                let liAdError = new LiAdError(LiAdErrorCodes.codes.DUPLICATE_AD);
                this.triggerError(liAdError);
                return;
            }
            this.adsManager.resize(this.width, this.height, this.viewMode);
            this.adsManager.setVolume(this.volume);
            if(this.isPaused) {
                this.adsManager.pause();
                this.terminationTimeout();  
            // }else if(this.adSource !== "AOL" && this.adSource !== "APPNEXUS"){
            } else {
                this.resume();
            }
            
            this.adInfo = this.exportAdInfo(adEvent.getAd());
            this.triggerChannel("Loaded", { adId: adId, meta: this.adInfo, innerEvent: adEvent }, TRIGGER_NAME);
        }.bind(this);
    }
    
    onClick(){
        return function(adEvent){
            this.triggerChannel("Click", {innerEvent: adEvent}, TRIGGER_NAME);
        }.bind(this);
    }
    
    onPuase(){
        return function(adEvent){
            let adDuration = adEvent.getAd().getDuration();
            let remainingTime = this.adsManager.getRemainingTime();
            let d = 0;
            //2023/01/19 當廣告接近結束時，pause 然後 resume 會導致 Yahoo Ad Manager Plus 的 VPAID (vista.js) 發生 Error。所以增加以下判斷（前三項）。
            if( Math.abs(remainingTime) < 0.4 || 
                Math.abs(adDuration - remainingTime) < 0.4 || 
                remainingTime > adDuration || 
                _detectingPlatform.isIOS
            ){
                d = 1000;
            }

            this.handlePausedTimeoutId = setTimeout(() => {
                if(!this.adContainer) return;
                this.adContainer.addEventListener("click", this.requestResume, true);
                this.triggerChannel("Pause", {innerEvent: adEvent, isInteractive: this.isInteractive}, TRIGGER_NAME);
            }, d);
        }.bind(this);
    }
    
    onResume(){
        return function(adEvent){
            this.adContainer.removeEventListener("click", this.requestResume, true);
            this.triggerChannel("Resume", {innerEvent: adEvent}, TRIGGER_NAME);
        }.bind(this);
    }

    onVolumeChanged(){
        return function(adEvent){
            let oriVolume = this.volume;
            this.volume = null;
            this.setVolume(oriVolume);
            // if(oriVolume == 0 && volume != 0){
            //     this.triggerChannel("Muted", {muted: false, innerEvent: adEvent}, TRIGGER_NAME);
            // }else if(volume == 0 && oriVolume != 0){
            //     this.triggerChannel("Muted", {muted: true, innerEvent: adEvent}, TRIGGER_NAME);
            // }
        }.bind(this);
    }
    
    onClickSkip(){
        this.triggerChannel("ClickSkip", {}, TRIGGER_NAME);
    }

    clearHandlePausedTimeout(){
        if(this.handlePausedTimeoutId != null){
            clearTimeout(this.handlePausedTimeoutId);
            this.handlePausedTimeoutId = null;
        }
    }
    
    requestResume(e){
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        this.triggerChannel("RequestResume", {}, TRIGGER_NAME);
    }
    
    exportAdInfo(adInstance){
        for(let k in adInstance){
            if(!adInstance.hasOwnProperty(k)) continue;
            if(typeof adInstance[k] === "object"){
                if("adId" in adInstance[k]){
                    return Object.assign({}, adInstance[k]);
                }
            }
        }
    }
    
    decorationEventListener(fn){
        if(typeof fn === "function") fn = fn();
        return function(adEvent){
            //console.log(adEvent.type);
            if(typeof fn === "function") fn.apply(this, arguments);
        };
    }
    
    setUpManagerEvents(managerEvents, decorator){
        let _managerEvents = {};
        Object.keys(managerEvents).forEach(k => {
            _managerEvents[k] = decorator(managerEvents[k]).bind(this);
        });
        return _managerEvents;
    }
    
    triggerChannel(type, ...publication){
        this.adsSessionChannel.trigger(type, ...publication);
    }
    
    triggerError(liAdError){
        this.triggerChannel("Error", { error: liAdError }, TRIGGER_NAME);
        this.trigger("Error", { error: liAdError });
    }
    
    getChannel(){
        return this.adsSessionChannel.getChannel();
    }
    
    setTerminationTime(){
        if(this.requestTimeoutMS < 0) return;
        this.timeoutId = setTimeout(() => {
            if(this.adsManager != null) {
                this.adsManager.stop();
            }
            let liAdError = new LiAdError(LiAdErrorCodes.codes.CUSTOM_TIMEOUT);
            this.triggerError(liAdError);
        }, this.requestTimeoutMS);
    }

    terminationTimeout(){
        if(this.timeoutId != null){
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
    }

    stopCheckRemaining(){
        if(this.checkRemainingTimeId != null){
            clearTimeout(this.checkRemainingTimeId);
            this.checkRemainingTimeId = null;
        }
        this.triggerChannel("CountdownEnd");
    }

    request(){
        if(this.isOldWindows == true && (this.adSource == "AOL" || this.adSource == "APPNEXUS")){ //TODO 測試 AppNexus 是否需要排除於 old windows 
            let liAdError = new LiAdError(LiAdErrorCodes.codes.OS_TOO_OLD);
            this.triggerError(liAdError);
            return this;
        }
        let w = this.adContainer.offsetWidth, h = this.adContainer.offsetHeight;
        this.adsRequest.linearAdSlotWidth = w;
        this.adsRequest.linearAdSlotHeight = h;
        this.adsRequest.nonLinearAdSlotWidth = w;
        this.adsRequest.nonLinearAdSlotHeight = h;
        this.adsRequest.setAdWillAutoPlay(this.autoPlayState.autoplayAllowed);
        this.adsRequest.setAdWillPlayMuted(this.autoPlayState.autoplayRequiresMuted);
        this.adsLoader.requestAds(this.adsRequest);

        this.setTerminationTime();
        this.triggerChannel("Request", {requestInfo: this.adsRequest}, TRIGGER_NAME);
        return this;
    }
    
    setAdsManager(adsManager, width, height, viewMode){
        if(this.adsManager != null) throw "Repeat execution";
        
        for(let eventType in this.managerEvents){
            if(!this.managerEvents.hasOwnProperty) continue;
            adsManager.addEventListener(eventType, this.managerEvents[eventType], false);
        }
        adsManager.init(width, height, viewMode);
        this.width = width;
        this.height = height;
        this.viewMode = viewMode;
        this.adsManager = adsManager;
        // if(!this.isPaused && (this.adSource == "AOL" || this.adSource == "APPNEXUS")) {
        //     this.resume();
        // }
        return this;
    }
    
    resize(width, height, viewMode){
        this.width = width;
        this.height = height;
        this.viewMode = viewMode;
        if(this.adsManager != null) {
            this.adsManager.resize(width, height, viewMode);
        }
        return this;
    }
    
    pause(){
        this.isPaused = true;
        if(this.isStarted == true) {
            this.adsManager.pause();
        }
    }
    
    resume(){
        this.isPaused = false;
        if (this.isStarted) {
            this.adsManager.resume();
            this.triggerChannel("Resume", {}, TRIGGER_NAME);
        } else if (this.adsManager) {
            this.isStarted = true;
            this.adsManager.start();
        }
    }
    
    stop(){
        if(this.adsManager != null) {
            this.adsManager.stop();
            this.triggerChannel("Abort");
        }
        return this;
    }
    
    setVolume(volume){
        if(this.volume == volume) return this;
        this.volume = volume;
        if(this.adsManager != null){
            this.adsManager.setVolume(volume);
        }
        return this;
    }
    
    getVolume(){
        if(this.adsManager != null){
            return this.adsManager.getVolume();
        }
        return this.volume;
    }

    getAdInfo(){
        return this.adInfo;
    }
    
    destroy(){
        this.terminationTimeout();
        this.stopCheckRemaining();
        this.clearHandlePausedTimeout();
        this.events = {};
        if(this.adsManager != null){
            for(let eventType in this.managerEvents){
                if(!this.managerEvents.hasOwnProperty) continue;
                this.adsManager.removeEventListener(eventType, this.managerEvents[eventType], false);
            }
            this.adsManager.setVolume(0);
            this.adsManager.discardAdBreak();
            this.adsManager.destroy();
            this.adsManager = null;
        }
        if(this.adsSessionChannel != null){
            this.adsSessionChannel.destroy();
            this.adsSessionChannel = null;
        }
        if(this.adContainer != null){
            this.adContainer.removeEventListener("click", this.requestResume, true);
            this.adContainer = null;
        }
        this.managerEvents = null;
        this.adsLoader = null;
        this.adsRequest = null;
        this.adInfo = null;
    }

    get hasAdsManager(){
        return this.adsManager != null;
    }
    
}
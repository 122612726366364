export default function (videojs) {
    const Component = videojs.getComponent("Component");
    const ClickableComponent = videojs.getComponent("ClickableComponent");

    class Caption extends ClickableComponent {
        constructor(player, options = {}) {
            super(player, options);
        }
        createEl() {
            const el = videojs.dom.createEl("a", { className: "vjs-caption" });
            this.titleEl = el.appendChild(videojs.dom.createEl("p", { className: "caption-title" }));
            this.subtitleEl = el.appendChild(videojs.dom.createEl("p", { className: "caption-subtitle" }));
            return el;
        }
        update({ text, link, title, subtitle }) {
            title = title || text;
            if (title) {
                this.setText(title, subtitle);
                link ? this.setLink(link) : this.removeLink();
            } else {
                this.removeText();
                this.removeLink();
            }
        }
        setText(title, subtitle) {
            this.removeClass("hide");
            this.titleEl.innerText = title || "";
            this.subtitleEl.innerText = subtitle || "";
        }
        removeText() {
            this.addClass("hide");
            this.titleEl.innerText = "";
            this.subtitleEl.innerText = "";
        }
        setLink(link) {
            this.addClass("link");
            this.setAttribute("href", link);
            this.setAttribute("target", "_blank");
        }
        removeLink() {
            this.removeClass("link");
            this.setAttribute("href", "javascript:void(0);");
            this.setAttribute("target", "_self");
        }
    }

    Component.registerComponent("Caption", Caption);
}

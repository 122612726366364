import calenderIcon from "@images/epg/calender.svg";
import replayIcon from "@images/epg/replay.svg";
import playIcon from "@images/epg/play.svg";
import Event from "./Event";
import { traceScheduleProgram, updateCurrentItem, getProgramTitle, toMilliseconds, toDayStartTimestamp } from "./utils";
import { getElement, noop } from "../Util/Util";
import { DAY_IN_SECONDS } from "./constant";

const VOD_SCHEDULE_SCROLL_OFFSET = 56;
const LIVE_SCHEDULE_SCROLL_OFFSET = 56 + 36; // menu header's height + Date row's

export default function Schedule(root, meta, eventCenter) {
    const container = getElement(root, ".channel-schedule-wrapper");
    const header = getElement(container, ".menu-header");
    const schedule = getElement(container, ".channel-schedule");
    const backIcon = getElement(container, ".back-icon");

    let internalChannelIndex = null;
    let onclickDelegate = noop;
    schedule.onclick = (event) => onclickDelegate(event);

    meta.channelList.length <= 1
        ? backIcon.parentElement.removeChild(backIcon)
        : (backIcon.onclick = () => eventCenter.trigger(Event.EpgChannel));

    eventCenter.on(Event.EpgSchedule, open);
    eventCenter.on(Event.EpgChannel, close);
    eventCenter.on(Event.EpgClose, close);

    function open({ channelIndex }) {
        clean();
        internalChannelIndex = channelIndex;

        const channel = meta.channelList[channelIndex];
        header.dataset.title = channel.title;
        if (channel.selectable) {
            schedule.classList.add("selectable");
            onclickDelegate = onclickSelectable;
        } else {
            schedule.classList.remove("selectable");
            onclickDelegate = noop;
        }
        channel.isVod ? createVodSchedule(schedule, channel) : createLiveSchedule(schedule, channel);
        eventCenter.on(Event.ProgramChange, onProgramChange);
    }

    function createVodSchedule(domEl, channel) {
        domEl.dataset.type = "vod";
        domEl.innerHTML = channel.schedule.map(vodProgramMap).join("");
        domEl.scrollTop = updateCurrentProgram(channel.programIndex).offsetTop - VOD_SCHEDULE_SCROLL_OFFSET;
    }

    function createLiveSchedule(domEl, channel) {
        domEl.dataset.type = "live";
        domEl.innerHTML = splitScheduleByDate(channel.schedule).map(scheduleChunkMapper()).join("");
        domEl.scrollTop = updateCurrentProgram(channel.programIndex).offsetTop - LIVE_SCHEDULE_SCROLL_OFFSET;
    }

    function close() {
        clean();
        eventCenter.off(Event.ProgramChange, onProgramChange);
    }

    function clean() {
        schedule.innerHTML = "";
    }

    function onProgramChange({ channelIndex, programIndex }) {
        if (channelIndex == internalChannelIndex) {
            updateCurrentProgram(programIndex);
        }
    }

    function updateCurrentProgram(programIndex) {
        return updateCurrentItem(schedule, `.program[data-index="${programIndex}"]`);
    }

    function onclickSelectable(event) {
        let program = traceScheduleProgram(event.target);
        if (program != null) {
            eventCenter.trigger(Event.PLAY, {
                channelIndex: internalChannelIndex,
                programIndex: +program.dataset.index,
            });
        }
    }
}

function splitScheduleByDate(schedule) {
    const today = toDayStartTimestamp(Date.now() / 1e3);
    const tomorrow = today + DAY_IN_SECONDS;
    let nextTimeStamp = toDayStartTimestamp(schedule[0].startTime);
    let chunks = [];
    let programs = [];
    schedule.forEach((program) => {
        if (program.startTime < nextTimeStamp) {
            programs.push(program);
        } else {
            programs = [program];
            chunks.push({ date: getDateString(nextTimeStamp), programs });
            nextTimeStamp += DAY_IN_SECONDS;
        }
    });
    return chunks;

    function getDateString(timestamp) {
        let date = new Date(toMilliseconds(timestamp));
        return (timestamp == today ? ["今日"] : timestamp == tomorrow ? ["明日"] : [])
            .concat(getMonthDay(date), getWeekDay(date))
            .join(" / ");
    }
    function getMonthDay(date) {
        return `${date.getMonth() + 1}月${date.getDate()}日`;
    }
    function getWeekDay(date) {
        return "星期" + ["日", "一", "二", "三", "四", "五", "六"][date.getDay()];
    }
}

function vodProgramMap(program, index) {
    let title = getProgramTitle(program);
    return `<div class="program" data-index=${index}><span>${title}</span>${playIcon}${replayIcon}</div>`;
}

function scheduleChunkMapper() {
    let programIndex = 0;
    return function (chunk) {
        const programSections = chunk.programs.map((program) => programMapper(program, programIndex++)).join("");
        return `<div class="schedule-chunk"><div class="date">${calenderIcon}${chunk.date}</div>${programSections}</div>`;
    };
}

function programMapper(program, index) {
    let timeSection = `<div class="time">${formatTime(program.startTime)}</div>`;
    let titleSection = `<div class="title"><span>${getProgramTitle(program)}</span>${playIcon}${replayIcon}</div>`;
    return `<div class="program" data-index=${index}>${timeSection}${titleSection}</div>`;
}

function formatTime(timestamp) {
    let date = new Date(toMilliseconds(timestamp));
    return padZero(date.getHours()) + ":" + padZero(date.getMinutes());
}

function padZero(num) {
    return String(num).padStart(2, "0");
}

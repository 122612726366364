import { getLineup, getSchedule, GetVideoUrl } from "../Api/PpnCdi";
import { getAssets, logoAdPart, logoAdElement } from "../Util/Util";
import Event from "./Event";
import { getProgramTitle, toDayStartTimestamp } from "./utils";
import { onAirSrcParserMap, vodSrcBase } from "./channelSrcParser";
import { CONTENT_TYPE_CHANNEL, CONTENT_TYPE_VOD_CHANNEL, DAY_IN_SECONDS } from "./constant";

async function fetchScheduleList(channelList) {
    const startTime = toDayStartTimestamp(((Date.now() / 1e3) | 0) - DAY_IN_SECONDS);
    const endTime = startTime + 3 * DAY_IN_SECONDS;
    return Promise.all(channelList.map(({ cdnCode }) => getSchedule(cdnCode, startTime, endTime).catch(() => null)));
}

async function fetchLiAd(partnerName, liadId) {
    return getAssets(`/meta/acs/${partnerName}/${liadId}.json`).catch(() => ({}));
}

export default class ChannelListMeta {
    constructor(config, eventCenter) {
        this.sponsorName = config.sponsorName;
        this.epgStyle = config.playlistUi;
        this.channel = {};
        this.sourceInfo = {};
        this.liAdMap = {};
        this.eventCenter = eventCenter;
        this.getVideoUrl = GetVideoUrl(config);
        this.init = this.fetchJson(config);

        this.videoType = "F";
        this.season = "0";
        this.episode = "0";
    }

    async next(contentInfo) {
        this.next = (newInfo) => (contentInfo = newInfo);
        await this.init;
        this.next = this.commonNext;
        return await this.commonNext(contentInfo);
    }

    async commonNext(contentInfo) {
        if (contentInfo == null) {
            // assert: !this.channel.isLive
            this.switchProgram((this.channel.programIndex + 1) % this.channel.schedule.length);
        } else {
            this.switchChannel(contentInfo.channelIndex);
            this.switchProgram(contentInfo.programIndex);
        }
        let { assetId, mediaType } = this.sourceInfo;
        this.sourceInfo.src = await this.getVideoUrl(assetId, mediaType);
        return this.sourceInfo;
    }

    getChannelIndex(id) {
        return this.channelList.findIndex(({ cdnCode }) => cdnCode == id);
    }

    switchChannel(channelIndex) {
        this.channelIndex = channelIndex;
        this.channel = this.channelList[channelIndex];
        this.eventCenter.trigger(Event.ChannelChange);
    }

    switchProgram(programIndex) {
        const channel = this.channel;
        if (programIndex != null) {
            // assert: channel.contentType != "channel" && channel.schedule != null
            this.sourceInfo = vodSrcBase(channel, channel.schedule[programIndex]);
        } else {
            programIndex = channel.onAir;
            this.sourceInfo = onAirSrcParserMap[channel.contentType](channel, Date.now() / 1e3);
        }
        channel.programIndex = programIndex;
        this.eventCenter.trigger(Event.ProgramChange, { channelIndex: this.channelIndex, programIndex });
    }

    async fetchJson(config) {
        const { refPartnerName: partnerName, liadId } = config;
        let channelList = await getLineup(partnerName);
        let [scheduleList, liad] = await Promise.all([fetchScheduleList(channelList), fetchLiAd(partnerName, liadId)]);

        channelList.forEach((channel, index) => {
            // assert: channel.selectable == channel.selectable && channel.contentType != "channel"
            channel.isLive = channel.contentType == CONTENT_TYPE_CHANNEL;
            channel.isVod = channel.contentType == CONTENT_TYPE_VOD_CHANNEL;
            appendScheduleInfo(channel, scheduleList[index]);
        });

        channelList = channelList.filter((channel) => channel.schedule != null || channel.isLive);
        if (channelList.length == 0) {
            throw new Error("no channel");
        }

        this.channelList = channelList;
        this._liad = liad;
        OnAirUpdater(this);
    }

    get mediaMode() {
        return this.sourceInfo.mediaMode;
    }

    get assetId() {
        return this.sourceInfo.assetId;
    }

    get mediaType() {
        return this.sourceInfo.mediaType;
    }

    get contentId() {
        return this.sourceInfo.contentId || this.channel.cdnCode;
    }

    get contentType() {
        return this.sourceInfo.contentType;
    }

    get liad() {
        try {
            return appendLogoToLiad(this.channel, this.sponsorName, this._liad);
        } catch (e) {
            return {};
        }
    }

    get program() {
        if (!this.channel.schedule) {
            return {};
        }
        return this.channel.schedule[this.channel.programIndex];
    }

    get title() {
        return this.channel.title;
    }

    get secondaryTitle() {
        return getProgramTitle(this.program);
    }

    get caption() {
        return { title: this.title, subtitle: this.secondaryTitle };
    }

    get midrollInterval() {
        return this.channel.isLive ? this.channel.midrollIntervalSeconds * 1e3 : 0;
    }
}

function OnAirUpdater(meta) {
    const { eventCenter } = meta;

    let updateEvent = null;
    updateOnAir(meta);

    eventCenter.on(Event.VIEW_IN, startUpdate);
    eventCenter.on(Event.VIEW_OUT, clearUpdateEvent);

    function startUpdate() {
        clearUpdateEvent();
        updateOnAir(meta);
        updateEvent = setInterval(updateOnAir, 1e3, meta);
    }

    function clearUpdateEvent() {
        if (updateEvent != null) {
            clearInterval(updateEvent);
            updateEvent = null;
        }
    }
}

function updateOnAir(meta) {
    let timestamp = Date.now() / 1e3;
    const { channelList, eventCenter } = meta;
    channelList.forEach((channel, channelIndex) => {
        if (!channel.schedule) {
            // assert: channel.contentType == "channel"
            return;
        }
        const onAir = channel.isVod ? getVodOnAir(channel, timestamp) : getLiveOnAir(channel, timestamp);
        channel.onAir = onAir;
        if (channel.programIndex != onAir && (channel.isLive || channelIndex != meta.channelIndex)) {
            channel.programIndex = onAir;
            eventCenter.trigger(Event.ProgramChange, { channelIndex, programIndex: onAir });
        }
    });
}

function getVodOnAir(channel, timestamp) {
    let { startTime, totalLength, schedule } = channel;
    let offset = (timestamp - startTime) % totalLength;
    return schedule.findIndex((program) => offset < program.endTime);
}

function getLiveOnAir(channel, timestamp) {
    let { schedule, onAir } = channel;
    let maxIndex = schedule.length - 1;
    while (schedule[onAir].endTime <= timestamp && onAir < maxIndex) {
        onAir++;
    }
    return onAir;
}

function appendScheduleInfo(channel, scheduleInfo) {
    if (!(scheduleInfo?.schedule?.length > 0)) {
        return;
    }
    let schedule = scheduleInfo.schedule;
    channel.schedule = schedule;
    channel.onAir = 0; // on air program's index
    channel.programIndex = 0; // playing program's index
    if (channel.isVod) {
        let scheduleStart = schedule[0].startTime;
        let totalLength = schedule.reduce((acc, current) => {
            current.startTime = acc;
            current.endTime = acc + current.length;
            return current.endTime;
        }, 0);
        channel.startTime = scheduleStart + totalLength * Math.floor((Date.now() / 1e3 - scheduleStart) / totalLength);
        channel.totalLength = totalLength;
    }
}

function appendLogoToLiad(channel, sponsorName, liad) {
    const elements = (liad.elements = liad.elements || {});

    ["tr", "tl", "br", "bl"].forEach((position) => {
        const elementId = "*channel_logo_" + position;
        // const image = channel[position + "Logo"];
        const { src: image, clickThrough } = channel.logo[position];
        if (!image) {
            elements[elementId] = null;
            return;
        }
        elements[elementId] = logoAdElement(elementId, image, clickThrough, sponsorName, position);

        const adPartKey = "logo_" + position;
        const adPart = liad[adPartKey];
        if (!adPart || !adPart.element_id || adPart.element_id.length == 0) {
            liad[adPartKey] = logoAdPart(elementId);
        }
    });
    return liad;
}

//參考：
//https://stackoverflow.com/questions/31089801/extending-error-in-javascript-with-es6-syntax-babel
//class ExtendableError extends Error {
//    constructor(message) {
//        super(message);
//        this.name = this.constructor.name;
//        if (typeof Error.captureStackTrace === 'function') {
//            Error.captureStackTrace(this, this.constructor);
//        } else { 
//            this.stack = (new Error(message)).stack; 
//        }
//    }
//}

// 不知為何經過 build 的 JavaScript code 無法用 instanceof 比較出正確的結果，因此先用 es5 的方式建立 Class
export function ExtendableError(error){
    this.name = this.constructor.name;
	if(error instanceof Error){
        for(let k in error){
            if(!error.hasOwnProperty(k)) continue;
            this[k] = error[k];
        }
    }else{
		this.message = String(error);
    }
    if (typeof Error.captureStackTrace === 'function') {
        Error.captureStackTrace(this, this.constructor);
    } else { 
        this.stack = (new Error(this.message)).stack; 
    }
}
ExtendableError.prototype = Object.create(Error.prototype);

export class LiAdError extends ExtendableError {
    constructor(error){
        let code, message, reportType;
        if(typeof error.getErrorCode === "function"){
            let convertErrorInfo = LiAdError.convert(error);
            code = convertErrorInfo.code;
            message = convertErrorInfo.message;
            reportType = convertErrorInfo.reportType;
        }else{
            code = error.code;
            message = error.message;
            reportType = error.reportType;
        }
        super(message);
        this.code = code;
        this.reportType = reportType;
    }
    
    static convert(imaAdError){
        let code = imaAdError.getErrorCode();
        let {message, reportType} = Object.values(LiAdErrorCodes.codes).find((item) => item.code == code) || {
            message : imaAdError.getMessage(),
            reportType : 'error'
        };
        
        return {
            code : code,
            message : message || imaAdError.getMessage(),
            reportType : reportType
        };
    }
}

export class LiAdErrorCodes{
    static get codes(){
        return {
            ADS_REQUEST_NETWORK_ERROR : {
                code: 1012,
                reportType: 'error'
            },
            VAST_ASSET_NOT_FOUND : {
                code: 1007,
                reportType: 'noAd'
            },
            VAST_EMPTY_RESPONSE : {
                code: 1009,
                reportType: 'noAd'
            },
            UNKNOWN_AD_RESPONSE : {
                code: 1010,
                reportType: 'noAd'
            },
            VAST_NO_ADS_AFTER_WRAPPER : {
                code: 303,
                reportType: 'noAd'
            },
//            FAILED_TO_REQUEST_ADS : {
//                code: 1005,
//                reportType: 'noAd'
//            },
            SCHEMA_MISMATCH : {
                code: 9404,
                message: 'Schema not recognized.',
                reportType: 'schemaError'
            },
            MEDIA_TYPE_MISMATCH : {
                code: 9406,
                message: 'Media type not acceptable.',
                reportType: 'schemaError'
            },
            DUPLICATE_AD : {
                code: 9901,
                message: 'Ad id duplicate.',
                reportType: 'duplicateAd'
            },
            IMA_SDK_LOST : {
                code: 9903,
                message: 'ima sdk lost.',
                reportType: 'blocked'
            },
            HOUSE_AD_IMAGE_ASSET_NOT_FOUND : {
                code: 9905,
                message: 'house ad image asset not found.',
                reportType: 'error'
            },
            HOUSE_AD_VIDEO_ERROR : {
                code: 9907,
                message: 'house ad video error.',
                reportType: 'error'
            },
            GET_HOUSE_AD_URL_ERROR : {
                code: 9908,
                message: 'get house ad url error.',
                reportType: 'error'
            },
            OS_TOO_OLD : {
                code: 9910,
                message: 'operating system is too old.',
                reportType: 'error'
            },
            CUSTOM_TIMEOUT : {
                code: 9912,
                message: 'It took too long time to load ad.',
                reportType: 'error'
            },
            GPT_IS_EMPTY : {
                code: 9801,
                message: 'gtp ad is empty.',
                reportType: 'noAd'
            },
            GPT_RENDER_TIMEOUT : {
                code: 9803,
                message: 'gtp ad render timeout.',
                reportType: 'warning'
            },
            GPT_BLOCK : {
                code: 9805,
                message: 'gtp ad blocked',
                reportType: 'warning'
            },
            GPT_UNABLE_TO_ACCOMMODATE : {
                code: 9807,
                message: 'gpt ad unable to accommodate.',
                reportType: 'warning'
            }
        };
    }
}
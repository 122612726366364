import { addClass, removeClass } from "../Util.js";
import metaViewportHandle from "./metaViewportHandle.js";
import EVENT from "../Events.js";

export default function fullscreenHandle(container, vjsPlayer, triggerEvent, defOpt) {
    const metaViewport = metaViewportHandle();
    if (vjsPlayer.isFullscreen() == true) {
        onFullScreen();
    }
    vjsPlayer.on("fullscreenchange", onFullScreenChange);
    vjsPlayer.on("enterFullWindow", onFullWindow);
    vjsPlayer.on("exitFullWindow", onExitFullWindow);

    function onFullScreenChange() {
        if (!defOpt.useNativeFullscreen) {
            return;
        }
        if (vjsPlayer.isFullscreen()) {
            onFullScreen();
            triggerFullscreenEvent(EVENT.FULLSCREEN);
            return;
        }
        onExitFullScreen();
        triggerFullscreenEvent(EVENT.FULLSCREEN_EXIT);
    }
    function onFullWindow() {
        onFullScreen();
        triggerFullscreenEvent(EVENT.FULLWINDOW);
    }
    function onExitFullWindow() {
        onExitFullScreen();
        triggerFullscreenEvent(EVENT.FULLWINDOW_EXIT);
    }
    function onFullScreen(type) {
        addClass(container, "is-fullscreen");
        metaViewport.addCover();
        triggerFullscreenEvent(type);
    }
    function onExitFullScreen(type) {
        removeClass(container, "is-fullscreen");
        metaViewport.reset();
        triggerFullscreenEvent(type);
    }
    function triggerFullscreenEvent(type) {
        triggerEvent(type, { type });
    }
}

export const DAY_IN_SECONDS = 86400; //24 * 60 * 60;
export const GTM8_OFFSET = 28800; // 8 * 60 * 60;

export const CONTENT_TYPE_CHANNEL = "channel";
export const CONTENT_TYPE_VOD_CHANNEL = "vod_channel";
export const CONTENT_TYPE_PLAYOUT = "playout";

export const EPG_STYLE_NONE = "none";
export const EPG_STYLE_IN = "in";
export const EPG_STYLE_OUT = "out";

export const CHANNEL_LIST_SCROLL_OFFSET = 156; // 3 * item height

export default function getEidsMacroMap() {
    try {
        const uidApi = window.__uid2 || top.__uid2;
        if (!uidApi) {
            throw "UID API not ready";
        }
        const uid = uidApi.getAdvertisingToken();
        if (!uid) {
            throw "UID not ready";
        }
        return { X_EIDS: `uidapi.com,${uid},UID2`, UC_EIDS: `uid2,${uid}` };
    } catch (e) {}
    return { X_EIDS: "", UC_EIDS: "" };
}

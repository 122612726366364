import ObserverPattern from "../Util/ObserverPattern.js";
import { getElement } from "../Util/Util.js";

const EVENT = {
    OCCURRED_ERROR: "occurred error",
};

const ERROR_SOURCE = {
    CONFIG: "config",
    META: "meta",
    PLAYER: "player",
    GET_URL: "get_url",
};

const ERROR_INFO = {
    network_error: {
        title: "Oops! 網路異常",
        message: "請確認網路連線正常或重新整理網頁",
    },
    video_format_error: {
        title: "Oops! 影片格式不支援或編碼錯誤",
        message: "請嘗試升級瀏覽器以支援播放之影片格式",
    },
    video_general_error: {
        title: "Oops! 影片載入錯誤",
        message: "請重新整理網頁重新載入",
    },
    geo_error: {
        title: "本服務僅限台澎金馬地區觀看",
        message: "目前沒有授權您的地區使用，請見諒！",
    },
    assets_error: {
        title: "Oops! 資料或網路異常",
        message: "請確認網路連線正常或重新整理網頁",
    },
    getGeneralError: (errorCode, errorMessage) => ({
        title: `發生錯誤${errorCode ? ` 錯誤碼: ${errorCode}` : ""}`,
        message: `${errorMessage}`,
    }),
};

function gateway(source, error) {
    switch (source) {
        case ERROR_SOURCE.CONFIG:
            return ERROR_INFO.assets_error;
        case ERROR_SOURCE.META:
            return ERROR_INFO.assets_error;
        case ERROR_SOURCE.PLAYER:
            return handlPlayerError(error);
        case ERROR_SOURCE.GET_URL:
            return handlGetUrlError(error);
        default:
            return ERROR_INFO.getGeneralError(error.code, error.message);
    }
}

function handlPlayerError(error) {
    switch (error.code) {
        case 2:
            return ERROR_INFO.network_error;
        case 3:
        case 5:
            return ERROR_INFO.video_format_error;
        default:
            return ERROR_INFO.getGeneralError(error.code, error.message);
    }
}

function handlGetUrlError(error) {
    switch (error.code) {
        case 42000087:
        case 87:
            return ERROR_INFO.geo_error;
        default:
            return ERROR_INFO.getGeneralError(error.code, error.message);
    }
}

function createTtemplate(errorInfo) {
    return `
    <div class="ppn-center ppn-font-s-13px ppn-c-ddd">
        <h2 class="ppn-subtitle ppn-text-center">${errorInfo.title}</h2>
        <p class="text ppn-font-w-400 ppn-pd-tb-1em">${errorInfo.message}</p>
        ${
            errorInfo.link
                ? `<a href="${errorInfo.link}" target="_blank" class="ppn-button ppn-w-150px ppn-mg-auto">立即前往 LiTV線上影視</a>`
                : ""
        }
    </div>
    `;
}

function show(errorInfo, container) {
    let $view = document.createElement("DIV");
    $view.className = "error ppn-bg-000 ppn-ratio-16-9 ppn-w-100p ppn-absolute ppn-top-0";
    $view.innerHTML = createTtemplate(errorInfo);
    container.appendChild($view);
}

function setupLink() {
    let { config, meta } = this;
    if (config.errorButton !== true) return "";

    const homePage = "https://www.litv.tv";
    if (typeof config === "undefined") {
        return homePage;
    }

    let contentId;
    if (typeof meta === "undefined") {
        contentId = config.presetContentId;
    } else {
        contentId = meta.contentId || config.presetContentId;
    }

    if (contentId) {
        return config.getDiversionLink("", contentId);
    } else {
        return homePage;
    }
}

export default class ErrorComponent extends ObserverPattern {
    constructor($container, config, meta) {
        super();
        this.container = getElement($container, ".ppn-wrapper_player");
        this.isShow = false;
        this.setInfo(config, meta);
        if (config.hasFail) {
            this.resolve(config.error, ErrorComponent.ERROR_SOURCE.CONFIG);
        }
    }

    setInfo(config, meta) {
        this.config = config;
        this.meta = meta;
    }

    resolve(error, source) {
        console.log(error, source);
        if (this.isShow == true) return;
        let errorInfo = gateway(source, error);
        errorInfo.link = setupLink.apply(this);
        show(errorInfo, this.container);
        this.isShow = true;
        this.trigger(EVENT.OCCURRED_ERROR, { error, source });
    }
}

ErrorComponent.prototype.ERROR_SOURCE = ErrorComponent.ERROR_SOURCE = ERROR_SOURCE;
ErrorComponent.prototype.EVENT = ErrorComponent.EVENT = EVENT;

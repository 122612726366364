import Events from "../Events";
import { conditionClass } from "../Util";

export default function (videojs) {
    const Component = videojs.getComponent("Component");
    const Button = videojs.getComponent("Button");

    class FavoriteButton extends Button {
        constructor(player, options = {}) {
            super(player, options);
        }
        buildCSSClass() {
            return "vjs-favorite-button " + super.buildCSSClass();
        }
        reset(isFavorite) {
            this.isFavorite = isFavorite;
            conditionClass(this.el_, "is-favorite", isFavorite);
        }
        handleClick() {
            const isFavorite = !this.isFavorite;
            this.reset(isFavorite);
            this.options_.tx(
                Events.REQUEST_FAVORITE,
                { isFavorite, getCarryInfo: () => ({ isFavorite }) },
                isFavorite
            );
        }
    }

    Component.registerComponent("favoriteButton", FavoriteButton);
}

import { addClass, conditionClass, removeClass } from "../Util";

const PLAYER_RATIO = 0.5625; // 9 : 16

export default function resizeHandle(container, logoContainer, vjsPlayer, imaPack) {
    onResize();
    vjsPlayer.on("playerresize", onResize);

    function onResize() {
        let width = vjsPlayer.currentWidth();
        let height = vjsPlayer.currentHeight();
        container.style.setProperty("--li-player-height", height);
        conditionClass(container, "less-630", width < 630);
        conditionClass(container, "less-480", width < 480);
        conditionClass(container, "is-small", width < 600 && width >= 400);
        conditionClass(container, "is-tiny", width < 400);
        conditionClass(container, "less-300", width < 300);
        conditionClass(container, "less-200", width < 200);
        calcLogoContainerSize(width, height);
        imaPack.resize();
    }
    function calcLogoContainerSize(width, height) {
        removeClass(container, "straight");
        removeClass(container, "horizontal");
        if (!vjsPlayer.isFullscreen()) {
            return setLogoContainerSize(width, height, 0);
        }
        const straightViewHeight = width * PLAYER_RATIO;
        if (height > straightViewHeight) {
            addClass(container, "straight");
            return setLogoContainerSize(width, straightViewHeight, (height - straightViewHeight) / 2);
        }
        addClass(container, "horizontal");
        return setLogoContainerSize(height / PLAYER_RATIO, height, 0);
    }
    function setLogoContainerSize(width, height, offset) {
        logoContainer.style.width = width + "px";
        logoContainer.style.height = height + "px";
        logoContainer.style.setProperty("--logo-offset", offset + "px");
    }
}

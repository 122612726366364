import ObserverPattern from './ObserverPattern.js';

export default class PauseAndResumController extends ObserverPattern{
    constructor(){
        super();
        this.count = 0;
        this.subs = [];
    }
    requestPause(type, rewind, mediaType){
        if(this.count == 0){
            this.trigger("RequestPause", { partType: type, rewind: rewind, mediaType: mediaType });
        }
        this.count += 1;
    }
    requestResum(type, rewind){
        if(this.count == 0) return;
        this.count -= 1;
        if(this.count == 0) {
            this.trigger("RequestResum", { partType: type, rewind: rewind });
            this.clearSub();
        }
    }
    getSub(type){
        let sub = new PauseAndResumControllerSub(this, type);
        this.subs.push(sub);
        return sub;
    }
    on(){
        super.on.apply(this, arguments);
        return this;
    }
    clearSub(){
        this.subs.forEach(sub => sub.destroy());
        this.subs = [];
    }
    reset(){
        if(this.count != 0){
            this.trigger("Reset");
            this.count = 0;
            this.clearSub();
        }
    }
    forceResum(){
        this.subs.forEach(sub => sub.requestResum());
        this.clearSub();
    }
}

class PauseAndResumControllerSub{
    constructor(parentCxt, type){
        this.parentCxt = parentCxt;
        this.isPauseRequested = false;
        this.type = type;
        this.rewind = 0;
    }
    requestPause(mediaType){
        if(this.isPauseRequested == false){
            this.isPauseRequested = true;
            this.parentCxt && this.parentCxt.requestPause(this.type, this.rewind, mediaType);
        }
    }
    requestResum(){
        if(this.isPauseRequested == true){
            this.parentCxt && this.parentCxt.requestResum(this.type, this.rewind);
        }
        this.parentCxt = null;
    }
    setRewind(rewind){
        if(typeof rewind !== "undefined") this.rewind = rewind;
    }
    destroy(){
        this.parentCxt = null;
    }
}
import ObserverPattern from "./ObserverPattern.js";

const EVENT = {
    "STOP": "stop",
    "RESTART": "restart",
    "USER_ACTION": "user_action"
};

export default class ObeyPlayBehavior extends ObserverPattern{
    constructor($player, config){
        super();
        this.$player = $player;
        this.config = config;
        this.stoped = false;
        this.clipCount = 0;

        let userAction = e => {
            $player.removeEventListener("mouseup", userAction);
            this.calcAutoPlay = () => true;
            this.trigger(EVENT.USER_ACTION);
    
            if(this.stoped == true){
                this.trigger(EVENT.RESTART);
            }
        };

        $player.addEventListener("mouseup", userAction);
    }

    calcAutoPlay(){
        if(this.config.autoPlay == false) return false;
        let configClipCount = this.config.clipCount;
        if(configClipCount < 1) return true;
        if(++this.clipCount <= configClipCount){
            return true;
        }else{
            this.stoped = true;
            this.trigger(EVENT.STOP);
            return false;
        }
    }

    on(){
        super.on.apply(this, arguments);
        return this;
    }
    
    one(){
        super.one.apply(this, arguments);
        return this;
    }
    
    off(){
        super.off.apply(this, arguments);
        return this;
    }
}

ObeyPlayBehavior.prototype.EVENT = ObeyPlayBehavior.EVENT = EVENT;
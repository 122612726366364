export default class PlayedCount{
    constructor(){
        this.transitMap = {
            main: {
                countTime: 0,
                inherit: false
            }
        };
        this.transit = this.transit.bind(this);
        this.resetCertainOne = this.resetCertainOne.bind(this);
        this.reset();
    }
    
    _updateCountTime(added){
        for(let k in this.transitMap){
            if(!this.transitMap.hasOwnProperty(k)) continue;
            let countTime = this.transitMap[k].countTime;
            if(countTime > -1) this.transitMap[k].countTime += added;
        }
    }
    
    timeChange(currentTime, timeStamp){
        if(this.lock == true) return;
        timeStamp = timeStamp >> 0;
        if(this.preTimeStamp !== null && timeStamp - this.preTimeStamp < 1000) return;
        this.preTimeStamp = timeStamp;
        
        currentTime = currentTime >> 0;
        if(this.preTime == null){
            this.preTime = currentTime;
            return;
        }
        
        let dif = currentTime - this.preTime;
        if(dif > 0 && dif <= 2){
            this._updateCountTime(dif);
        }
        
        this.preTime = currentTime;
    }
    
    getMainCount(){
        return this.transitMap["main"].countTime;
    }
    
    reset(){
        for(let k in this.transitMap){
            if(!this.transitMap.hasOwnProperty(k)) continue;
            if(k === "main"){
                this.transitMap[k].countTime = 0;
                continue;
            }
            
            if(this.transitMap[k].inherit === false){
                delete this.transitMap[k];
            }
        }
        
        this.lock = true;
        this.preTimeStamp = null;
        this.preTime = null;
    }
    
    start(){
        this.lock = false;
    }
    
    transit(key, intervalTime, inherit, forReset){
        if(forReset === true){
            if(key in this.transitMap){
                let tInfo = this.transitMap[key];
                tInfo.countTime = -1;
            }
            return false;
        }
        if(key in this.transitMap){
            let tInfo = this.transitMap[key];
            if(tInfo.inherit === null) tInfo.inherit = inherit;
            if(tInfo.countTime == -1) return true;
            if(tInfo.countTime >= intervalTime){
                tInfo.countTime = -1;
                return true;
            }else{
                return false;
            }
        }else{
            if(this.lock == true || this.getMainCount() > intervalTime) {
                this.transitMap[key] = {
                    countTime: -1,
                    inherit: inherit
                };
                return true;
            }else return false;
        }
    }

    resetCertainOne(key){
        if(!(key in this.transitMap)) return;
        this.transitMap[key].countTime = 0;
    }
    
    //for debug
    addCount(key, count){
        if(key in this.transitMap){
            this.transitMap[key].countTime += count;
        }else{
            this.transitMap[key] = {
                countTime: count,
                inherit: null
            };
        }
        return this.transitMap[key].countTime;
    }
    
    //for debug
    getAllCount(){
        let m = {};
        for(let k in this.transitMap){
            if(!this.transitMap.hasOwnProperty(k)) continue;
            m[k] = this.transitMap[k].countTime;
        }
        return m;
    }
}
import { getElement } from "../Util/Util";
import DragDrop from "./DragDrop";

export default function StickyControl($container, $content, config, player, tracing) {
    if (!isTimeToSticky(config)) {
        return;
    }
    $container.classList.add("ppn-floating_enable");
    $container.classList.add("ppn-" + config.floatPos);

    let $pupCloseBtn = getElement($container, ".ppn-pup_close_btn");
    let $placeholder = getElement($container, ".ppn-placeholder");

    let overflowsControl = OverflowsControl($container, $content, $placeholder, player, tracing, config);
    overflowsControl.start(config.stickyMode);

    let pupCloseFn = () => {
        overflowsControl.stop();
        player.mute(true);
        tracing.ga.send("close", "player_float");
    };
    //let removeListenPupCloseFn = () => $pupCloseBtn.removeEventListener("click", pupCloseFn);
    $pupCloseBtn.addEventListener("click", pupCloseFn);
    let displayCloseBtn = () => $pupCloseBtn.classList.remove("hide");
    player.one(player.EVENT.LINEAR_AD_MEDIA_START, displayCloseBtn);
    player.one(player.EVENT.IMPRESSION, displayCloseBtn);

    if (config.stickyDrag) {
        DragDrop($container.querySelector(".ppn-content_wrapper"), tracing.ga);
    }

    function isTimeToSticky(config) {
        let nowTime = +new Date();

        if (inTimeGap(nowTime, config.stickyTimeGap)) {
            return false;
        }

        if (!onTheSchedule(nowTime, config.stickyTimecode)) {
            return false;
        }

        try {
            localStorage.setItem("ppn_last_sticky_time", nowTime);
        } catch {}

        return true;

        function inTimeGap(nowTime, stickyTimeGap) {
            if (!stickyTimeGap || stickyTimeGap <= 0) {
                return false;
            }

            try {
                let lastStickyTime = +localStorage.getItem("ppn_last_sticky_time");
                return lastStickyTime && nowTime < lastStickyTime + 36e5 * stickyTimeGap;
            } catch {
                return false;
            }
        }

        function onTheSchedule(nowTime, timecode) {
            if (!timecode || timecode.length <= 0) {
                return true;
            }
            let minuteNow = (nowTime / 6e4 + 480) % 1440; // utc+8
            return !!timecode.find((interval) => interval.start < minuteNow && minuteNow < interval.end);
        }
    }
}

function OverflowsControl($container, $content, $placeholder, player, tracing, config) {
    let overflowsSwitch = genCssClassSwitch($container, "ppn-overflows");

    let onEnable = () => {
        $placeholder.style.height = $content.offsetHeight + "px";
        tracing.ga.sendOnce("impression", "player_float");
        overflowsSwitch.on();
    };
    let onDisable = overflowsSwitch.off;
    let overflowsObserver = OverflowsObserver($container, config, onEnable, onDisable);

    return {
        start(stickyMode) {
            const STICKY_MODE = {
                DEFAULT: 0,
                ASAP: 1,
                ON_AD_START: 2,
                HIDE_IF_NO_PREROLL: 3,
                AD_ONLY: 4,
            };

            switch (stickyMode) {
                case STICKY_MODE.AD_ONLY:
                    const stickyOff = () => {
                        overflowsObserver.stop();
                        overflowsSwitch.off();
                    };
                    player.on(player.EVENT.LINEAR_AD_MEDIA_START, overflowsObserver.start);
                    player.on(player.EVENT.IMPRESSION, stickyOff);
                    player.on(player.EVENT.RESUM_FOR_AD, stickyOff);
                    break;

                case STICKY_MODE.HIDE_IF_NO_PREROLL:
                    let onImpression = overflowsSwitch.off;
                    let onImpressionNoop = () => {
                        onImpression = () => {};
                    };
                    player.one(player.EVENT.IMPRESSION, () => onImpression());
                    player.one(player.EVENT.LINEAR_AD_MEDIA_START, onImpressionNoop);

                case STICKY_MODE.ASAP:
                    overflowsObserver.start();
                    break;

                case STICKY_MODE.ON_AD_START:
                    player.one(player.EVENT.LINEAR_AD_MEDIA_START, overflowsObserver.start);

                default:
                    createIntersectionObserver(
                        $container,
                        (entries, observer) => {
                            const entry = entries[0];
                            const isIntersecting = entry.isIntersecting;
                            const isAboveViewPort = !isIntersecting && entry.boundingClientRect.y < 0;
                            if (isIntersecting || isAboveViewPort) {
                                overflowsObserver.start();
                                observer.disconnect();
                            }
                        },
                        config.visibleThreshold
                    ).on();
            }
        },
        stop() {
            overflowsObserver.stop();
            overflowsObserver = null;
            overflowsSwitch.off();
        },
    };
}

function OverflowsObserver($container, config, onEnable, onDisable) {
    let started = false;
    let observers = genObservers();

    return {
        start: () => {
            if (started) {
                return;
            }
            started = true;
            observers.on();
        },
        stop: () => {
            if (started) {
                started = false;
                observers.off();
            }
        },
    };

    function genObservers() {
        if (config.stickyAvoidAtf) {
            let inAtf = window.innerHeight > window.scrollY;
            let inViewPort = true;
            let onIntersectionChange = () => (inAtf || inViewPort ? onDisable() : onEnable());

            let viewPortObserver = createIntersectionObserver(
                $container,
                (entries) => {
                    inViewPort = entries[0].isIntersecting;
                    onIntersectionChange();
                },
                config.visibleThreshold
            );

            let atfSensor = createAtfSensor();
            let atfObserver = createIntersectionObserver(
                atfSensor,
                (entries) => {
                    let entry = entries[0];
                    inAtf = entry.isIntersecting || entry.boundingClientRect.top > 0;
                    onIntersectionChange();
                },
                0.1
            );
            return {
                on() {
                    viewPortObserver.on();
                    atfObserver.on();
                },
                off() {
                    viewPortObserver.off();
                    atfObserver.off();
                },
            };
        }

        return createIntersectionObserver(
            $container,
            (entries) => {
                entries[0].isIntersecting ? onDisable() : onEnable();
            },
            config.visibleThreshold
        );
    }

    function createAtfSensor() {
        let sensor = document.body.appendChild(document.createElement("div"));
        sensor.style.cssText =
            "position:absolute;top:100vh;left:0;height:1px;width:100%;margin-top:-5px;padding:0;pointer-events:none;visibility:hidden!important";
        return sensor;
    }
}

function createIntersectionObserver(subject, callback, threshold) {
    let observer = new IntersectionObserver(callback, { threshold });
    return {
        on: () => {
            observer.POLL_INTERVAL = 100;
            observer.observe(subject);
        },
        off: () => observer.disconnect(),
    };
}

function genCssClassSwitch(container, className) {
    return {
        on: () => container.classList.add(className),
        off: () => container.classList.remove(className),
    };
}

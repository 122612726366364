import ObserverPattern from './ObserverPattern.js';

export default class AdsSessionChannel extends ObserverPattern{
    constructor(){
        super();
        let cxt = this;
        this.channel = new (class channel{
            onRequest(listener){
                cxt.one("Request", listener);
                return this;
            }
            onPauseRequested(listener){
                cxt.one("PauseRequested", listener);
                return this;
            }
            onAdMediaStart(listener){
                cxt.on("AdMediaStart", listener);
                return this;
            }
            onAdMediaComplete(listener){
                cxt.on("AdMediaComplete", listener);
                return this;
            }
            onImpression(listener){
                cxt.one("Impression", listener);
                return this;
            }
            onCompleted(listener){
                cxt.one("Completed", listener);
                return this;
            }
            onError(listener){
                cxt.on("Error", listener);
                return this;
            }
            onAbort(listener){
                cxt.on("Abort", listener);
                return this;
            }
            onClick(listener){
                cxt.on("Click", listener);
                return this;
            }
            onCompanionAd(listener){
                cxt.on("CompanionAd", listener);
                return this;
            }
            onCompanionAdEnd(listener){
                cxt.on("CompanionAdEnd", listener);
                return this;
            }
            onCountdownStart(listener){
                cxt.one("CountdownStart", listener);
                return this;
            }
            onCountdownProgress(listener){
                cxt.on("CountdownProgress", listener);
                return this;
            }
            onCountdownEnd(listener){
                cxt.one("CountdownEnd", listener);
                return this;
            }
            onClickSkip(listener){
                cxt.on("ClickSkip", listener);
                return this;
            }
            onLoaded(listener){
                cxt.one("Loaded", listener);
                return this;
            }
            onRequestResume(listener){
                cxt.on("RequestResume", listener);
                return this;
            }
            onPause(listener){
                cxt.on("Pause", listener);
                return this;
            }
            onResume(listener){
                cxt.on("Resume", listener);
                return this;
            }
            // onMuted(listener){
            //     cxt.on("Muted", listener);
            //     return this;
            // }
        })();
    }
    getChannel(){
        return this.channel;
    }
    destroy(){
        this.events = {};
    }
}

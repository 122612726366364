export default {
    AD_ERROR: "ad_error",
    AD_PROGRESS: "ad_progress",
    AD_REPORT: "ad_report",
    AD_REQUEST: "ad_request",
    AD_STREAM_COMPLETE: "ad_stream_complete",
    BEFORE_RESUME: "beforeresume",
    BEFORE_SEEK: "beforeseek",
    BUFFER: "buffer",
    CLICK: "click",
    CLICK_BRAND: "click_brand",
    CLICK_CAPTION: "click_caption",
    CLICK_LOGO: "click_logo",
    CLICK_PAUSE_BANNER: "click_pause_banner",
    CLICK_SKIP: "click_skip",
    COLLAPSE_COMPANION_AD: "collapse_companion_ad",
    DISABLE: "disable",
    ENDED: "ended",
    ERROR: "error",
    PLAYBACK_START: "playback_start",
    FILM_FINISH: "film_finish",
    FLASH_DISABLED: "flashdisabled",
    FULLSCREEN: "fullscreen",
    FULLSCREEN_EXIT: "fullscreen-exit",
    FULLWINDOW: "fullwindow",
    FULLWINDOW_EXIT: "fullwindow-exit",
    IMPRESSION: "impression",
    IN_VIEWPORT: "in_viewport",
    LINEAR_AD_MEDIA_COMPLETE: "linear_ad_media_complete",
    LINEAR_AD_MEDIA_START: "linear_ad_media_start",
    LOAD: "load",
    MULTIPLE_ACCOUNT_USING: "multiple_account_using",
    MUTE: "mute",
    NEXT_EPISODE: "next_episode",
    NONLINEAR_AD_MEDIA_COMPLETE: "nonlinear_ad_media_complete",
    NONLINEAR_AD_MEDIA_START: "nonlinear_ad_media_start",
    OUT_VIEWPORT: "out_viewport",
    PAUSE: "pause",
    PAUSE_BANNER_IMPRESSION: "pause_banner_impression",
    PAUSE_FOR_AD: "pause_for_ad",
    PROGRESS: "progress",
    PUBLISH_COMPANION_AD: "publish_companion_ad",
    QUALITY: "quality",
    QUALITY_MEUN_OPEN: "quality_meun_open",
    READY: "ready",
    RESUM_FOR_AD: "resum_for_ad",
    RESUME: "resume",
    SEEK: "seek",
    SHUTDOWN: "shutdown",
    SPEED: "speed",
    STOP: "stop",
    UNLOAD: "unload",
    VOLUME: "volume",
    WILL_SHUTDOWN: "will_shutdown",
    REQUEST_BACK: "request_back", // todo: remove this
    REQUEST_FAVORITE: "request_favorite",
    REQUEST_PREVIOUS_MEDIA: "request_previous_media",
    REQUEST_CONTROLLER: "request_controller",
    REQUEST_SCHEDULE: "request_schedule",
    REQUEST_INFO: "request_info",
};

import closeBtnSvg from "@images/ppnio_close_btn.svg";
import Meta from "../Model/Meta.js";
import Ancillary from "./Ancillary.js";
import RecommendComponent from "./Recommend.js";
import ContentManager from "./ContentManager.js";
import ErrorComponent from "./Error.js";
import Tracing from "../Util/Tracing.js";
import StickyControl from "./StickyControl.js";
import EarlyMidrollSetup from "./EarlyMidrollSetup.js";
import { getElement } from "../Util/Util.js";
import litvLogoWhite from "../../css/assets/litv_logo_white.png";
import { setupPpnAPI } from "./PpnApi.js";
import { createPlayer, sendInitTracking, sendImpressionTracking } from "../Util/MainviewHelper.js";

export const fabric = `
<div class="ppn-content_wrapper">
    <div class="ppn-content">
        <div class="ppn-outer_player">
            <div class="ppn-wrapper_player ppn-ratio-16-9">
                <div class="ppn-player_main"></div>
            </div>
        </div>
        <div class="ppn-sidebar ppn-bottom">
            <a target="_blank" class="ppn-title" data-ga-tracing data-ga-action="click" data-ga-label="em-footer"></a>
            <a target="_blank" class="ppn-logo" data-ga-tracing data-ga-action="click" data-ga-label="em-logo-click"><img></a>
        </div>
        <div class="ppn-outer_ancillary">
            <div class="ppn-ancillary">
                <div class="ppn-lds-columnar"><div></div><div></div><div></div></div>
            </div>
        </div>
    </div>
    <div class="ppn-pup_close_btn hide"><div>${closeBtnSvg}</div></div>
</div>
<div class="ppn-placeholder"></div>
`;

export default async function MainView(ppnId, $container, config, exApi) {
    let $content = setupLayout($container, config);

    let meta = new Meta(config);
    let player = createPlayer($container, config);
    let tracing = new Tracing(config, meta, player, exApi);
    let errorComponent = new ErrorComponent($container, config, meta);
    let contentManager = new ContentManager($content, config, meta, player, errorComponent);

    if (config.earlyMidroll) {
        EarlyMidrollSetup(config.earlyMidroll, player, meta);
    }

    if (config.enableSideBtn) {
        setupSideButton(config, player, tracing);
    }

    if (config.enableFloating) {
        StickyControl($container, $content, config, player, tracing);
    }

    if (config.enableShowcase) {
        Ancillary($container, config, meta, player, contentManager, errorComponent, tracing);
    }

    if (config.playlistUi == "in") {
        new RecommendComponent(config, meta, player, contentManager, tracing);
    }

    playerEvents($content, player, contentManager, errorComponent);

    let targetContent = setupPpnAPI(ppnId, config, player, contentManager, errorComponent);
    contentManager.play(targetContent || undefined);

    sendInitTracking(config, tracing);

    let displayViewOb = new IntersectionObserver(
        (entries) => {
            let intersectionRatio = entries[0].intersectionRatio;
            if (intersectionRatio > 0.1) {
                displayViewOb.disconnect();
                sendImpressionTracking(tracing);
            }
        },
        {
            threshold: 0.1,
        }
    );
    displayViewOb.POLL_INTERVAL = 100;
    displayViewOb.observe($content);
    window.addEventListener("unload", () => displayViewOb.disconnect());
}

function setupLayout($container, config) {
    let $content = getElement($container, ".ppn-content");

    if (config.enableShowcase) {
        $content.classList.add("ppn-has_ancillary");
    }

    if (config.bottom) {
        $content.classList.add("ppn-has_bottom");
    }

    if (config.fontSize != null) {
        $content.classList.add("ppn-font-" + config.fontSize);
    }

    setupLogo($container, config);

    return $content;
}

function setupLogo($container, config) {
    let $logo = getElement($container, ".ppn-logo");
    let imageEl = getElement($logo, "img");
    let href;
    if (config.brandLogo.bottomBar) {
        imageEl.src = config.brandLogo.bottomBar;
        href = config.brandLogo.clickThrough;
    } else if (config.logoDisplay) {
        imageEl.src = litvLogoWhite;
        href = config.logoLink || "";
    } else {
        imageEl.style.display = "none";
        href = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
    }
    $logo.href = href;
}

function setupSideButton(config, player, tracing) {
    let $sideBtn = document.createElement("a");
    $sideBtn.href = config.sideBtnUrl;
    $sideBtn.target = "_blank";
    $sideBtn.classList.add("ppn-side_btn");
    $sideBtn.innerHTML = `<div>${config.sideBtnTitle}</div>`;
    player.appendChild($sideBtn);
    $sideBtn.addEventListener("click", (e) => {
        tracing.ga.send("click", "player_episode_btn");
    });
}

function playerEvents($content, player, contentManager, errorComponent) {
    player.on(LiTVPlayer.EVENT.FILM_FINISH, contentManager.refresh);
    player.on(LiTVPlayer.EVENT.ENDED, contentManager.playNext);
    player.on(LiTVPlayer.EVENT.ERROR, (e) => {
        if (e.error.code == "3") {
            contentManager.play();
        } else {
            errorComponent.resolve(e.error, ErrorComponent.ERROR_SOURCE.PLAYER);
        }
    });
    player.on(LiTVPlayer.EVENT.LINEAR_AD_MEDIA_START, cutOut);
    player.on(LiTVPlayer.EVENT.LINEAR_AD_MEDIA_COMPLETE, cutIn);
    player.on(LiTVPlayer.EVENT.AD_ERROR, cutIn);

    function cutOut() {
        $content.classList.add("ppn-cut-out");
    }
    function cutIn() {
        $content.classList.remove("ppn-cut-out");
    }
}
